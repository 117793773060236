interface Props {
  pic?: string;
  tips?: string;
  childrenClass?: string;
  loadingClass?: string;
}
export default function Loading({ pic, tips, childrenClass, loadingClass }: Props) {
  return (
    <div className={`loading d-flex-c ai-center bw-100 mt-40 ${loadingClass}`}>
      <img className={`${!pic ? 'pic' : 'w-200'} mt-20 ${childrenClass}`} src={pic || '/pic/loading.png'} alt="" />
      <div className="tips mt-20 text-center">{tips || '暂无数据'}</div>
    </div>
  );
}
