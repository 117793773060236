let landingCode = sessionStorage.getItem('landingCode');
let serviceLink = sessionStorage.getItem('serviceLink');
class Main {
  pageBgStyle = {
    background: 'url("/pic/pic_page_bg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#f8f8fb',
  };
  // shopId: string = 'TN-whALACBx';
  shopId: string = landingCode ? landingCode : '';
  serviceLink?: string = serviceLink ? serviceLink : '';

  // 广州移动通道商id
  supId: string = '1667093255927742465';
  // 公众号APPID
  appId = 'wx325d2f7c68fc6349';

  // 微信用户授权CODE 在登录成功后获取OPENID
  code?: string;
  // 微信用户授权OPENID 在登录成功后获取OPENID
  openId?: string;

  // 是否ios14 签名有问题
  isIos14: boolean = false;
  // 是ios14以上需要保存初始url
  enterUrl: string = '';
  init() {
    let ua = window.navigator.userAgent.toLowerCase();
    const ver = ua.match(/cpu iphone os (.*?) like mac os/);
    this.isIos14 = ver ? Number(ver?.[1].split('_')[0]) >= 14 : false;
  }
}

const main = new Main();
export default main;
