import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

interface NavItem {
  path: string;
  label: string;
  icon: string;
  iconActive: string;
}
const navList: NavItem[] = [
  {
    path: '/',
    label: '首页',
    icon: '/pic/icon_nav_home.png',
    iconActive: '/pic/icon_nav_home_active.png',
  },
  {
    path: '/customer',
    label: '商城客服',
    icon: '/pic/icon_nav_customer.png',
    iconActive: '/pic/icon_nav_customer_active.png',
  },
  {
    path: '/order',
    label: '订单查询',
    icon: '/pic/icon_nav_search.png',
    iconActive: '/pic/icon_nav_search_active.png',
  },
];
export default function BottomNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectTab, setSelectTab] = useState(navList[0]);

  useEffect(() => {
    let idx = navList.findIndex((item) => item.path === location.pathname);
    if (idx >= 0) {
      setSelectTab(navList[idx]);
    }
  }, [location]); // eslint-disable-line

  function handleSelectTab(v: NavItem) {
    setSelectTab(v);
    navigate(v.path);
  }

  return (
    <div className="bottom-nav-bar d-flex-c">
      <div className="bottom-nav-bar-content ">
        <Outlet />
      </div>
      <div className="bottom-nav-bar-bottom border-top d-flex-r ai-center">
        {navList.map((i) => (
          <div key={i.label} className="bottom-nav-bar-item text-center flex-1" onClick={() => handleSelectTab(i)}>
            <img className="icon" src={selectTab.path === i.path ? i.iconActive : i.icon} alt="" />
            <div className={`font-size-12 ${selectTab.path === i.path ? 'text-color-p' : 'text-color-9'}`}>
              {i.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
