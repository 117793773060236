import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiGetOrderList } from '../api';
import AppBar from '../components/appBar';
import Empty from '../components/empty';
import Page from '../components/page';
import RefundDialog from '../components/refundDialog';
import { Order } from '../types';
import { showToast } from '../utils/utils';

export default function OrderListPage() {
  let location = useLocation();
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [userInfo, setuserInfo] = useState('');
  const [orderList, setOrderList] = useState<Array<Order>>([]);
  const [errorTxt, setErrorTxt] = useState('请按手机号搜索订单');
  // TODO:3586 H5集合页增加退款功能暂时注释
  // const [activeIdx, setactiveIdx] = useState<number>();
  // 申请退款
  const [refundDialogShow, setRefundDialogShow] = useState<string>();
  const [history, sethistory] = useState<boolean>(false);

  useEffect(() => {
    if (location?.pathname) {
      // 详情页面点击的订单查询路由为orderList，tabbar路由为order
      sethistory(location?.pathname.includes('orderList'));
    }
  }, [location]);

  async function handleSearchOrder() {
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(searchValue)) {
      setErrorTxt('请输入正确的手机号');
      return;
    }
    if (!userInfo) {
      setErrorTxt('请输入身份证号码/真实姓名');
      return;
    }
    showToast('正在查询...');
    try {
      let res = await apiGetOrderList({ phone: searchValue, userInfo });
      let data = res.data;
      if (data.code === 200) {
        setOrderList(data.data);
      } else {
        setOrderList([]);
        setErrorTxt(data.msg ?? '暂无记录');
      }
    } catch (e: any) {
      setErrorTxt(e.message ?? '暂无记录');
    }
  }
  // async function handleCancelRefund(item: Order) {
  //   if (item.orderPayStatus === 'allRefund') {
  //     setErrorTxt('该订单已完成退款');
  //     return;
  //   }
  //   showToast('正在取消退款...');
  //   try {
  //     let res = await apiCancelRefund({ orderSn: item.orderSn });
  //     if (res.code === 200) {
  //       handleSearchOrder();
  //       showToast(res.msg ?? '操作成功');
  //     } else {
  //       setErrorTxt(res.msg ?? '操作失败');
  //     }
  //   } catch (e: any) {
  //     setErrorTxt(e.message ?? '操作失败');
  //   }
  // }

  return (
    <Page
      navPage={!history}
      className="order-list-page d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c"
      appBar={
        !history ? undefined : (
          <AppBar showBack onBack={() => navigate(-1)} className="bg-transparent" title="订单查询" />
        )
      }
    >
      <div className="d-flex-r ai-center jc-center pt-25 pb-10 font-widget-600 font-size-20">
        <img className="icon-20 mr-5" src="/pic/icon_search.png" alt="" /> 订单查询
      </div>
      <div className="m-10 bg-white search-block box-shadow d-flex-r ai-center">
        <img className="icon-20" src="/pic/icon_phone.png" alt="" />
        <input
          className="search-input flex-1 ml-10 mr-10"
          value={searchValue}
          placeholder="请输入手机号"
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className="m-10 bg-white search-block box-shadow d-flex-r ai-center">
        <img className="icon-20" src="/pic/icon_iccid.png" alt="" />
        <input
          className="search-input flex-1 ml-10 mr-10"
          value={userInfo}
          placeholder="请输入身份证号码/真实姓名"
          onChange={(e) => setuserInfo(e.target.value)}
        />
      </div>
      <div
        className="m-10 search-btn border-radius-40 pt-10 pb-10 text-center bg-p text-color-white font-size-16"
        onClick={handleSearchOrder}
      >
        搜索
      </div>
      <div className="flex-1 h-1 overflow-y">
        {!orderList.length && (
          <Empty className="mt-20" tips={errorTxt} showBackBtn={false} pic="/pic/pic_order_nodate.png" />
        )}
        {orderList.map((i, idx) => (
          <div key={i.orderSn} className="m-10 border-radius-10 bg-white p-10 box-shadow">
            <div className="prop-line">
              <div className="label">订单编号</div>
              <div className="flex-1 d-flex-r ai-center jc-between">
                <div>{i.orderSn}</div>
                {/* TODO:3586 H5集合页增加退款功能暂时注释 */}
                {/* {(i.orderPayStatus === 'success' || i.orderPayStatus === 'aa-refund') && (
                  <div
                    onClick={() => {
                      activeIdx !== idx ? setactiveIdx(idx) : setactiveIdx(undefined);
                    }}
                  >
                    <img className="w-20" src={`/pic/icon_${idx === activeIdx ? 'down' : 'right'}.png`} alt="" />
                  </div>
                )} */}
              </div>
            </div>
            <div className="prop-line">
              <div className="label">订单名称</div>
              <div className="flex-1">{i.orderName}</div>
            </div>
            <div className="divider"></div>
            <div className="prop-line">
              <div className="label">开卡号码</div>
              <div className="flex-1">{i.activatePhone}</div>
            </div>
            <div className="prop-line">
              <div className="label">开卡说明</div>
              <div className="flex-1">{i.orderStatusDesc}</div>
            </div>
            <div className="divider"></div>
            <div className="prop-line">
              <div className="label">物流名称</div>
              <div className="flex-1">{i.expressName}</div>
            </div>
            <div className="prop-line">
              <div className="label">物流单号</div>
              <div className="flex-1">{i.expressNum}</div>
            </div>
            <div className="prop-line">
              <div className="label">下单时间</div>
              <div className="flex-1">{i.createTime}</div>
            </div>
            {i?.auditDesc && (
              <div className="prop-line">
                <div className="label">审批结果</div>
                <div className="flex-1">{i.auditDesc}</div>
              </div>
            )}
            {/*  TODO:3586 H5集合页增加退款功能暂时注释 */}
            {/* <div
              className={`prop-line ai-center jc-center transition ${
                activeIdx === idx ? 'h-40 opacity-block' : 'h-0 opacity-none'
              }`}
            >
              {i.orderPayStatus === 'success' && (
                <div
                  className="flex-1 d-flex-r ai-center jc-center bg-blue p-8 text-color-white border-radius-4 mr-20"
                  onClick={() => setRefundDialogShow(i.orderSn)}
                >
                  申请退款
                </div>
              )}
              {i.orderPayStatus === 'aa-refund' && (
                <div
                  className="flex-1 d-flex-r ai-center jc-center bg-gray p-8 text-color-3 border-radius-4 mr-20"
                  onClick={() => handleCancelRefund(i)}
                >
                  取消退款
                </div>
              )}
            </div> */}
          </div>
        ))}
      </div>
      {/* 申请退款弹窗 */}
      {!!refundDialogShow && (
        <RefundDialog
          orderSn={refundDialogShow}
          onClose={(v) => {
            setRefundDialogShow(undefined);
            if (v) handleSearchOrder();
          }}
        />
      )}
    </Page>
  );
}
