import React, { useEffect, useState } from 'react';
import { apiApplyRefund, apiOssUpload } from '../../api';
import { showToast } from '../../utils/utils';
import Loading from '../loading';
import ViewPictureDialog from '../viewPictureDialog';

interface Props {
  orderSn?: string;
  onClose: (v: boolean) => void;
}

export default function RefundDialog({ onClose, orderSn }: Props) {
  // 上传凭证
  const [refundImgPath, setrefundImgPath] = useState<string>();
  const [refundDesc, setrefundDesc] = useState<string>();
  // 上传照片显示loading
  const [uploadLoading, setuploadLoading] = useState<boolean>(false);
  // 查看图片
  const [viewPictureDialogShow, setviewPictureDialogShow] = useState<string | undefined>();
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
  }, []);

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose(false);
  }
  async function handleConfirm() {
    if (!refundImgPath) {
      return showToast('请上传支付凭证');
    }
    if (!refundDesc) {
      return showToast('请填写退款原因');
    }
    showToast('正在提交...');
    try {
      let res = await apiApplyRefund({ refundDesc, orderSn, refundImgPath });
      if (res.code === 200) {
        onClose(true);
      } else {
        showToast(res.msg ?? '暂无记录');
        onClose(false);
      }
    } catch (e: any) {
      showToast(e.message ?? '暂无记录');
    }
  }

  // 上传照片
  const chooseImg = async (t: FileList | null) => {
    if (t && t[0]) {
      if (t[0].size / 1024 / 1024 > 10) return showToast('照片不可以上传超过10MB');
      setuploadLoading(true);
      try {
        const res = await apiOssUpload(t[0]);
        if (res) {
          setrefundImgPath(res);
        }
      } catch (error) {
      } finally {
        setuploadLoading(false);
      }
    }
  };
  return (
    <div className="refund-dialog d-flex-r ai-center jc-center">
      <div className="refund-mask" onClick={handleClose} />
      <div className="refund-center bw-100 p-10" onClick={() => {}}>
        <div className="refund-title-block">
          <div className="refund-title">申请退款</div>
          <div className="refund-close" onClick={handleClose}>
            <img alt="" className="close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="refund-content">
          <div className="d-flex-r mb-20">
            <div className="label w-80 text-right">支付凭证：</div>
            {refundImgPath ? (
              <div className="upload-pic border-radius-4 overflow-hidden mr-5">
                <img
                  className="bw-100 bh-100"
                  src={refundImgPath}
                  alt=""
                  onClick={() => setviewPictureDialogShow(refundImgPath)}
                />
                <div className="icon-close-block" onClick={() => setrefundImgPath(undefined)}>
                  <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                </div>
              </div>
            ) : (
              <div className={`upload-pic border border-radius-4 text-center d-flex-r ai-center jc-center`}>
                <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                <input
                  className="upload-input bw-100 bh-100"
                  type="file"
                  accept=".jpg,.png,.jpeg"
                  onChange={(e) => chooseImg(e.target.files)}
                />
              </div>
            )}
          </div>
          <div className="d-flex-r mb-20">
            <div className="label w-80 text-right">退款原因：</div>
            <textarea
              className="input flex-1 border-radius-4 p-5"
              value={refundDesc}
              onChange={(e) => setrefundDesc(e.target.value)}
            />
          </div>
          <div className="d-flex-r ai-center jc-center">
            <div
              className="btn w-120 p-10 border-radius-8 mr-20 text-center text-color-ccc border-ccc"
              onClick={handleClose}
            >
              取消
            </div>
            <div
              className="btn w-120 bg-blue p-10 border-radius-8 text-center text-color-white"
              onClick={handleConfirm}
            >
              确定
            </div>
          </div>
        </div>
      </div>
      {uploadLoading && (
        <Loading
          tips={'正在加载中...'}
          loadingClass="bh-100 overflow-hidden text-color-white d-flex-r ai-center jc-center mt-0 bg-shallow"
        />
      )}
      {/* 查看图片 */}
      {viewPictureDialogShow && (
        <ViewPictureDialog imgPath={viewPictureDialogShow} onClose={() => setviewPictureDialogShow(undefined)} />
      )}
    </div>
  );
}

let closeIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACSUlEQVR4nO2avXKbQBSF9Th6CBUpXbpwYcfyT+L8PIr2nLPvlDKly8SWnThOYj+AyxRKEcggBliBgAXmfjOUcPd8u8Cyy2xmGIZhGIZhGIZh1Md7P3fOLfqu65xbeO/nfdfdwns/J/kM4KVPCc65BYAXks/RJKxWq1ckP5HcJMc3ABdd13XOHZP8kqn7meRB13W3KAj/XwLJ867qFoSPIyEzBPMN2XR1O1TVJPkHwGHbNSsBcJH0eFGD7gCctVWrouc3AJ4kfWyrVi1InpO8L5MgablvjarwJH+T/NBGlsZUSZC03kfC4MOneO/PSN6VDNE1gNO616wKL+mXpPddZGkMgFIJJG/rSAj0/PDCp0haSlqXSZD0OnSNQPifJN/1kaUxkpYACiVIunHOlUoYffgUAKckb0ueCTckT/LnBO75R0lXMbI0pkoCya9ZCYGefwQwrvApu8wYd5jhHcXOsRchCZMOn0LyJBn2RUGLjgdJb2K3u1UkHe8o4YHkZez2dkIioexhtyH5fbLhZ7Pg037aAnYIP10JNcJPT0LoPZ8cpfOE2O3fi10mOQCOJikhMOy3XnUkL5NhP43bIRD+R9EkZzISQuFJvi07d/QSQl91VeFTRish9D1f55N2dBJCKzlNFjNGIyG0gMk9lrEGL6HL8CmDlRDatGhz6XpwEmIsYw1qxkjygP+2pPMNeWKH21UVI+G6993hvARJnYbP1M1L6D98SvqLTFfDvozM7RDvF5kU7/08Rg8AOIwe3jAMwzAMwzAMY6z8BS3ngNlcif7iAAAAAElFTkSuQmCC';
