import React, { useEffect } from 'react';

interface Props {
  /** 协议类型 book=>转卖或转借电话卡风险告知书 inter=>客户服务协议 noticeYD=>移动关于客户个人信息收集，使用及保护的公告 internet=>入网协议 */
  type: 'book' | 'inter' | 'noticeYD' | 'internet' | 'userInfo' | 'noticeDX' | 'phone' | string;
  onClose: () => void;
  supName?: string;
}

export default function ProtocolDialog({ type = 'book', onClose, supName }: Props) {
  let noticeDXProtocol = {
    title: '中国电信用户入网协议',
    content: [
      '甲方：',
      `乙方：${supName?.indexOf('湖南电信') ? '中国电信XX分公司' : '中国电信湖南分公司'} `,
      '重要提示：',
      '1.甲方应当为具有完全民事行为能力的自然人。如甲方为无民事行为能力人或限制民事行为能力人，甲方签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。',
      '2.在签署本协议前，请甲方或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。',
      '3.乙方尊重并保护用户的个人信息，制定了《中国电信个人信息保护政策》，已向甲方告知有关乙方提供本协议项下服务适用的个人信息处理规则。',
      '4.如甲方为不满十四周岁的未成年人的，甲方父母或其他监护人须同时签署《中国电信儿童个人信息处理知情同意书（适用于不满十四周岁的未成年用户）》（“知情同意书”）。乙方尊重并保护儿童个人信息，制定了《中国电信儿童个人信息处理规则》，作为知情同意书的附件，向甲方告知有关乙方提供本协议项下服务适用的儿童个人信息处理规则。',
      '5.甲方或其代理人/监护人签署本协议即视为完全理解并同意接受本协议的全部条款。',
      '6.甲方所需的业务内容、办理方式以及资费方案请详见乙方的相关业务说明。',
      '根据《中华人民共和国民法典》《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国网络安全法》《中华人民共和国反电信网络诈骗法》《中华人民共和国电信条例》及其他有关法律、法规的规定，甲乙双方在自愿、平等、公平、诚实信用的基础上，就电信服务的相关事宜，达成如下协议：',
      '一、用户真实身份信息登记要求',
      '1.根据《中华人民共和国反电信网络诈骗法》《电话用户真实身份信息登记规定》（工业和信息化部令第25号）及有关登记实施规范要求，甲方办理入网手续时须向乙方出示二代居民身份证、外国人永久居留身份证（仅限外国公民使用）、提供真实身份信息，并通过乙方在线视频实人认证等认证方式完成身份信息核验（具体认证方式以乙方网络渠道页面提示为准）。如甲方申办乙方移动电话卡（含无线上网卡），但未完成在线实人认证程序的，乙方将委托第三方递送电话卡给甲方，并授权第三方现场查验甲方身份信息、收集相关资料，甲方须予以配合。',
      '2.甲方应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整，并有义务配合乙方或乙方授权的第三方进行查验。甲方可以通过乙方相应渠道查询已登记的身份信息。如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的，甲方应当及时到乙方营业网点更正或变更。因甲方登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由甲方自行承担。',
      '3.甲方申请入网时，在中国电信全国范围内登记在同一个个人用户名下的移动电话卡达到5张的，乙方根据国家相关规定不再为其开办新的移动电话卡。',
      '4.乙方有权按照《中华人民共和国反电信网络诈骗法》，对监测识别的涉诈异常电话卡用户重新进行真实身份信息核验。对未按规定核验或核验未通过的，乙方有权限制、暂停有关电话卡功能。',
      '二、甲方的权利和义务',
      '1.甲方有权监督乙方的电信服务质量，及时提出改善意见和建议；对电信服务持有异议的，可以向乙方进行咨询或投诉。',
      '2.甲方有权自主选择乙方依法提供的各类电信业务，有权自主选择乙方公示的资费方案，有权自主选择乙方提供的各类业务办理、咨询、查询和投诉渠道。对于部分乙方业务， 甲方有权自业务办理成功后7日内按照相应的业务规则申请办理退订且无需因退订支付违约金。具体适用业务范围及退订规则（包括但不限于甲方应当交纳已使用的电信费用）以甲方办理业务时签署的业务登记单或乙方公示信息为准。',
      '3.甲方有权自主选择取得入网许可的终端、设备，并负责安装、调测和维护（包括建筑内自建管线的维护）。甲方自主选择的终端、设备应当具备符合乙方网络的相应功能，否则可能无法支持所选择的电信服务，甲方将自行承担损失。',
      '4.甲方对乙方提供给甲方使用但所有权归乙方的线路、设备或终端有保管责任，不得转借、出租、出售或赠与他人，也不得用于非乙方提供的业务中。由于甲方原因造成该等线路、设备或终端损坏、丢失的，甲方需承担修复费用或按价赔偿。',
      '5.甲方应当按照约定的交费时间和方式及时、足额交纳电信费用。电信费用计费周期一般为自然月，即每月1日0时至当月最后一日24时，双方另有约定的除外。逾期交纳电信费用（欠费）的，甲方须每日按所欠费用3‰的标准支付违约金，并承担相应违约责任。由于通信设备产生费用清单及相关处理会有时延（如国际漫游业务等），可能会发生上一计费周期的部分电信费用计入下一计费周期收取的情况。',
      '6.甲方可以自愿订购或退订第三方服务提供商通过乙方网络提供的增值电信服务，并同意乙方根据第三方服务提供商的委托代为收取相关费用。如甲方对代收费用有异议，可以向第三方服务提供商或乙方提出，由第三方服务提供商自行解决或由乙方协调第三方服务提供商解决。',
      '7.甲方对交纳的电信费用及代收费用有异议的，应当在乙方计费原始数据保存期限内向乙方提出。',
      '8.甲方有权自主选择或终止以代扣、银行托收等方式支付电信费用，并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。',
      '9.甲方通信自由和通信秘密受法律保护。公安机关、国家安全机关、人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，乙方将依法配合。',
      '10.甲方不得非法买卖、出租、出借名下的电话卡、物联网卡、电信线路、短信端口等。甲方应当确保电信服务的实际使用人信息与乙方登记信息一致，如需转让给他人使用或实际使用人信息发生变化，甲方须按本协议关于用户真实身份信息登记的约定，办理过户手续或变更实际使用人信息，否则乙方有权采取关停相关服务或功能、解除协议以及有权机构要求的其他措施。由此产生的后果和法律责任，由甲方全部承担。',
      '11.甲方不得利用乙方通信资源、电信服务等制作、复制、发布、传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：',
      '（1）反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的；',
      '（2）危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
      '（3）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；',
      '（4）散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；',
      '（5）散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；',
      '（6）侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；',
      '（7）发送违法信息、拨打诈骗电话，未经接收方同意或请求、或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；',
      '（8）其他利用乙方通信资源、电信服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。',
      '12.甲方不得实施或协助他人实施下列危害乙方网络安全和信息安全的行为，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：',
      '（1）侵入或非法控制乙方网络或电信设备，盗接乙方电信线路、非法复制乙方电信码号；',
      '（2）非法获取、删除、修改乙方网络或电信设备中存储、处理、传输的信息和应用程序；',
      '（3）利用乙方网络从事窃取或破坏他人信息、损害他人合法权益的活动，制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；',
      '（4）提供从事入侵乙方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；',
      '（5）设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；',
      '（6）故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；',
      '（7）使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；',
      '（8）危害乙方网络安全和信息安全的其他行为。',
      '13.甲方同意乙方基于提供电信服务、保障通信服务安全、与甲方沟通联系目的处理并与乙方关联公司共享甲方信息，包括身份信息、服务数据及日志信息等。身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、出生日期、身份证件号码等身份证件登记信息、拍摄的影像、住址、电话号码、电子邮箱等；服务数据及日志信息包括但不限于甲方在使用乙方服务过程中上传和产生的位置信息、终端信息、通话记录、使用记录、订购信息、账单、网络信息等。乙方处理甲方个人信息的规则，见《中国电信个人信息保护政策》。如甲方不同意提供或不同意乙方处理如上的某一或某些信息，甲方将可能无法成为乙方的用户或无法享受乙方提供的某些服务，或无法达到相关服务拟达到的效果。本协议解除或终止后，乙方不再收集甲方新的信息，并将按照相关法律法规规定对本协议存续期间收集的甲方信息进行处理。其中乙方关联公司是指乙方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司或机构管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
      '14.未经甲方授权同意，乙方不向第三方提供甲方信息。如甲方授权与乙方有合作关系的银行等金融机构、依法设立的征信机构、数据服务机构、信息查询服务机构、信息验证服务机构、互联网企业等第三方向乙方收集、核验甲方信息，甲方同意乙方有权向该等第三方提供相关的甲方信息和核验信息。',
      '15.为保护公共利益、优化电信服务质量、保障甲方电信服务知情权、及时警示通讯信息诈骗、创新电信服务体验，甲方同意乙方使用甲方信息通过营业网点、网站、短信、互联网媒体、电子邮件、语音外呼等方式，向甲方告知社会公益、电信服务、业务使用、诈骗风险警示等信息。',
      '16.甲方有权自主选择携号转网服务，即在保持移动电话号码不变的情况下变更签约的电信运营企业。甲方办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。',
      '17.办理携号转网业务过程中，甲方同意乙方将必要的甲方身份信息提供给工业和信息化部集中业务管理系统（CSMS系统），用于比对携入方和携出方登记的身份信息是否一致。',
      '三、乙方的权利和义务',
      '1.乙方在其通信网络与设施覆盖范围内，向甲方提供电信服务；其中，乙方在签订有自动漫游协议的国家和地区的电信运营商的网络覆盖范围内为甲方提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。乙方提供的电信服务应符合国家规定的电信服务质量标准。',
      '2.乙方免费向甲方提供中华人民共和国境内（为本协议目的，仅限大陆地区，不含港澳台）火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。',
      '3.乙方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。',
      '4.乙方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、通知或告知业务种类和服务项目、范围、时限、资费方案以及其他电信服务内容。',
      '5.乙方可以通过电话热线、营业网点、网上营业厅、掌上营业厅或短信等多种渠道为甲方提供业务办理、咨询、查询、障碍申告、投诉受理等服务。',
      '6.乙方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。',
      '7.甲方向乙方申告除网络覆盖和终端设备故障外的电信服务障碍，乙方应当自接到申告之日起，城镇48小时、农村72小时内修复或调通。',
      '8.乙方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常电信服务的，应当提前72小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。',
      '9.乙方向甲方提供免费的电子账单服务，并有义务对账单进行解释。乙方对甲方计费原始数据保留期限为5个月（系统产生用户话单当月起后5个月，不含当月），法律另有规定除外。如数据保留期限内甲方对电信费用提出异议，乙方应当保存相应计费原始数据至异议解决。',
      '10.乙方可以根据对甲方实际情况的综合评估，与甲方约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。',
      '11.乙方有权根据具体业务类型按预付方式或后付方式向甲方收取电信费用：',
      '（1）预付方式下，甲方需预存费用。当账户余额加上信用额度（如有）不足以支付甲方拟使用的电信业务费用时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务（“欠费停机”）。',
      '（2）后付方式下，甲方累计未交的费用到达信用额度、或到达交费期时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务。其中，甲方当月累计出账费用的交费期为次月整月，另有约定的除外。',
      '12.甲方自欠费停机之日起60日内结清费用并申请复机的，乙方应当在收到甲方费用时起24小时内为甲方恢复暂停的电信服务（“复机”）。',
      '13.如甲方自欠费停机之日起满60日仍未结清欠费和违约金，乙方有权自欠费停机第61日起终止提供电信服务，收回相应号码（“销户”）、解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金，并可以依法向征信机构提供甲方失信信息。',
      '14.对经设区的市级以上公安机关认定的非法买卖、出租、出借电话卡、物联网卡、电信线路、短信端口以及假冒他人身份或者虚构代理关系开立上述卡或业务的单位、个人，以及因从事电信网络诈骗活动或者关联犯罪受过刑事处罚的人员，乙方有权按照国家有关规定采取限制其有关卡、账户、账号等功能和停止非柜面业务、暂停新业务、限制入网等措施。',
      '15.乙方严格按照《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国反电信网络诈骗法》《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、纸质返档，通过网络接收、读取并记录、身份验证、客户服务、安全防范、诈骗监测、邀请甲方参与有关乙方产品和服务的调查）中处理甲方信息。为提供更为精准优质的服务，乙方可以依法对包含甲方在内的整体用户数据进行分析并加以利用。乙方承诺对甲方信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供，并采取符合业界标准、合理可行的安全防护措施保护甲方的个人信息，防止甲方的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。',
      '16.为遵守相关法律法规或者行政机关、监管机构等有权部门依法提出的要求以及基于诉讼/仲裁需要，乙方有权披露甲方的信息。',
      '四、特殊情况的责任承担',
      '1.甲方入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由甲方自行承担责任。',
      '2.甲方密码是甲方办理业务的重要凭证。甲方入网后应当立即设置甲方密码，并妥善保管。为方便办理业务，乙方也可以根据甲方申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。',
      '3.凡使用甲方密码或随机密码定制、变更或取消业务的行为，均被视为甲方或甲方授权的行为。因甲方对密码保管不善造成的损失由甲方自行承担，但乙方有义务协助甲方或公安机关调查相关情况。',
      '4.甲方应当合理管理电信费用支出，出现异常的高额电信费用时（指超过甲方此前3个月平均电信费用5倍以上的费用），乙方一经发现，应当尽可能迅速告知甲方，如告知后未得到甲方确认的，乙方有权暂停本协议约定的部分或全部服务。',
      '5.在甲方欠费停机的情况下，乙方有权拒绝为甲方办理除交费、电信费用查询外的其他电信业务。',
      '6.一方违约给对方造成直接损失的，应当依法承担赔偿责任。',
      '7.因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任，但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。',
      '8.甲方使用本协议项下电信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等），由第三方承担责任。对于甲方提出的异议，乙方可以协调第三方解决。',
      '9.乙方可能重新启用销户的移动电话号码。号码被销户前原使用该号码的用户（“原机主”）如将该号码用作第三方联系电话或者绑定第三方业务，且原机主未在销户前予以变更或解除绑定，该号码可能会收到第三方发给原机主的通知，或者因第三方的限制无法在解除绑定前完成新用户认证绑定。甲方需自行办理解除绑定等手续，乙方在合理限度内提供配合。',
      '五、协议的生效、变更、终止和解除',
      '1.除本协议另有约定外，本协议自甲方以点击确认或其他方式表示同意本协议时成立，自甲方按本协议第一条第1款完成身份信息线上核验或现场查验时生效。如甲方办理号码携入的，本协议自甲方以点击确认或其他方式表示同意本协议时成立，于甲方按本协议第一条第1款完成身份信息线上核验或现场查验、乙方收到携号转网成功生效结果告知之时起生效。',
      '2.甲方如终止使用电信服务，应当结清电信费用及违约金（如有），并至乙方营业网点申请销户或号码携出，销户或号码携出后本协议终止。',
      '3.未经双方同意并办理有关手续，甲、乙方不得将依据本协议办理的业务全部或部分转让给第三方。',
      '4.甲方申请销户或号码携出时账户仍有余额的，乙方根据实际情况将账户余额退还甲方，双方另有约定的除外；甲方通过参与赠送、优惠、兑换等营销活动获取的余额，不予退还，如按营销活动的业务规则另有约定的，按其业务规则处理。',
      '5.因乙方电信业务经营许可证的业务范围、业务种类发生改变，或因乙方对移动通信网络进行整体换代升级、技术进步、国家政策变动等原因，乙方无法向甲方继续提供原有业务或需对原有业务的服务方式、服务功能、操作方法、业务号码等进行调整时，乙方有权变更或终止本协议，但应当提前通知或公告，提出解决方案，做好客户服务。',
      '6.有下列情形之一的，乙方有权单方终止或解除本协议：',
      '（1）甲方未按照用户真实身份信息登记要求，提供的登记资料不真实、不准确、不完整、无效，或经乙方通知后未在合理期限内配合提供的；',
      '（2）甲方收到电话卡后20天内未激活使用的；',
      '（3）甲方存在本协议第二条第11款、第12款所列任一行为，或由此引起用户投诉或举报的；',
      '（4）甲方未办理相关手续，自行改变电信服务使用性质、用途，或出租、二次转售、倒卖电话卡，或自行转让协议的；',
      '（5）甲方利用乙方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案；',
      '（6）甲方自欠费停机之日起60日内仍未结清所有欠费、违约金、赔偿金等费用的；',
      '（7）有权机关要求乙方停止对甲方提供电信服务的；',
      '（8）甲方原因造成本协议无法履行的；',
      '（9）法律、法规、规章及规范性文件规定的其他情形。',
      '7.本协议终止或解除后，乙方继续保留向甲方追缴所欠费用的权利，并有权收回甲方原使用的业务号码（销户的号码）。乙方对销户的业务号码冻结90日后将重新分配给其他用户使用。',
      '8.甲方改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用（如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、代扣等第三方协议，自行卸载甲方加载的第三方软件，避免造成甲方信息泄露；否则由此造成甲方或其他人损失由甲方承担。',
      '9.为使甲方便捷使用乙方或其关联公司提供的产品或服务、保障信息安全，如甲方通过其在乙方办理的移动电话号码使用（包括但不限于登录、真实身份验证等）乙方或乙方关联公司运营的各类互联网应用（统称“中国电信互联网应用”）的，中国电信互联网应用将默认该移动电话号码作为甲方的中国电信互联网应用账号（统称“天翼账号”）。当甲方对该移动电话号码进行改号、销户或过户时，中国电信互联网应用将自动注销甲方的上述天翼账号，并同步删除该天翼账号中的全部信息。甲方应当在改号、销户或过户前另行备份上述天翼账号中的信息。如甲方未备份造成信息丢失的，由甲方自行承担，乙方或乙方关联公司不承担责任且无义务为甲方进行信息恢复。中国电信互联网应用包括但不限于189邮箱、天翼云盘。',
      '六、其他约定',
      '1.本协议适用中华人民共和国法律（仅为本协议之目的，不含香港特别行政区、澳门特别行政区和台湾地区法律）。所有因本协议引起的或与本协议有关的争议，可以通过协商、请求消费者协会或依法成立的其他调解组织调解（限于甲方为消费者）、向有关行政部门投诉，或者向乙方所在地的人民法院起诉等方式解决。',
      '2.如果本协议的任何条款在任何时候变成不合法、无效或不可强制执行但不从根本上影响本协议的效力时，本协议的其他条款不受影响。',
      '3.甲方办理各类业务所签署的业务登记单为本协议的一部分，业务登记单与本协议条款冲突的部分以业务登记单为准。乙方以公告等书面形式公开做出的服务承诺为本协议的补充，该等服务承诺与本协议冲突的部分以服务承诺为准。',
      '4.有关乙方提供本协议项下服务适用的个人信息处理规则，见《中国电信个人信息保护政策》。《中国电信个人信息保护政策》与本协议条款冲突的部分以《中国电信个人信息保护政策》为准。',
      '5.如甲方为不满十四周岁的未成年人的，甲方父母或其他监护人签订本协议时须同时签署《中国电信儿童个人信息处理知情同意书（适用于不满十四周岁的未成年用户）》（“知情同意书”）。甲方同意乙方按照知情同意书及其附件《中国电信儿童个人信息处理规则》处理甲方的个人信息。知情同意书及其附件《中国电信儿童个人信息处理规则》为本协议的一部分，知情同意书及其附件《中国电信儿童个人信息处理规则》与本协议条款冲突的部分以知情同意书为准。',
      '6.本协议一式两份，双方各保留一份，具有同等法律效力。甲乙双方以电子方式签署本协议的，甲方可以通过乙方提供的途径或指引查询、下载本协议。',
      '甲方：（签名或单位盖章）',
      '地址：',
      '联系方式：',
      '签署日期： 年 月 日',
      '甲方法定代理人或代理人：（签名或单位盖章）',
      '地址：',
      '联系方式：',
      '签署日期： 年 月 日',
      '乙方：（名称并盖章）',
      '地址：（乙方单位注册地址）',
      '签署日期： 年 月 日',
    ],
  };
  let p =
    type === 'book'
      ? userProtocol
      : type === 'inter'
      ? interProtocol
      : type === 'phone'
      ? phoneProtocol
      : type === 'noticeYD'
      ? noticeYDProtocol
      : type === 'noticeDX'
      ? noticeDXProtocol
      : type === 'userInfo'
      ? userInfoProtocol
      : type === 'internet'
      ? internetProtocol
      : type === 'gdyd1'
      ? liabilityProtocol1
      : type === 'gdyd2'
      ? liabilityProtocol2
      : type === 'gdyd3'
      ? liabilityProtocol3
      : type === 'gdyd4'
      ? liabilityProtocol4
      : type === 'gdyd5'
      ? liabilityProtocol5
      : type === 'gdyd6'
      ? liabilityProtocol6
      : type === 'gdyd7'
      ? liabilityProtocol7
      : type === 'zhouShanDX'
      ? zhouShanDX
      : type === 'zhouShanruwangDX'
      ? zhouShanruwangDX
      : { title: '', content: [] };
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
  }, []);

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose();
  }
  return (
    <div className="protocol-dialog">
      <div className="protocol-mask" onClick={handleClose} />
      <div className="protocol-center" onClick={() => {}}>
        <div className="protocol-title-block">
          <div className="protocol-title">{p.title}</div>
          <div className="protocol-close" onClick={handleClose}>
            <img alt="" className="close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="protocol-content">
          {p.content.map((v, i) => (
            <div key={i} className="protocol-content-line">
              {v}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

let closeIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACSUlEQVR4nO2avXKbQBSF9Th6CBUpXbpwYcfyT+L8PIr2nLPvlDKly8SWnThOYj+AyxRKEcggBliBgAXmfjOUcPd8u8Cyy2xmGIZhGIZhGIZh1Md7P3fOLfqu65xbeO/nfdfdwns/J/kM4KVPCc65BYAXks/RJKxWq1ckP5HcJMc3ABdd13XOHZP8kqn7meRB13W3KAj/XwLJ867qFoSPIyEzBPMN2XR1O1TVJPkHwGHbNSsBcJH0eFGD7gCctVWrouc3AJ4kfWyrVi1InpO8L5MgablvjarwJH+T/NBGlsZUSZC03kfC4MOneO/PSN6VDNE1gNO616wKL+mXpPddZGkMgFIJJG/rSAj0/PDCp0haSlqXSZD0OnSNQPifJN/1kaUxkpYACiVIunHOlUoYffgUAKckb0ueCTckT/LnBO75R0lXMbI0pkoCya9ZCYGefwQwrvApu8wYd5jhHcXOsRchCZMOn0LyJBn2RUGLjgdJb2K3u1UkHe8o4YHkZez2dkIioexhtyH5fbLhZ7Pg037aAnYIP10JNcJPT0LoPZ8cpfOE2O3fi10mOQCOJikhMOy3XnUkL5NhP43bIRD+R9EkZzISQuFJvi07d/QSQl91VeFTRish9D1f55N2dBJCKzlNFjNGIyG0gMk9lrEGL6HL8CmDlRDatGhz6XpwEmIsYw1qxkjygP+2pPMNeWKH21UVI+G6993hvARJnYbP1M1L6D98SvqLTFfDvozM7RDvF5kU7/08Rg8AOIwe3jAMwzAMwzAMY6z8BS3ngNlcif7iAAAAAElFTkSuQmCC';

let userProtocol = {
  title: '关于客户个人信息收集、使用规则的公告',
  content: [
    '尊敬的客户：',
    '根据《中华人民共和国反恐怖主义法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》(工业和信息化部令第24号)和《电话用户真实身份信息登记规定》(工业和信息化部令第25号)等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点(含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等)办理固定电话、移动电话(含无线上网卡)入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息，根据业务受理情况，必要时会致电客户本人，客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。',
    '如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。',
    '为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息:',
    '(a)事先获得客户的明确授权;',
    '(b)根据有关的法律法规要求；',
    '(c)按照相关司法机关和/或政府主管部门的要求；',
    '(d)为维护社会公众的利益所必需且适当；',
    '(e)为维护我公司或客户的合法权益所必需且适当。',
  ],
};

let interProtocol = {
  title: '客户服务协议',
  content: [
    '1.您应当为具有完全民事行为能力的自然人、个体工商户、农村承包经营户、 法人、非法人组织、获得法人或非法人组织授权的依法登记的经营单位。 如您为无民事行为能力人或限制民事行为能力人， 您签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。',
    '2.在签署本协议前，请您或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。',
    '3.您或其代理人签署本协议即视为完全理解并同意接受本协议的全部条款。',
    '4.您所需的业务内容、办理方式以及资费方案请详见相关业务说明。 根据《中华人民共和国合同法》《中华人民共和国网络安全法》 《中华人民共和国电信条例》及其他有关法律、 法规的规定，您同意就我方提供的通信服务的相关事宜，达成如下协议：',
    '一、用户真实身份信息登记要求',
    '1.根据《电话用户真实身份信息登记规定》（工业和信息化部令第25号） 及有关登记实施规范要求，您办理入网手续时须向我方出示有效证件、 提供真实身份信息；您委托他人办理入网手续的，代理人须出示您和代理人的有效证件， 提供您和代理人的真实身份信息；并且配合我方进行身份信息查验、登记，留存办理手续的记录。',
    '2.您为个人用户的，线上办理入网时使用二代居民身份证。',
    '3.您为单位用户的，办理入网时应出示统一社会信用代码证、或营业执照、 或事业单位法人证书、社会团体法人登记证书、或法律、 行政法规和国家规定的其他有效证件或者证明文件。 单位办理登记的，除出示以上相应证件外，还应当出示经办人的有效证件和单位的授权书。',
    '4.单位用户办理电话入网时， 必须向我方提供与每一张电话卡一一对应的实际使用人的有效证件， 由我方进行查验并登记实际使用人的身份信息。',
    '5.您应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整， 并有义务配合我方进行查验。您可以通过我方相应渠道查询已登记的身份信息。 如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的， 您应当及时到我方营业网点更正或变更。 因您登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由您自行承担。',
    '6.您申请入网时，在中国电信全国范围内登记在同一个个人用户名下的电话卡达到 5张的，我方根据国家相关规定不再为其开办新的电话卡。',
    '7.我方要求您提供担保的，您应当按照我方要求办理相应担保手续。',
    '二、您的权利和义务',
    '1.您有权监督我方的通信服务质量，及时提出改善意见和建议； 对我方提供的服务持有异议的，可以向我方进行咨询或投诉。',
    '2.您有权自主选择我方依法提供的各类通信业务，有权自主选择我方公示的资费方案， 有权自主选择我方提供的各类业务办理、咨询、查询和投诉渠道。',
    '3.您有权自主选择取得入网许可的终端、设备，并负责安装、 调测和维护（包括建筑内自建管线的维护）。 您自主选择的终端、设备应当具备符合我方网络的相应功能， 否则可能无法支持所选择的通信服务，您将自行承担损失。',
    '4.您对我方提供给您使用但所有权归我方的线路、设备或终端有保管责任， 不得转借、出租、出售或赠与他人，也不得用于非我方提供的业务中。 由于您原因造成该等线路、设备或终端损坏、丢失的，您需承担修复费用或按价赔偿。',
    '5.您应当按照约定的交费时间和方式及时、足额交纳通信费用。 通信业务一般按自然月为一个计费与结算周期，双方另有约定的除外。 逾期交纳通信费用（欠费）的，您须每日按所欠费用3‰的标准支付违约金， 并承担相应违约责任。由于通信设备产生费用清单及相关处理会有时延（如国际漫游业务等）， 可能会发生上一计费周期的部分通信费用计入下一计费周期收取的情况。',
    '6.您可以自愿订购或退订第三方服务 提供商通过我方网络提供的增值通信服务， 并同意我方根据第三方服务提供商的委托代为收取相关费用。 如您对代收费用有异议，可以向第三方或我方提出，由第三方自行解决或由我方协调第三方解决。',
    '7.您对交纳的通信费用及代收费用有异议的，应当在我方计费原始数据保存期限内向我方提出。',
    '8.您有权自主选择或终止以代扣、 银行托收等方式支付通信费用， 并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。',
    '9.您通信自由和通信秘密受法律保护。公安机关、国家安全机关、 人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，我方将依法配合。',
    '10.您不得二次转售、倒卖名下持有的电话卡。 如需转让给他人使用或实际使用人信息发生变化， 您须按本协议关于用户真实身份信息登记的约定， 办理过户手续或变更实际使用人信息， 否则我方有权采取关停号码、解除协议等措施。 由此产生的后果，我方不承担责任。',
    '11.您不得利用我方通信资源、通信服务等制作、复制、发布、 传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动， 否则我方有权暂停或限制向您提供通信服务， 直至终止通信服务。由此产生的后果，我方不承担责任：',
    '（1）反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的；',
    '（2）危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
    '（3）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；',
    '（4）散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；',
    '（5）散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；',
    '（6）侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；',
    '（7）发送违法信息、拨打诈骗电话，未经接收方同意或请求、 或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；',
    '（8）其他利用 我方通信资源与服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。',
    '12.您不得实施或协助他人实施下列危害我方网络安全和信息安全的行为， 否则我方有权暂停或限制向您提供通信服务，直至终止通信服务。 由此产生的后果，我方不承担责任：',
    '（1）侵入或非法控制我方网络或电信设备，盗接我方电信线路、非法复制我方电信码号；',
    '（2）非法获取、删除、修改我方网络或电信设备中存储、处理、传输的信息和应用程序；',
    '（3）利用我方网络从事窃取或破坏他人信息、损害他人合法权益的活动， 制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；',
    '（4）提供从事入侵我方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；',
    '（5）设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；',
    '（6）故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；',
    '（7）使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；',
    '（8）危害我方网络安全和信息安全的其他行为。',
    '13.您同意我方基于提供通信服务、与您沟通联系、改善服务质量等目的， 收集、储存并使用您个人信息，包括身份信息、服务数据及日志信息等。 身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、 出生日期、身份证件号码、拍照人像、住址、电话号码、电子邮箱等； 服务数据及日志信息包括但不限于您在使用我方服务过程中上传和产生的位置信息、 终端信息、通话记录、使用记录、订购信息、账单等。如您不同意提供或不同意我方收集、 储存并使用如上的某一或某些信息，您将可能无法成为我方的用户或无法享受我方提供的某些服务， 或无法达到相关服务拟达到的效果。本协议解除或终止后，我方不再收集新的您个人信息， 并将按照相关法律法规规定对本协议存续期间收集的您个人信息进行处理。',
    '14.未经您授权同意，我方不向除我方关联公司外的第三方提供您个人信息。 如您授权与我方有合作关系的银行、依法设立的其他征信机构、数据服务机构、 信息查询服务机构及信息验证服务机构等第三方向我方收集、核验您个人信息， 您同意我方有权向该等第三方提供相关的个人信息和核验信息。 其中我方关联公司是指我方现在或将来控制、受控制或与其处于共同控制下的任何公司、 机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力， 无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
    '15.为保护公共利益、优化通信服务质量、保障您通信服务知情权、及时警示通讯信息诈骗、 创新通信服务体验，您同意我方使用您个人信息通过营业网点、 网站、短信、互联网媒体、电子邮件、语音外呼等方式，向您告知社会公益、 通信服务、业务使用、诈骗风险警示等信息。',
    '16.您有权自主选择携号转网服务，您办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。',
    '17.办理携号转网业务过程中，您同意我方将必要的您身份信息提供给工业和信息化部集中业务管理系统（CSMS系统）， 用于比对携入方和携出方登记的身份信息是否一致。',
    '三、我方的权利和义务',
    '1.我方在其通信网络与设施覆盖范围内，向您提供通信服务； 其中，我方在签订有自动漫游协议的国家和地区的运营商的网络覆盖范围 内为您提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。 我方提供的通信服务应符合国家规定的通信服务质量标准。',
    '2.我方免费向您提供中华人民共和国境内 （为本协议目的，仅限大陆地区，不含港澳台） 火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。',
    '3.我方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。',
    '4.我方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、 公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、 通知或告知业务种类和服务项目、范围、时限、资费方案以及其他通信服务内容。',
    '5.我方可以通过电话热线、营业网点、网上营业厅、 掌上营业厅或短信等多种渠道为您提供业务办理、咨询、查询、障碍申告、投诉受理等服务。',
    '6.我方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。',
    '7.您向我方申告除网络覆盖和终端设备故障外的通信服务障碍， 我方应当自接到申告之日起，城镇48小时、农村72小时内修复或调通。',
    '8.我方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常通信服务的， 应当提前72小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。',
    '9.我方向您提供免费的电子账单服务，并有义务对账单进行解释。 我方对您计费原始数据保留期限为5个月（系统产生用户话单当月起后5个月，不含当月）， 法律另有规定除外。如数据保留期限内您对通信费用提出异议， 我方应当保存相应计费原始数据至异议解决。',
    '10.我方可以根据对您实际情况的综合评估，与您约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。',
    '11.我方有权根据具体业务类型按预付方式或后付方式向您收取通信费用：',
    '（1）预付方式下，您需预存费用。当账户余额加上信用额度（如有）不足以支付您拟使用的电信业务费用时， 您应当及时充值或交费，否则我方可暂停提供通信服务（“欠费停机”）。',
    '（2）后付方式下，您累计未交的费用到达信用额度、或到达交费期时， 您应当及时充值或交费，否则我方可暂停提供通信服务。 其中，您当月累计出账费用的交费期以中国电信各省分公司业务规定为准。',
    '（3）上述（1）、（2）欠费停机所涉业务，不包括单独的固定电话业务。 固定电话业务的欠费停机时间按照《中华人民共和国电信条例》的相关规定执行。',
    '12.您自欠费停机之日起60日内结清费用并申请复机的， 我方应当在收到您费用时起24小时内为您恢复暂停的通信服务（“复机”）。',
    '13.如您自欠费停机之日起满60 日仍未结清欠费和违约金， 我方有权自欠费停机第61日起终止提供通信服务，收回相应号码（“销户”）、 解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金， 并可以依法向征信机构提供您失信信息。',
    '14.为提供更为精准优质的服务，我方严格按照《网络安全法》、 《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号） 等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、 纸质返档，通过网络接收、读取并记录）中收集、使用您个人信息， 我方可以依法对包含您在内的整体用户数据进行分析并加以利用。 我方承诺对您个人信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供， 并应用行业通行的安全技术和管理手段（如脱敏展示、访问权限等）防止未经授权的访问和使用。',
    '15.为遵守相关法律法规或者行政机关、 监管机构等有权部门依法提出的要求以及基于诉讼 / 仲裁需要，我方有权披露您的个人信息。',
    '四、特殊情况的责任承担',
    '1.您入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由您自行承担责任。',
    '2.您密码是您办理业务的重要凭证。您入网后应当立即设置您密码，并妥善保管。为方便办理业务， 我方也可以根据您申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。',
    '3.凡使用您密码或随机密码定制、变更或取消业务的行为，均被视为您或您授权的行为。 因您对密码保管不善造成的损失由您自行承担，但我方有义务协助您或公安机关调查相关情况。',
    '4.您应当合理管理通信费用支出，出现异常的高额通信费用时（指超过您此前3个月平均通信费用5倍以上的费用）， 我方一经发现，应当尽可能迅速告知您，如告知后未得到您确认的，我方有权暂停本协议约定的部分或全部服务。',
    '5.在您欠费停机的情况下，我方有权拒绝为您办理除交费、通信费用查询外的其他电信业务。',
    '6.一方违约给对方造成直接损失的，应当依法承担赔偿责任。',
    '7.因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任， 但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。',
    '8.您使用本协议项下通信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等）， 由第三方承担责任。对于您提出的异议，我方可以协调第三方解决。',
    '五、协议的生效、变更、终止和解除',
    '1.除本协议另有约定外，本协议自您签字（您为自然人）或盖章（您为非自然人）、 且我方盖章之日起生效；通过电子渠道、电子签名、电子协议或其他电子数据形式签订本协议的， 您以点击确认等方式表示同意本协议的电子数据进入我方系统的时间视为您签字时间。 如您为办理号码携入并签订本协议的， 甲、乙双方签字或盖章后，本协议于我方收到 携号转网成功生效结果告知之时起生效。',
    '2.您如终止使用通信服务，应当结清通信费用及违约金（如有）， 并至我方营业网点申请销户或号码携出，销户或号码携出后本协议终止。',
    '3.未经双方同意并办理有关手续，甲、我方不得将依据本协议办理的业务全部或部分转让给第三方。',
    '4.您申请销户或号码携出时账户仍有余额的， 我方根据实际情况将余额一次性转入您指定的我方运营的其他有效号码的账户中， 或以中国电信充值卡等方式退还；您通过参与赠送、优惠、兑换等营销活动获取的余额， 不予退还，如按营销活动的业务规则另有约定的，按其业务规则处理。',
    '5.因我方电信业务经营许可证的业务范围、业务种类发生改变，或因技术进步、 国家政策变动等原因，我方无法向您继续提供原有业务或需对原有业务的服务方式、 服务功能、操作方法、业务号码等进行调整时， 我方有权变更或终止本协议，但应当提前发布公告，提出解决方案，做好客户服务。',
    '6.有下列情形之一的，我方有权单方终止或解除协议：',
    '（1）您未按照用户真实身份信息登记要求， 提供的登记资料不真实、不准确、不完整、无效，或经我方通知后未在合理期限内配合提供的；',
    '（2）您存在本协议第二条第11款、第12款所列任一行为，或由此引起用户投诉或举报的；',
    '（3）您以担保方式取得业务使用权的情况下，违反担保约定或担保人无能力履行担保义务的；',
    '（4）您未办理相关手续，自行改变通信服务使用性质、用途，或二次转售、倒卖电话卡，或自行转让协议的；',
    '（5）您利用我方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案；',
    '（6）您自欠费停机之日起60日内仍未结清所有欠费、违约金、赔偿金等费用的；',
    '（7）有权机关要求我方停止对您提供通信服务的；',
    '（8）您原因造成本协议无法履行的；',
    '（9）法律、法规、规章及规范性文件规定的其他情形。',
    '7.本协议终止或解除后，我方继续保留向您追交所欠费用的权利， 并有权收回您原使用的业务号码（销户的号码）、IP地址等电信资源。 为保证您权益，我方对销户的业务号码至少冻结90日，方可重新分配给其他用户使用。',
    '8.您改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用 （如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、 代扣等第三方协议，自行卸载您加载的第三方软件，避免造成个人信息泄露； 否则由此造成您或其他人损失由您承担。',
    '六、其他约定',
    '1.所有因本协议引起的或与本协议有关的争议， 双方将通过友好协商解决。协商不成的，您可向我方所在地有管辖权的人民法院提起诉讼。',
    '2.如果本合同的任何条款在任何时候变成不合法、 无效或不可强制执行但不从根本上影响本合同的效力时，本合同的其他条款不受影响。',
    '3.您办理各类业务所签署的业务登记单为本协议的一部分，业务登记单与本协议条款冲突的部分以业务登记单为准。 我方以公告等书面形式公开做出的服务承诺为本协议的补充，该等服务承诺与本协议冲突的部分以服务承诺为准。',
    '（您方承诺：本人已经充分、完整阅读并理解本协议所述全部条款及条件。）',
  ],
};

let noticeYDProtocol = {
  title: '关于客户个人信息收集，使用及保护的公告',
  content: [
    '尊敬的客户：',
    '您好！',
    '       根据《中华人民共和国个人信息保护法》、《中华人民共和国数据安全法》、《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理电话卡（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名、证件类型、证件号码等。同时，为更好地提供服务，可能需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。您在使用套餐类业务办理、通用流量包类业务办理、定向流量包类业务办理、权益+流量包办理、语音短信这类业务办理服务时，我们可能需要收集和使用您的手机号码、短信验证码，确保成功为您办理业务。',
    '上述数据会提供给我们的合作运营商，用于为您办理业务。在接受和使用我司销售分公司的服务和产品前，您应了解我司销售分公司的《隐私和信息保护政策》，（完整政策可查看网页https://dev.coc.10086.cn/coc/web2/Agreement/）同意本文件即视为您已了解并同意我司销售分公司的隐私和信息保护政策。',
    '       客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，中国移动有权不提供服务或终止服务。',
    '       为向客户提供优质、个性化的服务，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：',
    '1）事先获得客户的明确授权；',
    '2）根据有关的法律法规要求；',
    '3）按照相关司法机关和/或政府主管部门的要求；',
    '4）为维护社会公众的利益所必需且适当；',
    '5）为维护我公司或客户的合法权益所必需且适当；',
    '6）为确保您能成功办理中国移动独立或与我们合作的运营活动，我们会将您提交的信息提供给中国移动。',
    '',
    '',
    '河南天诺信息技术有限公司',
  ],
};

let internetProtocol = {
  title: '入网协议',
  content: ['尊敬的客户：', '您好！'],
};
let userInfoProtocol = {
  title: '关于客户个人信息收集、使用规则的公告',
  content: [
    '尊敬的客户：',
    '根据《中华人民共和国反恐怖主义法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》(工业和信息化部令第24号)和《电话用户真实身份信息登记规定》(工业和信息化部令第25号)等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点(含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等)办理固定电话、移动电话(含无线上网卡)入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息，根据业务受理情况，必要时会致电客户本人，客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。',
    '如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。',
    '为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息:',
    '(a)事先获得客户的明确授权;',
    '(b)根据有关的法律法规要求；',
    '(c)按照相关司法机关和/或政府主管部门的要求；',
    '(d)为维护社会公众的利益所必需且适当；',
    '(e)为维护我公司或客户的合法权益所必需且适当。',
  ],
};

let phoneProtocol = {
  title: '办理电话号码开户法律风险及防范提示告知书',
  content: [
    '为进一步加强打击治理通讯网络新型违法犯罪工作，根据《中华人民共和国刑法》、《中华人民共和国网络安全法》、《中华人民共和国电信条例》、《关于防范和打击电信网络诈骗犯罪的通告》、《工业和信息化部关于进一步防范和打击通讯信息诈骗工作的实施意见》等文件相关规定现对办理电话号码开户人法律风险及防范提示，告知如下：',
    '一、办理固定电话、移动电话等开户入网手续，必须持本人合法有效身份证件实名登记;不得冒用他人身份证件，或者使用伪造、变造的身份证件或单位证件办理入网手续。',
    '二、不得将办理的电话用于违法犯罪活动;不得转售、倒卖、出租电话卡。',
    '三、未取得电信业务经营许可证的任何组织或者个人，不得从事电信业务经营活动。',
    '请开户申请人严格遵守以上规定，如有违反，通信运营企业有权采取关停号码、解除合同、纳入信用管理等处置措施。对涉嫌伪造变造身份证件、帮助信息网络犯罪活动、非法经营等违法犯罪的，将依法严厉追究当事人法律责任。',
    '开户申请人已清楚明确阅读上述告知内容，将依法合规申请、使用相关电信业务。',
    '客户签名: 日期(年月日)',
  ],
};

let liabilityProtocol1 = {
  title: '电信网络诈骗犯罪及发送不良信息法律责任风险提示告知书',
  content: [
    '一、《中华人民共和国反电信网络诈骗法》第三十一条规定，任何单位和个人不得非法买卖、出租、出借电话卡、物联网卡、电信线路、短信端口、互联网账号等，不得提供实名核验帮助。',
    '二、《中华人民共和国民法典》一千零三十三条，除法律另有规定或者权利人明确同意外，任何组织或者个人不得以电话、短信、即时通讯工具、电子邮件、传单等方式侵扰他人的私人生活安宁。',
    '三、按照工业和信息化部关于治理电信网络诈骗、骚扰电话、垃圾短信的相关要求，不得买卖、出租、出借本人名下的电话卡，不拨打诈骗电话或协助不法分子拨打诈骗电话，不拨打骚扰电话，不发送垃圾短信，未经接收方同意，不向其拨打或发送营销类、骚扰类、高频类等情况的电话和短信。',
    '如果违反上述内容,我司有权限制、暂停号码的通信服务功能。',
    '四、本告知书一经阅读并确认，即视为已知悉并同意以上内容。',
  ],
};

let liabilityProtocol2 = {
  title: '电子渠道开户服务协议',
  content: [
    '尊敬的客户，您好！',
    '根据《中华人民共和国电信条例》及其他相关法律、法规的规定，在平等、自愿、公平、诚实、信用的基础上，为明确双方的权利和义务，规范双方业务行为，就您（下称“甲方”）与中国移动通信集团广东有限公司（下称“乙方”）电子渠道开户服务的有关事宜，达成并签定协议如下：',
    '一、移动通信服务的开通及服务内容',
    '1.甲方愿意使用乙方提供的号码，或者使用甲方自其它运营商携号转网携入的号码，接受乙方提供的电信服务，乙方愿意在现有技术条件下的网络覆盖范围内以及签订有漫游协议的电信运营商的网络覆盖范围内为甲方提供服务，具体服务内容由甲乙双方通过签署业务受理单或电话、短信等其他方式另行约定。（特别提醒：甲方使用4G网络服务，需更换4G USIM卡、办理4G套餐/产品和使用适配终端；甲方同时理解在现有技术条件下移动通信网络尚无法达到无缝隙覆盖。）',
    '2.甲方应使用取得国家入网许可并具有进网许可标志的终端设备，该终端设备应具有支持其所选服务的相应功能。甲方因使用未取得入网许可或不具备相应功能的终端设备所产生的后果由甲方自行承担。',
    '3.如果甲方订购第三方服务商提供的由乙方代收服务费的增值电信业务，乙方在收费前应当告知甲方即将定制的服务及收费方式并征得甲方同意。若甲方对代收的服务费有异议，由乙方、第三方服务商依据协议约定的相关原则和处理程序及时与甲方协商解决相关争议。',
    '4.甲方依据乙方公布的各渠道鉴权方式办理业务，甲方通过乙方鉴权方式办理的业务均视为甲方或甲方授权的行为。如使用服务密码或随机验证码办理业务的行为均被视为甲方或甲方授权的行为。',
    '5.甲方购买乙方产品或服务自订购成功并生效之日起资费方案有效期为1年，另有约定的除外。到期后若双方无异议，则自动按照当前资费方案续约。若乙方需修改资费方案及相关约定内容，应当提前30天通过短信、APP、微信、邮件、门户网站（WAP/WEB）等至少一种方式通知甲方，甲方可以选择修改后的资费方案或乙方提供的其他资费方案，若甲方不接受修改后的资费方案或乙方提供的其他资费方案，甲方应在结清全部费用后，办理相关服务终止手续。',
    '如政府主管部门对资费等相关内容的政策发生变化，乙方将根据相关要求，对原资费方案行相应调整。调整后方案的具体执行时间以乙方公示为准。',
    '6.根据《关于防范和打击电信网络诈骗犯罪的通告》的相关规定，乙方通过电子渠道发展新用户时，有权要求甲方办理实名登记，并采取在线视频实人认证等技术方式对甲方身份信息进行检验。',
    '7.甲方应当自购买卡号之日起30天内完成实名认证及激活。',
    '甲方收到该号码的实体USIM卡后，可选择实名认证及激活方式如下：（1）按照配送附带的操作指南进行自助实名认证及激活；（2）套卡客户持卡和身份证到乙方自有营业厅，在工作人员的协助下，进行现场实名认证并激活。',
    '8.若甲方逾期未完成实名认证并激活，甲方收到号码实体USIM卡将无法激活使用，乙方有权回收甲方购买的号卡及甲方选定号码。白卡开户的系统自动退款给原购销帐户上，套卡开户的费用不作客户退款。（注：白卡开户，包括但不限于18元/24元王卡开户、38元/88元大流量开户、动感青春卡开户；套卡开户，包括但不限于万能副卡。）',
    '二、甲方主要权利及义务',
    '（一）主要权利',
    '1.除双方另有约定外，甲方可以在乙方提供的资费方案范围内选择变更资费种类。',
    '2.甲方在结清所有费用后可以将本协议项下的号码过户给第三人。办理过户手续后本协议自动终止，甲方过户后不能查询或打印过户前的账单、清单、发票。',
    '3.甲方在结清所有费用后可以持登记的有效证件到乙方营业网点申请销户。除双方另有约定外，乙方不应拒绝甲方销户。',
    '4.甲方在销户后可以申请退还通过银行划扣、现金方式交清费用后的余额。参与营销活动取得的话费余额按活动协议约定进行处理，未约定的，优惠赠送余额随销户失效。',
    '5.除参与赠送、优惠等营销活动的费用余额外，甲方销户时（包括携号转网携出时）可以预约将余额一次性转入另一个在乙方开立的号码。',
    '6.乙方为甲方提供音频或视频通知音服务，在甲方拨打电话未接通前，通过音频或视频通知音的方式将被叫号码所处状态及时告知甲方；甲方确知乙方提供的音频或视频通知音服务可能含广告信息，甲方同意接收乙方在电信服务过程中显示的相关信息；甲方若不愿意接受此类信息或服务，有权通过乙方提供的关闭功能进行关闭，或通过付费方式进行服务升级，自行选择订阅专属化音视频内容。',
    '（二）主要义务',
    '1.甲方应遵守法律法规的规定使用乙方提供的服务。',
    '2.甲方使用乙方号码或自其他运营商携号转网携入的号码，并接受乙方提供的电信服务，需办理入网登记手续。甲方办理入网手续时，应当按照《电话用户真实身份信息登记规定》（工业和信息化部令第25号）向乙方出示有效身份证件进行实名制登记。甲方本人持有效身份证最多可为全国范围内5个中国移动号码进行实名制登记，甲方名下已登记中国移动号码达到5个的，乙方将根据规定拒绝甲方开通新号码的申请。甲方同意乙方影印留存甲方及其委托的受托人在办理入网登记时提供的身份证件。如甲方入网登记资料发生变更，应及时到乙方营业网点等渠道进行修订、完善。因甲方或其受托人提供的资料不详、不实或变更后未及时办理资料修订、完善手续等原因所造成的后果由甲方自行承担（以单位名开户的集团客户，使用人身份证件须与号码一一对应）。甲方因欠费销户，甲方将被限制办理乙方业务，甲方需缴清欠费后方能解除限制。',
    '3.如果甲方选择密码保护的，入网后应立即设置服务密码并妥善保管，因甲方原因密码保管不善造成的后果由甲方自行承担。若发现号码被盗的，甲方应及时拨打客服热线10086或到乙方营业网点办理停机手续，并向公安机关报案。乙方有义务按照法律规定协助有关国家机关依据职权等调查相关情况。',
    '4.甲方应按照与乙方约定的时间、方式，向乙方交纳费用账单中记载的全部费用。如甲方以过户方式取得号码使用权，甲方应该自行了解原号码的使用和缴费情况，若过户后存在转让人应缴未缴的费用（过户时未出账费用），由甲方承担。',
    '5.甲方开通的号码属于：',
    '□新号码。',
    '□重新放号号码。甲方应自行了解该号码曾捆绑的第三方信息，包括但不限于银行、支付宝、微信、水电煤气缴费，若需更新捆绑信息甲方应自行向第三方经营者提出。',
    '甲方同时确知乙方无从知晓且无权解除号码合法使用人与第三方服务机构之间的号码绑定约定。如甲方不再使用原号码，应当自行提前解除号码曾捆绑过的第三方信息，否则造成信息泄露或经济损失，由甲方自行承担责任，与乙方无关。甲方选择使用重新放号号码将可能需承担因此种绑定可能产生的不利后果。乙方无法预知上述可能后果。',
    '6.甲方应当通过乙方官方营业渠道购买电话卡（电话号码），并办理实名制登记手续。甲方不得二次转售、倒卖电话卡（电话号码）。如甲方存在转售、倒卖电话卡（电话号码）等违约行为，乙方有权对甲方采取关停号码、解除合同等措施，由此造成的后果由甲方承担。',
    '7.若甲方的号码属于吉祥号码，经甲、乙双方协商一致，甲方可以将吉祥号码过户给第三人。甲方和第三人应按照过户规则到乙方营业网点办理过户手续，在甲方结清所有费用后，由第三人按照过户时乙方关于吉祥号码的新入网政策（最新吉祥号码等级对应的首次预存话费标准、每月最低消费标准、承诺最低消费期限）与乙方重新签订吉祥号码使用协议，本协议自动终止。在办理过户手续之前，因使用吉祥号码导致的交费义务及一切后果仍由甲方承担。甲方申请过户时，首次预存话费已返还且未使用完的，由新机主享用。',
    '三、乙方主要权利与义务',
    '（一）主要权利',
    '1.乙方认为甲方存在以下情形之一的，乙方有权解除本协议，终止提供服务或限制甲方新入网申请；',
    '（1）甲方（包括受托人）提供的证件或资料虚假不实；',
    '（2）利用乙方提供的服务从事违法犯罪活动，或出现违规外呼、呼叫频次异常、超约定用途使用、转租转售等违反国家监管部门相关规定的行为；',
    '（3）利用乙方提供的服务从事损害社会公共利益或他人合法权益的活动；',
    '（4）利用乙方提供的服务从事违背公序良俗的活动；',
    '（5）甲方欠费满90日仍未交纳费用。',
    '或乙方收到国家有关部门通知及其他文件要求停止向甲方提供通信服务的，乙方亦有权解除本协议且不承担任何责任。',
    '2.乙方有权按照所欠费用自交费日期届满次日起每日加收3‰（千分之三）的违约金，并可以通过电话、信函、公告、委托第三方等方式追缴全部欠费和违约金。',
    '3.甲方在欠费停机期间，停机当月和复机当月的月使用费（套餐费）照常计收（另有协议约定的除外）。',
    '4.甲方销户90天后，乙方有权将甲方原号码回收后进行二次放号。',
    '5.若甲方承诺曾承诺消费或承诺在一定期限使用约定套餐，可享受乙方提供的相关优惠。在停机期间，乙方仍有权按照相关约定向甲方收取甲方所承诺消费金额和所承诺使用套餐所必须缴纳的费用。乙方采用市场化方式合理设定吉祥号码分类等级和合约周期，为甲方提供有合同期限的、非终身保底消费方案。',
    '6.甲方向乙方申请停机保号业务的，乙方有权依照相关规定收取停机保号费；若甲方办理主副卡相关业务的，主卡在申请停机保号同时，副卡亦进入停机保号状态，主卡和副卡均需分别支付各自的停机保号费。',
    '7.乙方根据实际业务情况可在甲方办理的资费方案或产品的电子或实物业务受理单上标注甲方所办理业务的网络服务范围（如，2、3、4G网络服务），甲方所办理的相关业务将仅限于规定网络服务范围内使用规定。',
    '8.如果甲方丧失民事主体资格，本协议自动终止，乙方有权作销户处理，收回号码，终止提供电信服务。',
    '（二）主要义务',
    '1.乙方提供的电信服务和收取的相关费用应当符合国家相关规定、政府主管部门发布的相关标准和甲乙双方的约定。',
    '2.乙方通过营业厅、门户网站、10086热线、微博、微信、APP等渠道公示服务项目、服务时限、服务范围及资费标准等内容，并向甲方提供便捷的业务办理和客户投诉受理渠道。',
    '3.欠费停机之日起90日内，甲方交清欠费和违约金的，乙方应在甲方交清欠费和违约金后的24小时内恢复服务。',
    '4.乙方为甲方提供免费的话费查询服务，并对账单、清单进行解释。清单仅显示需要向甲方收费的业务使用记录。',
    '乙方计费原始数据保留期限为5个月(系统产生用户话单当月起后5个月，不含当月)，甲方确知在产生原始数据后5个月内未查询的，将无法再次查询相关信息。若甲方对乙方收取的费用存有异议，应在上述计费原始数据保留期限内及时提出，上述期限内未提出的，乙方将无法对甲方异议予以原始数据复核，将以乙方现存信息为准；逾期提出的，甲方应承担由此产生的不利后果。甲方对乙方收取的费用提出异议的，乙方有责任进行调查、解释和处理。经核实确属乙方责任多收费用的，乙方应将已多收的部分按对外承诺的方式返还甲方。',
    '5.乙方需对甲方提供发票，发票保留期限为5个月(不含当月),补打发票按税法相关规定执行。',
    '6.乙方每月为甲方免费提供139邮箱电子账单自动推送服务，甲方如需取消服务可点击账单邮件底部“退订邮件”按钮进行退订。',
    '7.甲方销户之日起90天内，甲方要求号码恢复使用，可凭有效证件在乙方指定的营业厅办理销户重开。',
    '四、隐私和通信权益保护',
    '1.甲方的通信自由和通信秘密不受侵犯。甲方在申请办理乙方相关业务时，甲方同意乙方为服务目的收集甲方相关个人信息资料，乙方对甲方的个人信息（包括但不限于客户资料和通信信息）负有保密义务。但根据法律、行政法规规定，司法、行政机关依法要求乙方提供协助和配合，乙方应给予协助和配合的，不构成违反保密义务。',
    '2.为提供更为精准优质的服务，乙方应严格按照《网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）、《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、纸质返档，通过网络接收、读取并记录）中收集、使用甲方个人信息，乙方有权依法对包含甲方在内的整体用户数据进行分析并加以利用。未经甲方同意，乙方不向除乙方关联公司外的第三方提供甲方个人信息。甲方可以通过营业厅或乙方指定的其他渠道，对其个人信息进行查询、更正。为了让甲方及时了解和享受乙方推出的最新业务或优惠，甲方同意乙方可以通过电话、短信、邮件、媒体广告等方式向甲方告知或推荐使用。',
    '3.甲方在接受和使用乙方的服务和产品前，应了解乙方的《隐私政策》（该政策已在乙方官方网站发布并可在服务厅索取），本协议签署即视为甲方已了解并同意乙方的隐私政策。',
    '4.甲方通过乙方提供的服务发送信息、拨打电话，必须遵守国家的有关规定和行政规章制度。如甲方利用乙方提供的服务发送垃圾信息、违法信息或拨打骚扰电话，包括但不限于反动类、涉黑类、涉黄类、诈骗类、谣言类、推销类，乙方有权采取停止短信功能、停机、解除服务协议等措施，并配合公安部门追查信息来源，依法追究甲方的相关法律责任。甲方的上述行为如违反国家法律法规，乙方有权视具体情况拒绝甲方恢复通信功能的申请。',
    '五、携号转网',
    '1.根据工信部文件要求，携号转网服务是指在同一本地网范围内，蜂窝移动电话用户变更签约的基础电信业务经营者而用户号码保持不变的一项服务。甲方申请携号转网服务的相关权利义务说明如下：',
    '（1）甲方有权依据国家携号转网相关的有效规定，向乙方申请携号转网。',
    '（2）乙方根据甲方申请，为甲方办理携号转网过程中，乙方需要将必要的甲方资料提供给携号转网集中业务管理系统，以用于比对携入方和携出方登记的号码使用人身份信息是否一致。',
    '（3）甲方办理携号转网（包括转入、转出）应符合相关条件并提供所需要的材料。甲方携号转网的条件及所需要的材料，乙方已通过营业网点、门户网站、10086热线等渠道进行公示。',
    '（4）甲方申请号码携入乙方网络时，因不同网络技术与设备等差异，甲方可能需要更换终端。甲方启用携入新卡的时间点及网络切换期间可能会短时间影响正常通信。',
    '（5）甲方携入乙方网络前，应当与携出方结清申请携号转网时已出账的费用，同时，甲方确认在携出方享有的积分、VIP级别、赠送话费及相关优惠权益都将无法继续使用，携出方及其合作伙伴提供的部分业务或服务将无法继续使用。',
    '（6）甲方需及时将携号转网事宜告知社会上号码相关的服务提供者，以免携号转网后无法正常接收短信和电话等相关信息。',
    '2.甲方向乙方申请将号码携出至其他运营商网络的，甲方在其他运营商申请办理号码携入后，乙方终止向甲方提供电信服务，甲方不再就该号码向乙方或乙方关联公司主张任何权益或优惠。',
    '3.甲方应在完成网络切换后30日内或在收到账单后10日内向乙方结清携出时未出账的费用。甲方超过约定交费日未交足费用的，乙方有权按照所欠费用自交费日次日起每日加收3‰（千分之三）的违约金，且乙方有权将甲方欠费信息共享给甲方的携入方运营商，并委托携入方运营商向甲方代为追缴甲方所欠费用及违约金。',
    '4.甲方携带其他运营商号码与乙方签订本协议的，乙方有权根据携出方运营商的委托，代其追缴甲方在号码携出时未结清的携出方运营商的电信费用及违约金，追缴方式包括但不限于从甲方话费账户中扣减。',
    '5.甲方办理乙方的业务如存在在网协议期限，甲方在协议有效期内办理携号转网构成违约，甲方需按相关解约条款向乙方支付违约金（包括但不限于营销活动违约金、家庭宽带优惠补收费）。',
    '6.甲方办理乙方业务存在优惠期或有效期的，均属于承诺在网期限，甲方在承诺在网期限内需持续使用中国移动网络。',
    '7.甲方携带其他运营商号码携入乙方网络的，甲方入网协议自乙方提供的移动电话卡（电话号码）激活启用之日（即完成网络切换之日）起生效。',
    '六、解约条款',
    '1.甲方订购乙方业务，且乙方业务无任何优惠或补贴，甲方未从乙方获得实物或权益，甲方可无条件向乙方申请解约。',
    '2.甲方办理乙方资金优惠类业务（包括但不限于话费存送、套餐折扣），甲方如要求提前终止协议，则甲方需按照已履行月份获赠金额或优惠减免金额总额、以及未履行月份所有低消或套餐金额总额的一定比例（法律规定范围内）向乙方支付违约金。',
    '3.甲方办理乙方业务赠送类业务（包括但不限于承诺消费送流量包、语音包），甲方如要求提前终止协议，则甲方需按照已获赠业务进行价值折算向乙方支付违约金。非流量类（语音、短信等）按终止协议时市场单价资费折算，流量类按终止协议时市场单价优惠资费折算。',
    '4.甲方办理乙方宽带类业务（包括但不限于网龄送宽带，承诺低消送宽带），甲方如要求提前终止协议，则甲方需按照前期活动约定的初装费用或拆机费用向乙方支付违约金。',
    '5.甲方办理乙方实物赠送类业务（包括但不限于承诺消费送终端、充值卡），甲方如要求提前终止协议，甲方应当根据退货要求在不影响二次销售的前提下，将赠送实物退还至乙方后方可解约；如赠送实物已不满足退货条件，则甲方应当根据实物赠送价值及未履行承诺期限予以乙方补偿。',
    '6.甲方办理乙方权益赠送类业务（包括但不限于咪咕视频、爱奇艺、优酷会员权益），甲方要求提前终止协议的，如甲方未使用权益，可在完成赠送权益回退后解约；如甲方已领取或使用权益，则甲方应当按照已使用权益折算后的价值向乙方支付违约金后方可解约。',
    '7.甲方办理乙方实物租赁类业务（包括但不限于家庭宽带机顶盒、光猫、路由器），甲方如要求提前终止协议，甲方应当按照活动约定，在不影响二次销售或租赁的前提下将租赁实物退回至乙方后方可解约；如租赁实物已遗失或损坏，则甲方应当按照租赁设备价值进行等价偿还。',
    '8.甲方办理乙方融合类业务（包括但不限于融合套餐、主副卡、统付、共享、家庭网、集团网、惠农网、双向亲情互打），甲方如要求提前终止协议，则甲方应当在不影响其他甲方业务使用的情况下，根据具体情况进行退订或解除后方可解约。甲方解除主卡、共享者之前，应当及时通知其他成员，因甲方通知不及时导致其他成员业务无法使用等后果由甲方承担。甲方解除副卡、被共享者，不应对其他成员权益造成损失。',
    '9.甲方办理乙方吉祥号码业务（如四连号、三连号等），如甲方要求提前终止协议，则甲方应当按照未履行月份所有低消或套餐金额总额向乙方支付损失赔偿金，如甲方有签约减免或降档低消金额特殊情况的，采用前述同样标准执行。（参考最新吉祥号码等级对应的低消或套餐标准）。',
    '10.甲方办理乙方集团信息化业务，甲方如要求提前终止协议，则甲方应当按照具体业务协议条款约定标准向乙方支付违约金后方可解约。',
    '七、其他约定事项',
    '1.甲方办理各类业务所签署的业务受理单、表单、协议等为本协议的补充协议，与本协议冲突部分以补充协议为准，补充协议中未约定部分适用本协议。',
    '2.乙方有权在必要时变更本协议条款。乙方变更本协议条款的，将以包括但不限于在营业网点告示、门户网站公告、电子邮箱、手机短信通知等方式中的一种或多种，向甲方发送本协议的变更情况，甲方可以根据上述方式查阅相关的变更协议条款。本协议条款变更且乙方及时通过上述方式通知甲方后，如果甲方继续使用乙方提供的电信服务，即视为甲方已接受变更后的协议。如果甲方不接受变更后的协议，可向乙方申请销户或携号转网。',
    '3.本协议项下发生的争议，甲乙双方应协商解决。协商不成的，双方可向乙方住所地人民法院提起诉讼。',
    '八、特别提示',
    '1.甲方同意乙方通过业务端口（不限于10086端口）、微博、微信和邮箱等渠道不定期向甲方发送服务提醒、关怀、升级、推荐和优惠等信息。',
    '2.甲方通过乙方APP、微信、门户网站、充值卡等渠道充值的话费具有使用期限限制，甲方请留意充值提示界面，并及时在有效期限内使用。',
    '3.乙方提供的来电显示、呼叫转移服务将遵照国家规范及行业标准进行，对于其他运营商（含国外运营商）传送的号码将如实显示，但乙方不保证该号码在发送端的真实性，甲方应谨防可能存在的网络违法侵权风险。甲方应采取谨慎的方式仔细甄别号码的真实性（如通过回拨等方式予以确认），谨防可能存在的网络信息诈骗及其他第三方违法侵权风险。',
    '4.乙方根据现行的电信行业规范保障甲方的通信安全，并对甲方在乙方账户内的话费余额负有安全保障义务，对超出乙方服务范围的业务及资金安全，乙方声明不承担任何安全保障义务。甲方需对其将手机号码与金融机构、第三方支付机构及其他服务提供商进行绑定，将本号码作为甲方与第三方的鉴权方式、账号密码找回方式等可能造成甲方信息泄露、资金损失的途径的行为自行承担可能产生风险，乙方对上述将号码关联其他服务商而造成的损失不承担任何责任，因此造成的不利后果需由甲方自行承担。',
    '5.乙方关联公司是指乙方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
    '6.甲方或其受托人已详细阅读本协议的全部条款，特别是加粗条款，甲方或其受托人在签署本协议后即视为完全理解并同意接受本协议的全部条款。',
    '九、协议生效和效力',
    '1.本协议自甲方勾选“同意”按钮时或者电子签名的方式签署时生效，生效即视为甲方完全理解并同意接受本协议的全部条款，特别是加粗条款。本协议的任何条款如因任何原因而被确认无效，都不影响本协议其他条款的效力。',
    '2.甲方名下任意一个手机号码登录电子渠道并“同意”本协议时，等同于本协议对甲方名下所有手机号码具有效力。',
    '3.甲方“同意”本协议外，甲方在乙方线下渠道或者电子渠道办理各类业务所签署的业务受理单、表单、协议等为本协议的补充协议，与本协议冲突部分以补充协议为准，补充协议中未约定部分适用本协议。',
    '4.本协议项下发生的争议，甲乙双方应协商解决。协商不成的，双方可向乙方住所地人民法院提起诉讼。',
    '5.本协议有效期为1年。在有效期届满前30天，如果任何一方未向对方提出异议，有效期届满后则：',
    '□本协议自动展期1年，展期次数不限；',
    '□本协议终止。',
    '附件： 《个人单位在通信企业申请通讯工具涉电信网络新型违法犯罪法律责任及防范提示告知书》',
  ],
};

let liabilityProtocol3 = {
  title: '中国移动广东公司电子渠道商品销售服务协议',
  content: [
    '尊敬的客户，您好：',
    '根据《中华人民共和国电信条例》及其他相关法律、法规的规定，在平等、自愿、公平、诚实、信用的基础上，为明确双方的权利和义务，规范双方业务行为，就您（下称“甲方”）与中国移动通信集团广东有限公司（下称“乙方”）电子渠道开户服务的有关事宜，达成并签定协议如下：',
    '一、 电子渠道商品销售相关定义',
    '1.1、乙方电子渠道包括但不限于门户网站（gd.10086.cn）、手机WAP网站（wap.gd.100086.cn），10086APP客户端、10086热线、10086短信服务厅。',
    '1.2、电子渠道商品包括但不限于语音、短信、流量、手机、手机配件、号码卡、数据卡、路由器及其预存营销案。',
    '1.3、商品销售订单履行，指商品订单下单后的执行过程，包括但不限于配送上门或客户自提、订单包裹交接、商品涉及的业务开通、后续话费返还，具体以乙方电子渠道公布的履行电子渠道商品销售及服务（下称“电子服务”）方式和要求为准。',
    '二、 服务内容及承诺',
    '2.1、甲方愿意购买乙方电子渠道相关商品，并接受乙方提供的相关电子服务；乙方愿意在现有技术条件下按照本协议的规定及其不时发布的操作规则为甲方提供电子服务，具体电子服务内容根据甲方通过乙方电子渠道购买商品或接受服务的具体内容确定。',
    '2.2、甲方同意本协议的全部条款并按照相关提示完成全部操作以获取乙方电子服务，否则视作甲方放弃乙方提供的电子服务。',
    '2.3、甲方在操作过程中点击或回复“同意”等相关内容即表示已详细阅读点击或回复的内容及本协议的全部条款，并完全理解并同意接受点击或回复的内容及本协议的全部条款。',
    '2.4、乙方提供的电子服务应当符合法律法规的规定，甲方应遵守法律法规的规定使用乙方提供的电子服务。',
    '2.5、乙方应向甲方提供便捷的业务办理和客户投诉受理渠道，乙方应在接到客户投诉后按对外承诺时间通过10086热线、电子渠道等方式予以回复。',
    '三、 电子服务协议签定与履行',
    '3.1、甲方按照乙方电子渠道提示选择合适商品，并需进行必要身份信息验证；甲方在下单前应清楚了解商品相关内容，成功下单后不能将不了解商品或服务作为不履行订单的理由。',
    '3.2、甲方应在确认所选购商品后，认真阅读乙方通过电子渠道展示的本协议相关内容，同意后按乙方电子渠道的提示进行相关操作，一旦完成操作，即视为甲方完全理解和接受本协议的全部条款。',
    '3.3、甲方应按照乙方电子渠道提示，选择合适的履行电子服务方式（包括但不限于配送上门等，具体以乙方电子渠道信息公布为准），完成相关信息的填写，并确保信息的真实性。',
    '3.4、除不可抗力外，成功下单后双方都应按照乙方电子渠道公布的履行电子服务方式和要求完成订单履行。履行订单时，甲方需提供本人真实有效的身份证件（仅限于身份证）。',
    '四、 费用及费用交纳',
    '4.1、甲方购买并使用乙方提供的电子服务应支付相关费用，乙方收取的相关费用应符合法律法规的规定和相关订单的约定。',
    '与相关网络服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置等）及其所需的费用（如为接入互联网而支付的电话费及上网费等）均应由甲方自行负担。',
    '4.2、甲方应按照与乙方约定的方式（包括但不限于在线支付、货到付款等），向乙方交纳订单全部费用，订单费用包括但不限于商品价格、运费及相关税费。',
    '4.3、甲方有权要求乙方提供费用查询服务，乙方有义务就订单费用对甲方合理要求进行解释。',
    '五、双方的权利和义务',
    '5.1、甲方应保证其为签订本协议提供的相关资料真实、准确、有效，甲方承诺当资料发生变更后，及时按乙方业务流程通知乙方更改；若甲方提供的资料不真实或存在其它法律障碍，乙方有权随时暂停服务，并解除本协议及本协议有效期内与甲方签订的任何协议，由此造成乙方损失的，乙方将保留追究甲方赔偿乙方损失的权利。',
    '5.2、甲方通过电子渠道成功下单，甲乙双方本次商品交易即达成，即视为甲方完全接受本协议项下的全部条款，双方应严格按照本协议及订单约定完成订单履行；非因不可抗力因素不履行或不完全履行订单的，另一方有权要求违约方赔偿违约造成的损失。',
    '5.3、手机号码和客户密码是甲方重要个人资料和用户身份识别凭证，甲方应妥善保管，凡按照乙方业务规则使用手机号码和客户密码通过乙方电子渠道购买的商品或服务，均视为甲方亲自办理，并由甲方负责。甲方应当对以其手机号码和客户密码签权进行的所有活动承担法律责任。',
    '5.4、乙方需通过相关合适的电子渠道向甲方提供电子服务、本协议相关内容的查询服务，甲方需按法律法规要求合理使用该查询服务，不得损害乙方权益。',
    '5.5、甲方在使用乙方电子服务过程中，需遵循以下原则：',
    '（1）遵守中国有关的法律和法规；',
    '（2）不得为任何非法目的而使用乙方系统或电子服务；',
    '（3）遵守所有与电子服务有关的协议、规定和程序；',
    '（4）不得利用乙方系统进行任何可能对互联网的正常运转造成不利影响的行为；',
    '（5）不得利用乙方系统发布、传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；',
    '（6）不得利用乙方电子服务系统进行任何侵害乙方合法权益的行为；',
    '（7）如发现任何非法行为、隐患或安全漏洞的情况，应立即通告乙方。',
    '六、协议的变更和终止',
    '6.1、甲方依据本协议通过电子渠道购买乙方产品或服务的资费方案有效期，以下单时相关产品或服务的相关说明或约定为准（资费方案有效期默认为1年，双方特殊约定的除外）。',
    '有效期满后，乙方如需修改资费方案及相关约定内容，应在修改前30天通过不限于短信、APP、微信、邮件、门户网站（WAP/WEB）等方式通知甲方，甲方可以选择修改后的资费方案或乙方提供的其他资费方案。若甲方不接受修改后的资费方案及乙方提供的其他资费方案，在结清全部费用后，乙方可解除本协议。',
    '有效期期满后，乙方无需修改资费方案及相关约定内容，若双方未提出异议，原资费方案自动延期。',
    '如遇政府主管部门统一调整资费标准，本协议按政府主管部门调整后的资费标准在规定时间起执行。',
    '6.2、非乙方原因导致订单履行失败，则视本协议自动终止，乙方对此不承担任何法律责任；如甲方对本订单相关商品仍有需要，则需通过乙方电子渠道重新下单。',
    '七、所有权及隐私保护',
    '7.1、乙方提供的电子服务内容包括但不限于文字、软件、声音、图片、录像、图表，所有内容均受版权、商标和其它财产所有权等国家相关法律保护；甲方只有在获得乙方或其合法权利人的合法授权之后才能使用，而不能擅自复制、或创造与之有关的派生产品。',
    '7.2、甲方的通信自由和通信秘密不受侵犯，乙方对甲方的客户资料和通信信息负有保密义务。但根据法律法规规定，司法、行政机关依法要求乙方提供协助和配合，乙方给予协助和配合的，不构成违反保密义务。',
    '7.3、甲方不得利用乙方提供的服务从事违法犯罪活动或损害社会公共利益的活动，不得发送违法信息或未经接收客户同意发送骚扰信息，不得有拨打骚扰电话等不当行为，否则，乙方可以暂停向甲方提供服务，直至终止服务，由此造成的后果由甲方自行承担。',
    '7.4、甲方同意保障和维护乙方利益，如因甲方违反有关法律法规或本协议项下的任何条款而给乙方或第三方造成损失，甲方需承担损害赔偿责任。',
    '甲方隐私被他人非法窃取，乙方将在法律法规范围内积极配合甲方主张权利。',
    '7.5、乙方保证不对外公开或向第三方提供甲方资料，但下列情况除外：',
    '(1) 乙方为正常生产经营、履行订单等所必要；',
    '（2）事先获得客户的明确授权；',
    '（3）根据有关的法律法规要求；',
    '（4）按照相关政府主管部门的要求；',
    '（5）为维护社会公众的利益。',
    '八、 重要声明',
    '8.1、甲方在协议签定、履行等环节不按乙方操作规则和提示完成的，或乙方已经明示其电子服务发生变更并提醒甲方应当注意事项，但甲方未按要求操作，所产生的不利后果由甲方自行承担。',
    '8.2、甲方理解并同意接受使用电子服务所存在的风险，并同意完全承担非乙方原因产生的此类风险责任。',
    '8.3、由于电子渠道的特殊性，乙方申明不保证乙方电子渠道提供的服务不会中断，不保证乙方电子渠道提供的服务的绝对及时、安全、真实和无差错，也不保证甲方发送的信息能完全准确、及时、顺利地被传送。',
    '8.4、如因系统维护或升级等需要而需暂停电子服务，乙方将尽可能事先在电子渠道公告，甲方或任何第三方未及时按照公告内容采取相应措施而造成损失，乙方将不承担任何责任。',
    '8.5、对于订单履行的部分工作，乙方将在法律允许范围或参考行业做法，聘请第三方协助开展，因第三方责任造成甲方意外损失的，损失将由第三方负责赔偿，乙方将积极配合甲方主张权利。但因不可抗力等原因除外。',
    '8.6、电子服务及本协议相关内容可通过电子渠道页面、电子邮件或短信等方式公告或传达，该类公告或传达之时即视为已送达甲方，因甲方未留意、未阅读等原因造成其损失的，乙方不承担任何责任。',
    '8.7、如发生下列任何一种情形，乙方有权随时中断或终止向甲方提供本协议项下的服务，乙方不承担任务责任：',
    '（1）甲方提供的资料不真实、不准确、不全面；',
    '（2）甲方违反本协议中规定的使用规则；',
    '（3）发生不可抗力、不可预测因素或非乙方故意或政府行为等原因导致本协议无法履行。',
    '九、争议解决',
    '甲方和乙方在履行本协议的过程中，如发生争议，应友好协商解决。协商不成的，任何一方均可向乙方住所地人民法院起诉。',
    '十、 协议生效和效力',
    '10.1、甲方通过乙方电子渠道或其他非电子渠道购买商品时签署的业务清单、表单、协议等为本协议的补充协议，与本协议冲突部分以补充协议为准，补充协议中未约定部分以本协议为准。',
    '10.2、本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。',
    '10.3、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。',
    '10.4、本协议为电子协议，保存在乙方相关电子渠道，甲方可通过相关乙方电子渠道查询；甲方在乙方电子渠道完成下单，本协议立即生效。',
    '10.5、本协议为上一级主体协议《电子渠道登录服务协议》的子协议，以附件的形式作为主体协议的一部分。本协议的效力受《电子渠道登录服务协议》约束，对同类业务或服务的条款说明以《电子渠道登录服务协议》的相关条款内容为准。',
  ],
};

let liabilityProtocol4 = {
  title: '校园客户新入网优惠协议',
  content: [
    '甲方：中国移动通信客户',
    '乙方：中国移动通信集团广东有限公司广州分公司',
    '1、甲方是广州高校客户（持有效录取通知书/有效学生证件的客户），并保证参加活动的手机号码（下称“办理号码”）在入网时办理39元及以上动感地带潮玩套餐或动感地带学霸套餐，即可享受以下校园客户新入网优惠。',
    '2、甲方同意在新激活号卡入网当月一次性充值不低于50元话费，充值后可享480元月租优惠，每月减免月租10元，有效期48个月，月租减免优惠在充值后下一个月结日起生效。如未按时充值或充值金额不足则无法享受优惠。',
    '3、甲方在优惠期内仅可办理一次本优惠，如在优惠生效前主动通过其他渠道办理上述优惠，则不再重复生效。优惠期内须在网且使用39元及以上动感地带潮玩套餐或动感地带学霸套餐，如转品牌、转运营商或取消本优惠，则不能继续享受优惠，并按照已履行月份优惠所减免金额向我司支付违约金。',
    '4、以下号码情况不能参与本活动：非广州移动客户、限定客户、2G/3G不限量套餐用户、4G随心王套餐客户、多号通的副号、一卡双号副号、家庭服务计划副号、万能副卡等附属号码客户、内部员工号码、集团代付／对公托收（如政企动力100行业卡）客户、集团全额统付号码、万花筒客户、手机状态异常（停机、销户、欠费、转品牌未生效等状态）号码。',
    '5、甲方承诺所登记的学生证件资料真实有效，且与办理号码机主资料对应。如乙方后期通过技术手段等方式查实甲方非高校客户，乙方有权终止优惠，充值的话费的仅作为普通充值话费。',
    '6、甲方或其受托人已详细阅读本协议的全部条款，特别是加粗条款，甲方或其受托人在签署本协议后即视为完全理解并同意接受本协议的全部条款。',
  ],
};

let liabilityProtocol5 = {
  title: '中国移动通信客户服务协议',
  content: [
    '甲方：中国移动通信客户',
    '乙方：中国移动通信集团广东有限公司        分公司',
    '一、移动通信服务内容',
    '1.甲方愿意使用乙方提供的号码，或者使用甲方自其它运营商携号转网携入的号码，接受乙方提供的电信服务，乙方愿意在现有技术条件下的网络覆盖范围内以及签订有漫游协议的电信运营商的网络覆盖范围内为甲方提供电信服务，具体服务内容由甲乙双方通过签署业务受理单或电话、短信等其他方式另行约定。（特别提醒：甲方使用4G/5G网络服务，需更换为USIM卡、办理4G/5G套餐/产品和使用适配终端；甲方同时理解在现有技术条件下移动通信网络尚无法达到无缝隙覆盖。）',
    '2.甲方应使用取得国家入网许可并具有进网许可标志的终端设备，该终端设备应具有支持其所选服务的相应功能。甲方因使用未取得入网许可或不具备相应功能的终端设备所产生的后果由甲方自行承担。',
    '3.乙方根据实际业务情况可在甲方办理的资费方案或产品的电子或实物业务受理单上标注甲方所办理业务的网络服务范围（如，2、3、4、5G网络服务），甲方所办理的相关业务将仅限于规定网络服务范围内使用。',

    '4.甲方可自由选择是否订购第三方服务商提供的由乙方代收服务费的增值电信业务（以下简称“代收费业务”）。',
    '5.甲方依据乙方公布的各渠道鉴权方式办理业务。甲方通过乙方鉴权方式办理的业务均视为甲方或甲方授权的行为，包括但不限于如使用服务密码或随机验证码等鉴权方式办理业务的行为。',
    '6.乙方将根据法律法规要求，免费向甲方提供中华人民共和国大陆境内火警 119、匪警110、医疗急救120、交通事故报警122等紧急电话的接入服务。',
    '7.甲方接受乙方提供的电信服务的资费方案有效期为1年，自成功订购并生效之日起计算，双方另有约定的除外。到期后若双方无异议，则自动按照当前资费方案续约。',
    '如政府主管部门对资费等相关内容的政策发生变化，乙方将根据相关要求，对原资费方案行相应调整。调整后方案的具体执行时间以乙方公示为准。',
    '8.乙方通过营业厅、门户网站、10086热线、微博、微信、APP等渠道公示服务项目、服务时限、服务范围及资费标准等内容，并向甲方提供便捷的业务办理和客户投诉受理渠道。',
    '9.乙方为甲方提供音频或视频通知音服务，在甲方拨打电话未接通前，通过音频或视频通知音的方式将被叫号码所处状态及时告知甲方；甲方确知乙方提供的音频或视频通知音服务可能含广告信息，甲方同意接收乙方在电信服务过程中显示的相关信息；甲方若不愿意接受此类信息或服务，有权通过乙方提供的关闭功能进行关闭，或通过付费方式进行服务升级，自行选择订阅专属化音视频内容。',
    '二、入网登记',
    '1.甲方使用乙方号码或自其他运营商携号转网携入的号码，并接受乙方提供的电信服务，需通过乙方官方营业渠道办理入网登记手续。甲方办理入网登记手续时，应当按照《电话用户真实身份信息登记规定》（工业和信息化部令第25号）向乙方出示有效身份证件进行实名制登记。甲方委托他人办理入网登记手续的，受托人应当出示甲方和受托人的有效身份证件，并提供甲方和受托人的真实身份信息。甲方及其受托人同意乙方影印留存甲方及其受托人在办理入网登记时提供的身份证件。',
    '甲方应为或被视为完全民事行为能力人。如甲方为无民事行为能力人或限制民事行为能力人，则按相关法律法规规定处理。',
    '甲方以其有效身份信息在全国范围内最多可办理5个中国移动号码的入网登记手续。如甲方名下已登记中国移动号码达到5个的，乙方有权拒绝甲方新入网登记手续申请。以单位名义开户的集团客户号码，需同时对应登记号码实际使用人个人身份信息。',
    '2.甲方或其受托人拒绝出示有效身份证件提供真实身份信息的，或冒用他人的证件的，或使用伪造、变造的证件的，乙方有权不为其办理入网手续。甲方冒用他人身份证件或使用伪造、变造身份证件骗取办理入网手续的，应自行承担相关法律责任，同时乙方有权解除本协议，因此造成乙方损失的，甲方应当予以全额赔偿。',
    '3.甲方开通的号码（不包括其他运营商携入号码）属于重新放号号码。甲方应自行了解该号码曾捆绑的第三方应用信息，包括但不限于银行、支付宝、微信、水电煤气缴费，若需更新捆绑信息甲方应自行向第三方经营者提出。甲方明确知悉乙方无从知晓且无权解除该号码此前与第三方经营者之间的号码绑定约定。',
    '4.如甲方入网登记资料发生变更，应及时到乙方营业网点等渠道进行修改、完善。因甲方或其受托人提供的资料不详、不实或变更后未及时办理资料修改、完善手续等原因所造成的后果由甲方自行承担。',
    '5.甲方在入网时应对服务密码进行设置或修改并妥善保管。因甲方密码设置或保管不善造成的后果由甲方自行承担。若号码被盗的，甲方应及时拨打客服热线10086或到乙方营业网点办理停机手续，并向公安机关报案。乙方有义务按照法律规定对有权国家机关进行的案件调查取证等予以协助。',
    '为方便办理业务，乙方可根据甲方申请以短信等方式提供随机密码，该密码可作为办理业务的临时凭证。甲方认可，其使用服务密码、随机密码办理业务的行为均为甲方或甲方授权的行为。',
    '6.甲方SIM卡丢失或毁损的，甲方可凭在乙方登记的有效身份证件到乙方营业网点办理补卡或换卡手续，或通过乙方指定的其他方式办理。甲方委托他人代办需同时提供甲方及受托人真实有效身份证件及委托书，乙方有权要求同时辅以服务密码、通话记录、缴费记录、入网时间、已开通业务等相关信息验证后进行办理。乙方可以就前述事项收取相应费用。',
    '7.根据公安部和工业和信息化部要求，甲方如需使用乙方来电提醒、呼叫转移等基于呼叫转移实现的相关业务，应向乙方申请开通呼叫转移功能。',
    '三、服务资费及费用交纳',
    '1.甲方使用乙方提供的电信服务应支付相关费用，乙方收取的费用应符合法律法规的规定和双方的约定。',
    '2.乙方每月可为甲方免费提供139邮箱等电子账单自动推送服务，甲方如需取消账单推送服务的可点击账单邮件底部“退订邮件”按钮退订。乙方应为甲方提供免费的话费查询服务，并有义务对账单、清单进行解释。乙方提供的话费查询清单仅显示要向甲方收费的业务使用记录。',
    '3.甲方应按照约定的资费标准、时间和方式向乙方及时、足额交纳费用账单中记载的全部费用。甲方超过约定交费日未交足费用（以下称“欠费”），乙方有权暂停提供电信服务(以下称“欠费停机”)，并可以自约定交费日次日起按照所欠费用每日加收3‰（千分之三）的违约金。自欠费停机之日起90日内，如甲方交清欠费和违约金的，乙方应在甲方交清欠费和违约金后的24小时内恢复暂停的服务；欠费停机满90日，如甲方仍未交清欠费和违约金的，乙方有权解除协议和终止提供电信服务（以下简称“销号”），并可以通过信函、公告、委托第三方等方式追缴全部欠费和违约金。在甲方缴清全部历史欠费和违约金前，乙方有权拒绝与甲方订立其他电信服务协议。',
    '4.如甲方承诺消费或承诺在一定期限使用约定套餐，可享受乙方提供的相关优惠。在套餐有效期内因甲方原因停机的，乙方仍有权按照约定向甲方收取甲方所承诺消费金额和所承诺使用套餐所必须缴纳的费用。乙方采用市场化方式合理设定吉祥号码分类等级和合约周期，为甲方提供有合同期限的、非终身保底消费方案。',
    '5.乙方计费原始数据保留期限为5个月(系统产生用户话单当月起后5个月，不含当月)，甲方已明确知悉如需查询相关信息的，应在且只能在产生原始数据后5个月内进行查询。若甲方对乙方收取的费用存有异议，应在上述计费原始数据保留期限内及时提出，如逾期提出的，乙方将无法对甲方异议进行原始数据复核，将以乙方现存信息为准。甲方对乙方收取的费用提出异议的，乙方有责任进行调查、解释和处理。经核实确属乙方责任多收费用的，乙方应将已多收的部分按对外承诺的方式返还甲方。',
    '6.甲方正常缴清话费后，如需开具发票的，应在话费缴清后12个月内（不含缴费月当月）向乙方要求开具；超过12个月需补开的，则按发票管理办法等规定执行。。',
    '7.如甲方欠费停机的，在欠费停机期间，停机当月和复机当月的月使用费（套餐费）照常计收（另有协议约定的除外），欠费全停跨整月不收取月使用费（套餐费）。',
    '8.甲方向乙方申请办理、取消停机保号业务的，办理及取消当月的号码月使用费（套餐费）照常计收，停机保号期间跨整月计收停机保号费，另有约定除外。如甲方办理了主副卡相关业务的，主卡在申请停机保号同时，副卡亦将进入停机保号状态，主卡和副卡均需分别支付各自的停机保号费。',
    '9.如甲方向第三方服务商订购代收费业务的，则视同甲方同意乙方代第三方服务商向甲方收取服务费。该服务费的资费标准由第三方服务商制订并告知甲方。若甲方对代收的费用有异议的，应自行与第三方服务商协商解决，乙方提供必要的协助。',
    '10.甲方应妥善保管自己的号码，如发现其号码的通信费用异常增长，应及时到乙方营业网点或乙方指定的其他渠道办理停机手续，并向公安机关报案。乙方应积极配合甲方和公安部门调查相关情况。',
    '四、协议的变更、终止和转让',
    '1.除双方另有约定，甲方可在乙方现行有效执行的资费方案范围内变更选择适用的资费方案。',
    '2.如乙方需在资费方案有效期（一年）届满后，修改资费方案或相关约定内容，应当在有效期届满前30天通过短信、APP、微信、邮件、门户网站（WAP/WEB）等至少一种方式通知甲方，甲方可以选择修改后的资费方案或乙方提供的其他资费方案，若甲方不接受修改后的资费方案或乙方提供的其他资费方案，应在与乙方结清全部费用后，办理协议终止手续。',
    '3.甲方在结清所有费用后并经乙方同意的，可以将本协议项下的号码过户给第三人。甲方和第三人应持双方有效身份证件到乙方营业网点办理过户手续，手续办理完成后本协议自动终止。号码过户后，甲方不能查询或打印过户前的账单、清单、发票。过户前发生但在过户日还未结算的该号码产生的所有费用由新机主支付。',
    '4.若甲方过户给第三人的号码属于吉祥号码，需在甲方结清所有费用后，甲方和第三人按照过户规则到乙方营业网点办理过户手续。由第三人与乙方按照过户时乙方吉祥号码新入网政策（最新吉祥号码等级对应的首次预存话费标准、每月最低消费标准、承诺最低消费期限）签订吉祥号码客户协议，本协议自动终止。在办理过户手续之前，因使用吉祥号码导致的交费义务及一切后果仍由甲方承担。号码过户时，首次预存话费已返还且未使用完的，由过户后的新机主享用。',
    '5.甲方在结清所有费用后可以持登记的有效身份证件到乙方营业网点办理协议解除手续（以下称“销户”）。除双方另有约定外，乙方不应拒绝甲方销户。销户后甲方应主动解除该号码捆绑的银行、理财、个人社交账号等第三方应用，如甲方未及时解除号码捆绑造成信息泄露或经济损失，乙方不承担责任。',
    '6.甲方在销户后可以申请退还通过银行划扣、现金方式缴纳的费用余额。甲方参与乙方营销活动获得的优惠或赠送的费用余额按活动协议约定进行处理，未作约定的，则该余额随销户失效。',
    '7.除参与营销活动获得的优惠或赠送的费用余额外，甲方销户或携号转网携出的，可以预约将余额一次性转入在乙方开立的号码（如该号码账户不属于甲方，相关后果由甲方自行承担）。',
    '8.在甲方办理号码销户之日起90天内，如甲方申请号码恢复使用，可凭有效身份证件在乙方指定的营业厅办理销户重开；甲方办理号码销户之日起满90天，乙方有权将该号码回收进行二次放号。',
    '9.甲方存在以下情形之一的，乙方有权解除本协议，终止提供服务或限制甲方新入网申请：',
    '（1）甲方（包括受托人）提供的包括身份证件在内的证件、资料虚假不实或冒用他人身份证件的；',
    '（2）利用乙方提供的服务从事违法犯罪活动，或出现未经接收方同意发送商业广告信息、发送短信或拨打电话给他人造成骚扰、违规外呼、呼叫频次异常、超约定用途使用、转借转租或转售电话卡等违反国家监管部门相关规定的行为；',
    '（3）利用乙方提供的服务从事损害社会公共利益、他人合法权益或其他违背公序良俗的活动；',
    '（4）甲方欠费满90日仍未缴清费用；',
    '（5）乙方收到国家有关部门通知要求停止向甲方提供通信服务的。',
    '10.如果甲方丧失民事主体资格，本协议自动终止，乙方有权作销户处理，收回号码，终止提供电信服务。',
    '五、携号转网',
    '11.甲方在SIM卡丢失后应当及时向乙方申请停机，因甲方未及时申请停机而造成的后果，由甲方自行承担。',
    '1.携号转网服务是指在同一本地网范围内，蜂窝移动电话用户(不含物联网用户)变更签约的基础电信业务经营者而用户号码保持不变的一项服务。甲方申请携号转网服务的相关权利义务约定如下：',
    '（1）甲方有权依据国家携号转网相关的有效规定，向乙方申请携号转网；',
    '（2）乙方根据甲方申请，为甲方办理携号转网过程中，将必需的甲方资料提供给携号转网集中业务管理系统，以用于比对携入方和携出方登记的号码使用人身份信息是否一致；',
    '（3）甲方办理携号转网（包括转入、转出）应符合相关条件并提供所需要的材料。甲方携号转网的条件及所需要的材料，乙方通过营业网点、门户网站、10086热线等渠道进行公示；',
    '（4）甲方申请号码携入乙方网络时，因不同网络技术与设备等差异，甲方可能需要更换终端。甲方启用携入新卡的时间点及网络切换期间可能会短时间影响正常通信；',
    '（5）甲方携入乙方网络前，应当与携出方结清申请携号转网时已出账的费用，同时，甲方确认在携出方享有的积分、VIP级别、赠送话费及相关优惠权益都将无法继续使用，携出方及其合作伙伴提供的部分业务或服务将无法继续使用；',
    '（6）甲方应及时将携号转网事宜通知号码绑定的第三方业务经营者，避免号码在携号转网后无法正常接收第三方业务经营者的电话或短信等情形。',
    '2.甲方向乙方申请将号码携出至其他运营商网络的，甲方在其他运营商申请办理号码携入后，乙方终止向甲方提供电信服务。甲方无权就该号码向乙方或乙方关联公司主张任何权益或优惠。',
    '3.甲方向乙方申请将号码携出至其他运营商网络的，应在完成网络切换后30日内或在收到账单后10日内向乙方结清携出时未出账的费用。甲方超过约定交费日未交足费用的，乙方有权自约定交费日次日起按照所欠费用每日加收3‰（千分之三）的违约金，且有权将甲方欠费信息共享给甲方的携入方运营商，并委托携入方运营商向甲方代为追缴甲方所欠费用及违约金。',
    '4.甲方携入其他运营商号码与乙方签订本协议的，乙方有权根据携出方运营商的委托，代其追缴甲方在号码携出时未结清的携出方运营商的电信费用及违约金，追缴方式包括但不限于从甲方话费账户中扣减。',
    '5.如甲方与乙方之间存在在网期限约定或承诺的，甲方在在网期限内办理携号转网构成违约，需按相关约定向乙方支付违约金（包括但不限于营销活动违约金、家庭宽带优惠补收费）后方可申请携号转网。',
    '6.甲方办理乙方业务时如有约定优惠期或有效期的，均属于承诺在网期限，甲方在承诺在网期限内不申请携号转网或在承担违约责任后方可申请携号转网。',
    '六、隐私、个人信息和通信权益保护',
    '1.甲方的通信自由和通信秘密不受侵犯。甲方在申请办理乙方相关业务时，甲方同意乙方为服务目的收集甲方相关个人信息资料，乙方对甲方的个人信息（包括但不限于客户资料和通信信息）负有保密义务。但根据法律、行政法规规定，司法、行政机关依法要求乙方提供协助和配合，乙方应给予协助和配合的，不构成违反保密义务。同时，甲方应理解并知悉，甲方有义务保护自身个人隐私和个人信息安全。甲方在乙方营业网点或其他渠道办理业务时，应在了解业务规则、资费等详细信息后，自行获取并输入密码或随机验证码（如需要）。甲方充分知悉，如果他人（含乙方工作人员）代替甲方操作，则此类行为会带来各类严重风险，包括但不限于信息泄露、信息被滥用以及隐私被侵犯等。',
    '2.个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。乙方收集、使用甲方个人信息，应当遵循合法、正当、必要的原则。针对甲方敏感个人信息，乙方将采取更加严格的保护措施保障个人信息处理安全，包括但不限于开展个人信息保护影响评估、在实现目的所需的最小必要范围内处理敏感个人信息、采取加密等安全技术措施、在处理敏感个人信息前获得甲方单独同意并通知甲方等（本协议以下将对涉及到的敏感个人信息以粗体进行显著标识）。',
    '3.甲方理解并同意，乙方可以通过业务受理系统登记、纸质返档，通过网络接收、读取并记录等方式，以提供电信服务所必需为目的，在业务活动中限于实现处理目的的最小范围内收集、使用甲方提供的和甲方使用服务过程中形成的个人信息。乙方基于提供电信服务所收集和使用甲方个人信息的具体内容如下：',
    '3.1办理入网手续',
    '基于为甲方办理入网手续的目的，乙方会收集甲方的【姓名、证件类型、证件号码、证件有效期、个人面部信息、住址信息】；',
    '3.2业务受理、提供与计费',
    '基于受理甲方电信业务订购需求，保障甲方正常使用电信服务及实现业务计费收费准确的目的，乙方会收集甲方的【业务订购信息、消费信息、业务使用信息、个人通信设备信息、位置信息】；',
    '3.3 优化网络服务质量',
    '基于优化提升为甲方所提供的电信服务质量的目的，乙方会收集使用甲方的【住址信息、位置信息、通信记录信息】；',
    '3.4 提供客户服务',
    '为帮助甲方解决问题或记录相关问题的处理方案及结果，乙方会收集甲方的【沟通记录、订单信息、甲方提供的咨询所需的必要信息】。',
    '为了解甲方使用乙方电信服务的感知体验，优化乙方服务内容和流程的目的，乙方可能会使用甲方【手机号码】，向甲方发送服务调研问卷，并收集甲方的【反馈信息】。甲方如不希望收到此类调研问卷，可以通过调研短信中提供的退订途径申请退订。',
    '3.5 提供网络安全保障',
    '为履行保障电信网络安全的法定义务，乙方可能收集和使用依法收集甲方的【个人身份信息、通信记录信息】',
    '3.6 个性化推荐商品和服务信息',
    '甲方知悉并同意：为向甲方展示商品或服务信息，满足甲方的多元化需求，乙方会结合依法收集的甲方的个人信息，通过算法模型预测甲方的偏好特征，并基于甲方的偏好特征，向甲方推荐甲方可能感兴趣的产品和服务（同时也将会为甲方提供不针对其个人特征的产品或服务），或者向甲方发送商业性短信息。',
    '如甲方希望取消或退订上述个性化推荐功能，可通过以下方式取消：【10086热线、短信退订、门户网站、营业网点】。',
    '4.乙方将会依据法律法规的要求对甲方的个人信息进行匿名化处理，并对无法识别到甲方特定个人且不能复原的整体用户数据进行统计、分析并加以利用。',
    '5.乙方在中华人民共和国境内收集的甲方个人信息，将存储在中华人民共和国境内，并且仅会在实现收集甲方个人信息的目的所必需的最短时间内保留甲方的个人信息。超出个人信息保存期限后，乙方会对个人信息进行删除或匿名化处理，但法律、行政法规另有规定的情形除外。',
    '6.基于为甲方提供电信服务所必需，乙方可能委托授权合作伙伴处理甲方的个人信息。乙方仅会出于本协议约定的合法、正当、必要、特定、明确的目的委托授权合作伙伴处理甲方的信息，授权合作伙伴只能接触到其履行职责所需信息。乙方会与受委托处理个人信息的授权合作伙伴约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及各方权利和义务等，要求授权合作伙伴按照国家规定的保密和安全措施、双方约定以及乙方的要求处理甲方的个人信息。乙方与授权合作伙伴的委托关系不生效、无效、被撤销或者终止的，授权合作伙伴会将个人信息返还给乙方或者予以删除。乙方会对授权合作伙伴的上述个人信息处理活动进行监督。',
    '7.乙方不会与任何第三方公司、组织和个人共享甲方的个人信息，但以下情况除外：',
    '（1）事先获得甲方单独同意或授权；',
    '（2）为履行乙方法定义务所必需的共享；',
    '（3）基于履行与甲方订立的电信服务合同、为甲方提供电信服务所必需，将甲方个人信息与乙方关联公司或合作伙伴共享。乙方只会共享必要的个人信息，且受本协议中声明的目的的约束，乙方关联公司或合作伙伴如要改变个人信息的处理目的，需再次征求甲方的授权同意；',
    '（4）基于符合法律法规的社会公共利益、应对突发公共卫生事件或者紧急情况下为保护自然人的生命健康和财产安全而共享。',
    '8.甲方可以通过乙方营业厅或指定的其他渠道（包括10086电话、营业网点、门户网站等），在符合法律规定的情形及条件下，对其个人信息进行查询、复制、请求转移、更正、补充、删除、获取个人信息副本、限制和拒绝乙方处理。',
    '9.为了让甲方及时了解和享受乙方推出的最新业务或优惠，甲方同意乙方可以通过电话、短信、邮件、媒体广告等方式向甲方告知或推荐使用。',
    '10.甲方通过乙方提供的服务发送信息、拨打电话，必须遵守国家的有关规定和行政规章制度。如甲方利用乙方提供的服务发送垃圾信息、违法信息或拨打骚扰电话，包括但不限于反动类、涉黑类、涉黄类、诈骗类、谣言类、推销类，乙方有权采取停止短信功能、停机、解除服务协议等措施，并配合公安部门追查信息来源，依法追究甲方的相关法律责任。甲方的上述行为如违反国家法律法规，乙方有权视具体情况拒绝甲方恢复通信功能的申请。',
    '11.甲方在接受和使用乙方的服务和产品前，应了解乙方的《隐私和信息保护政策》（该政策已在乙方官方网站发布并可在服务厅索取），本协议签署即视为甲方已了解并同意乙方的隐私和信息保护政策。',
    '七、合同解除',
    '1.甲方订购乙方业务，且乙方业务无任何优惠或补贴，甲方未从乙方获得实物或权益，甲方可向乙方要求解除协议。',
    '2.甲方办理了乙方资金优惠类业务（包括但不限于话费存送、套餐折扣），如甲方要求提前终止协议，则需按照已履行月份获赠金额或优惠减免金额总额、以及未履行月份所有低消或套餐金额总额的一定比例（法律规定范围内）向乙方支付违约金。',
    '3.甲方办理了乙方业务赠送类业务（包括但不限于承诺消费送流量包、语音包），如甲方要求提前终止协议，则甲方需按照已获赠业务进行价值折算向乙方支付违约金。非流量类（语音、短信等）按协议终止时市场单价资费折算，流量类按协议终止时市场单价优惠资费折算。',
    '4.甲方办理了乙方宽带类业务（包括但不限于网龄送宽带，承诺低消送宽带），如甲方要求提前终止协议，则需按照约定的初装费用或拆机费用向乙方支付违约金。',
    '5.甲方办理了乙方实物赠送类业务（包括但不限于承诺消费送终端、充值卡），如甲方要求提前终止协议，则应当根据法律规定在不影响二次销售的前提下，将赠送实物返还乙方后方可终止；如赠送实物已不满足退货条件，则甲方应当根据实物赠送价值及未履行承诺期限予以乙方赔偿。',
    '6.甲方办理了乙方权益赠送类业务（包括但不限于咪咕视频、爱奇艺、优酷会员权益），要求提前终止协议的，如甲方未使用所赠送权益，可在完成赠送权益回退后终止；如甲方已领取或使用权益，则应当按照已使用权益折算后的价值向乙方支付违约金后方可终止。',
    '7.甲方办理了乙方实物租赁类业务（包括但不限于家庭宽带机顶盒、光猫、路由器），如甲方要求提前终止协议，应当按照约定，在不影响二次销售或租赁的前提下将租赁实物返还乙方后方可终止；如租赁实物已遗失或损坏，则甲方应当按照租赁设备价值进行等价赔偿。',
    '8.甲方办理了乙方融合类业务（包括但不限于融合套餐、主副卡、统付、共享、家庭网、集团网、惠农网、双向亲情互打），如甲方要求提前终止协议，则应当在不影响其他甲方业务使用的情况下，根据具体情况进行退订或解除后方可终止。甲方解除主卡、共享者之前，应当及时通知其他成员，因甲方通知不及时导致其他成员业务无法使用等后果由甲方承担。甲方解除副卡、被共享者，不应对其他成员权益造成损失。',
    '9.甲方办理乙方吉祥号码业务，如甲方要求提前终止协议，则甲方应当按照未履行月份所有低消或套餐金额总额向乙方支付违约金，如甲方有签约减免或降档低消金额特殊情况的，采用前述同样标准执行（按照乙方最新吉祥号码等级对应的低消或套餐标准）。',
    '10.甲方办理了乙方集团信息化业务，如甲方要求提前终止协议，则应当按照具体业务协议条款约定向乙方支付违约金后方可终止。',
    '11.上述协议终止事项所有涉及违约金支付的需到乙方指定的线下营业厅办理。',
    '八、其他约定事项',
    '1.甲方办理各类业务所签署的业务受理单、表单、协议等为本协议的补充协议，与本协议约定不一致部分以补充协议为准，补充协议中未约定的适用本协议。',
    '2.乙方有权在必要时变更本协议条款。乙方变更本协议条款的，将以包括但不限于在营业网点告示、门户网站公告、电子邮箱、手机短信通知等方式中的一种或多种，向甲方发送本协议的变更情况，甲方可以根据上述方式查阅相关的变更协议条款。本协议条款变更且乙方及时通过上述方式通知甲方后，如果甲方继续使用乙方提供的电信服务，即视为甲方已接受变更后的协议。如果甲方不接受变更后的协议，可向乙方申请销户或携号转网。',
    '3.本协议经甲方盖章或签字后以及加盖乙方业务专用章后生效。但在甲方携带其他运营商号码使用乙方电信服务的情况下，本协议经双方盖章或签字后并自乙方提供的移动电话卡（电话号码）激活启用之日（即完成网络切换之日）起生效。本协议项下发生的争议，双方应协商解决。协商不成的，任何一方可向乙方住所地人民法院提起诉讼。',
    '4.中国移动权益超市新星会员是面向全网用户开放的会员产品，资费为0元每月，长期有效。您可通过中国移动APP等入口登录权益超市，享受权益超市新星会员权益优惠购、福利社等权益。其中：会员优惠购指权益超市商品定价设置有会员价，会员价低于标准售价，新星会员享受以优惠的会员价购买货架商品；会员福利社是权益超市用户福利模块，新星会员用户每月可在会员福利社领取各类免费的优惠券；智能客服权益指用户可直接咨询在线客服解决问题。具体会员权益内容以中国移动权益超市会员页面展示为准。基于市场与业务的发展，中国移动有权对新星会员全部或部分权益进行更新、修改和调整。中国移动将就前述权益调整在相应的服务页面进行通知或公告，客户可以通过中国移动权益超市页面查询最新会员权益内容，并按照变更后的实际情况决定是否继续使用。如客户不再需要该服务，可通过中国移动APP等入口登录权益超市页面-我的订单处进行退订。',
    '特别提示：',
    '1.甲方同意乙方通过业务端口（不限于10086端口）、微博、微信和邮箱等渠道不定期向甲方发送服务提醒、关怀、升级、推荐和优惠等信息。',
    '2.甲方通过乙方APP、微信、门户网站、充值卡等渠道充值的话费具有使用期限限制，甲方请留意充值提示界面，并及时在有效期限内使用。',
    '3.乙方提供的来电显示、呼叫转移服务将遵照国家规范及行业标准进行，对于其他运营商（含国外运营商）传送的号码将如实显示，但乙方不保证该号码在发送端的真实性，甲方应谨防可能存在的网络违法侵权风险。甲方应采取谨慎的方式仔细甄别号码的真实性（如通过回拨等方式予以确认），谨防可能存在的网络信息诈骗及其他第三方违法侵权风险。',
    '4.乙方根据现行的电信行业规范保障甲方的通信安全，并对甲方在乙方账户内的话费余额负有安全保障义务，对超出乙方服务范围的业务及资金安全，乙方声明不承担任何安全保障义务。甲方需对其将手机号码与金融机构、第三方支付机构及其他服务提供商进行绑定，将本号码作为甲方与第三方的鉴权方式、账号密码找回方式等可能造成甲方信息泄露、资金损失的途径的行为自行承担可能产生风险。乙方对上述将号码关联其他服务商而造成的损失不承担任何责任，因此造成的不利后果需由甲方自行承担。',
    '5.乙方关联公司是指乙方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
    '6.甲方或其受托人已详细阅读本协议的全部条款，特别是加粗条款，甲方或其受托人在签署本协议后即视为完全理解并同意接受本协议的全部条款。',
    '7.甲方理解，在现有技术条件下乙方网络及特定网络技术（如第五代移动通信技术）尚无法达到无缝隙覆盖。即使在乙方网络覆盖范围内，甲方使用的终端、SIM卡类型、所订购的服务均可能影响甲方对乙方特定网络的接入和使用。甲方如需使用特定网络，可能需要更换适配终端、SIM卡并订购相应的服务，且上述网络的质量和速率也受到多种因素的影响，包括但不限于网络技术、网络容量、甲方终端设备、甲方所订购服务情况、周边环境与天气等。',
    '8.因不可抗力而使本协议部分或全部不能履行，双方互不承担违约责任。',
    '9.因技术进步、国家政策变动等原因，导致本协议部分或全部无法继续履行时，乙方有权变更或终止本协议部分或全部，但应当提前发布公告，提供合理解决方案。',
    '10.本协议有效期为1年。如在有效期届满前30天，任何一方未向对方提出异议，有效期届满后本协议自动展期1年，展期次数不限。',

    '甲方（签名或单位盖章）：',
    '监护人/受托人（签名或盖章）：',
    '甲方号码：                         ',
    '签署日期：   年     月     日',
    '乙方（签名或单位盖章）：',
    '经办人（签名）：',
    '签署日期：    年   月  日',
  ],
};

let liabilityProtocol6 = {
  title: '解约条款',
  content: [
    '甲方：中国移动通信客户',
    '乙方：中国移动通信集团广东有限公司',
    '      甲方业务协议解约按类型分为“直接解约”和“有条件解约”两大场景。',
    '一、直接解约',
    '主要是涉及甲方直接订购的业务，乙方未进行任何优惠或补贴，甲方也未得从乙方得到实物或权益等相关的业务，采用无条件直接解约。',
    '二、有条件解约',
    '1.合约业务',
    '如涉及赠送终端、话费存送、设备租赁或承诺在网或承诺消费获得相应优惠等业务，一般不能直接解约，甲方在“完成优惠赔付、不影响二次销售的退货”等情况下，有条件解约，按解约标准和流程操作（如有事先签订赔付标准，按约定的赔付标准执行）。具体如下：',
    '（1）资金优惠：如话费存送、套餐折扣等。解约条件：根据优惠、减免情况支付违约金。赔付标准：甲方如提前终止协议，则甲方需将已履行月份获赠金额或优惠减免金额总额、以及未履行月份所有低消或套餐金额总额的一定比例（法律规定范围内）进行赔付。',
    '（2）业务赠送：如承诺消费送流量包、语音包、短信包等。解约条件：根据已赠送业务价值，支付合理的违约金。赔付标准：甲方如要求提前终止协议，则甲方需将已获赠业务进行价值折算进行赔付。非流量类（语音、短信等）按单价标准资费折算，流量类按实际单价优惠资费折算。',
    '（3）宽带业务：如网龄送宽带，承诺低消送宽带。解约条件：提前终止宽带业务需对初装费或拆机费进行赔付。赔付标准：甲方如要求提前终止协议，需根据前期活动约定的初装费用或拆机费用进行赔付。',
    '（4）实物赠送：如承诺消费送终端、充值卡等。解约条件：在不影响二次销售的前提下，退还赠送的实物；在无法退还实物的情况下，通过对实物进行折现，支付违约金。赔付标准：根据退货要求且不影响二次销售的前提下，可在完成赠送实物退货后解约；如赠送实物已不满足退货条件，则甲方需根据实物赠送价值及未履行承诺期限进行补偿。',
    '（5）权益赠送：如咪咕视频、爱奇艺、优酷会员权益等。解约条件：需根据活动约定退回方可解约，不能退回需按价值折算进行补偿（权益赠送协议签订中应明确约定赠送权益的价值或折算方法）。赔付标准：权益未使用可在完成赠送权益回退后解约；权益已领取、使用，则甲方需将已使用权益进行价值折算，赔偿后解约。',
    '（6）实物租赁：如家庭宽带机顶盒、光猫等。解约条件：确保设备完好的情况下，返还租赁物；如无法归还或涉及其他利益损失的，可通过折现，支付合理违约金。赔付标准：甲方如要求提前终止协议，需根据活动约定条件退回或赔偿租赁物，根据退货约定且不影响二次销售的前提下，可在完成租赁实物退回后解约（注，如租赁物非新：在确保设备完好的情况下，可在完成租赁物退货后解约）；如租赁物已不满足再次租赁条件（设备已无法正常使用），则甲方需根据租赁设备价值进行等价偿还。',
    '2.融合业务',
    '融合套餐、主副卡、统付、共享、家庭网、集团网、惠农网、双向亲情互打等业务，需在不影响其他甲方业务使用的情况下，根据具体情况进行退订或解除。',
    '类别	场景	解约条件	举例',
    '融合业务	统付业务、主副卡等	取消统付关系、解除相关成员	家庭统付、家庭网、集团网等',
    '  共享业务	解除共享关系	流量、语音共享等',
    '  V网	解除V网成员关联关系	家庭网、集团网、惠农网、双向亲情互打等',
    '注：①主卡、共享者解除，应对其他成员尽到通知义务。',
    '②副卡、被共享者解除，不应对其他成员权益造成损失。',
    '3.吉祥号码业务',
    '如四连号、三连号等吉祥号码。解约条件：按吉祥号码低消和未履行协议期进行赔付。赔付标准：如甲方要求提前终止协议，则甲方应当按照未履行月份所有低消或套餐金额总额向乙方支付损失赔偿金，如甲方有签约减免或降档低消金额特殊情况的，采用前述同样标准执行。（参考最新吉祥号码等级对应的低消或套餐标准）。',
    '4.集团信息化业务',
    '解约条件：根据乙方投入成本补贴和未履行时间等进行价值折算赔付（协议签订中应明确约定相关业务价值或折算方法）。',
    '赔付标准：具体赔付标准按签订的协议条款折算。',
  ],
};

let liabilityProtocol7 = {
  title: '隐私政策',
  content: [
    '更新时间：2019年5月30日',
    '生效时间：2019年5月31日',
    '中国移动通信集团广东有限公司非常重视您的个人信息安全保护，我们将按照法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。',
    '请您在使用本客户端前，认真阅读并充分理解本《隐私政策》的全部内容，特别是以字体加粗方式标识的内容，在确认充分理解并同意后开始使用。在阅读过程中如您对本政策内容有任何疑问、意见或建议，请联系我们。',
    '需要特别说明的是，本政策不适用于第三方依托本客户端向您提供的产品或服务，也不适用于本客户端中已独立设置隐私政策的产品或服务。',
    '本隐私政策将帮助您了解以下内容：',
    '1.我们如何收集和使用您的个人信息。',
    '2.我们如何使用Cookie和同类技术。',
    '3.我们如何共享、转让、公开披露您的个人信息。',
    '4.我们如何保护和保存您的个人信息。',
    '5.您的权利',
    '6.我们如何处理未成年人的个人信息。',
    '7.您的个人信息如何在全球范围转移。',
    '8.本政策如何更新。',
    '9.如何联系我们。',
    '一、我们如何收集和使用您的个人信息',
    '个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。',
    '中国移动仅会出于本政策所述的以下目的，收集和使用您的个人信息：',
    '（一）为更好地为您提供服务，中国移动将遵循“合法、正当、必要”原则，按照法律法规的规定和您的同意收集您的个人信息，主要包括：',
    '1. 您提供的信息',
    '您注册成为我们的用户或使用我们的服务时，向我们提供的相关个人信息，包括您的姓名（名称）、性别、年龄、地址、电话号码、电子邮箱、身份证件号码、缴费相关的银行信息以及联系人信息。',
    '您提供的上述信息，将在您使用本客户端服务期间持续授权我们使用。在您注销账号时，我们将停止使用上述信息。',
    '2. 您在使用服务过程中产生的信息',
    '您使用我们提供的服务时，我们可能自动收集以下信息：',
    '（1）日志信息',
    '指您使用我们的服务时，系统可能通过 cookies、web beacon 或其他方式自动采集的技术信息，包括：',
    'A. 设备或软件信息，例如您的移动设备、网页浏览器或您用于接入我们的服务的其他程序所提供的配置信息、您的IP地址和您的移动设备所用的版本和设备识别码；',
    'B. 您在使用我们的服务时搜索和浏览的信息，例如您使用的搜索关键字、访问页面，以及您在使用我们的服务时浏览或要求提供的其他信息；',
    '（2）位置信息',
    '指您开启设备定位功能并使用我们基于位置提供的相关服务时，我们收集的有关您位置的信息，例如您通过具有定位功能的移动设备使用我们的服务时，我们通过 GPS 或 WiFi 等方式收集的您的地理位置信息。您可以通过关闭定位功能随时停止我们对您的地理位置信息的收集。',
    '（3）订购信息',
    '指您通过本客户端订购移动通信、宽带接入及其他增值服务或购买商品时产生的记录，包括订购关系记录、消费记录。',
    '（4）手机账户信息',
    '指您的手机登录账户的信息，例如在您使用手机账号登录本客户端服务时，我们会查询您的手机账号等相关信息。',
    '3. 第三方分享的有关您的信息',
    '为给您提供服务，我们可能会从关联公司、业务合作伙伴等第三方来源获得您的相关信息。例如当您通过我们关联公司、业务合作伙伴客户端、网站订购我们的产品，您向其提供的预订信息可能会转交给我们，以便我们处理您的订单。我们会依据与第三方的约定、对接收的个人信息来源的合法性进行确认后，依相关法律、法规及第三方约定使用您的信息。',
    '4. 我们收集的个人信息',
    '当在你使用本客户端服务的过程中，本客户端会按照如下方式收集你在使用服务时主动提供或因为使用服务而产生的信息，用以向你提供服务、优化我们的服务以及保障你的帐号安全：',
    '用户个人基本信息：个人姓名、住址、邮箱、电话号码；',
    '用户终端信息：设备MAC地址、唯一设备识别码、SIM卡IMSI信息、IMEI、终端版本型号、网络IP、本机号码、网络类型、手机品牌、WiFi状态；定位信息、通讯录权限、用户软件列表信息、摄像头权限、用户语音信息；',
    '电子商务信息：银行卡号、收货住址、身份证、办理套餐明细、时间、被充值人手机号码、订单编号、订单状态、订单金额、充值金额、支付账号信息；',
    '内容分享信息：分享至新浪微博的微博账号信息，分享至腾讯微信的微信账号信息，分享至支付宝的支付宝账号信息。',
    '用户行为信息：访问本客户端页面及点击相关控件的行为信息；',
    '（二）我们可能将收集到的您的个人信息用于以下目的：',
    '1. 为您提供服务',
    '（1）在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；',
    '（2）通过信息数据分析，帮助我们设计新服务，改善我们现有服务；',
    '（3）使我们更加了解您如何使用我们的服务，从而针对性地满足您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他使用我们服务的用户作出其他方面的回应；',
    '（4）提供您关注或者与您相关的广告以替代普遍投放的广告，您在使用我们的服务时，我们可能使用您的信息向您发送电子邮件、短信或推送通知，如您不希望收到这些信息，可以按照我们的相关提示取消订阅；',
    '（5）评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；',
    '（6）软件认证或管理软件升级；',
    '（7）让您参与有关我们产品和服务的调查；',
    '（8）其他您授权同意提供个人信息的情况。',
    '2. 我们可能将通过某些功能所收集的信息用于我们的其他服务。例如，我们可能将你在使用我们某一功能或服务时我们收集的信息，在另一功能或服务中用于向你提供特定内容，包括但不限于展示广告、对你阅读过的内容进行信息安全类提示、基于特征标签进行间接人群画像并提供更加精准和个性化的服务和内容。为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息，例如，你使用应用程序的频率、崩溃数据、总体使用情况、性能数据以及应用程序的来源。我们不会将我们存储在分析软件中的信息与你在应用程序中提供的任何个人身份信息相结合。如我们使用你的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用你的个人信息前，再次向你告知并征得你的明示同意。',
    '3. 在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在符合相关法律法规的前提下，我们有权对包括您的个人信息在内的用户数据库进行整体化分析和利用。',
    '4. 当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。',
    '5. 您充分知晓，以下情形中我们使用个人信息无需征得您的授权同意：',
    '（1）与国家安全、国防安全有关的；',
    '（2）与公共安全、公共卫生、重大公共利益有关的；',
    '（3）与犯罪侦查、起诉、审判和判决执行有关的；',
    '（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；',
    '（5）所收集的个人信息是个人信息主体自行向社会公众公开的；',
    '（6）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；',
    '（7）根据您的要求签订合同所必需的；',
    '（8）用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；',
    '（9）为合法的新闻报道所必需的；',
    '（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；',
    '（11）法律法规规定的其他情形。',
    '（三）业务功能需要获取的相关权限信息',
    '当在你使用本客户端服务的过程中，本客户端需要获取您的手机相关权限，用以向你提供服务、优化我们的服务以及保障你的帐号安全：',
    '（1）通讯录备份：需要通讯录权限；',
    '（2）电视助手：需要摄像头权限；',
    '（3）免密登录：需要设备信息权限；',
    '（4）家庭生活：需要定位权限；',
    '（5）优惠券：需要定位权限；',
    '（6）实名认证：需要摄像头权限；',
    '（7）短号查询：需要通讯录权限；',
    '（8）亲密圈：需要通讯录权限；',
    '（9）找营业厅：需要位置信息权限；',
    '（10）  语音搜索：需要麦克风权限；',
    '（11）  视频在线客服：需要麦克风权限、摄像头权限；',
    '（12）  充值缴费：需要通讯录权限；',
    '（13）  手机联系人：需要通讯录权限；',
    '（14）  首页选择地区：需要位置权限；',
    '（15）  首页扫一扫：需要相机权限；',
    '（16）  我的短号：需要通讯录权限；',
    '（17）  光猫扫一扫：需要摄像头权限、相册读写权限、sd卡存储权限；',
    '（18）  实名认证：需要相机权限，录音权限；',
    '（19）  万能副卡：需要定位权限；',
    '（20）  日行一善：需要写短信权限；',
    '（21）  流量提醒：需要读短信权限；',
    '（22）  优惠券：需要定位权限；',
    '（23）  我的移动页面：需要定位权限；',
    '（24）  通用权限及个人信息：需要定位信息权限、设备信息权限、读写存储设备权限；',
    '（26）  通讯录备份：需要通讯录权限；',
    '（四）个人信息安全保护措施和能力',
    '我们努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、未经授权访问或披露。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，报文AES加密传输、登录tokenDES加密传输、MD5数字签名校验、报文加解密验证、httpsCA证书方式）、匿名化处理手段来保护你的个人信息。我们通过不断提升的技术手段加强安装在你设备端的软件的安全能力，以防止你的个人信息泄露。例如，我们为了安全传输会在你设备本地完成部分信息加密的工作；为了预防病毒、木马程序或其他恶意程序、我们会了解你设备安装的应用信息或正在运行的进程信息。我们建立专门的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告形式告知你。',
    '本客户端获得工信部基础电信企业互联网信息服务系统备案，安全等级1级。',
    '（五）个人信息的使用规则',
    '客户端在用户点击轮播图、广告位、办理业务、查询业务，客户端会匿名化记录移动用户的喜好及业务点击量，据此给用户推荐热门活动及业务；',
    '（六）在以下情形中我们使用您的信息时无需征得您的授权同意：',
    '1. 关系国家安全、国防安全的情况时。',
    '2. 关系公共安全、公共卫生、重大公共利益的情况时。',
    '3. 关系犯罪侦查、起诉、审判和判决执行的情况时。',
    '4. 出于维护个人信息主体或其他个人的生命、财产安全，但又很难得到本人同意的情况下。',
    '5. 所收集的个人信息是个人信息主体自行向社会公众公开的信息。',
    '6. 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道。',
    '7. 根据您的要求签订合同所必需的。',
    '8. 用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障。',
    '9. 为合法的新闻报道所必需的。',
    '10. 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。',
    '11. 法律法规规定的其他情形。',
    '二、我们如何使用 Cookie 和同类技术',
    '（一）Cookie',
    '为确保客户端正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，客户端能够存储您的选项或购物篮数据，便于您在不同页面间跳转时仍能记住您的选项或购物篮数据，避免重复输入。',
    '我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie。',
    '（二）客户端信标和像素标签',
    '除 Cookie 外，我们还可能会在客户端上使用网站信标和像素标签其他同类技术。例如,我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。',
    '（三）Do Not Track（请勿追踪）',
    '很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。',
    '三、我们如何共享、转让、公开披露您的个人信息',
    '（一）共享',
    '我们不会与中国移动以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：',
    '1. 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。',
    '2. 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。',
    '3. 与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。',
    '我们的关联公司包括中国移动现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
    '4. 与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您通过我们的客户端购买商品或订购业务时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。',
    '目前，我们的授权合作伙伴包括以下三大类型：',
    '（1）供应商、服务提供商和其他合作伙伴。我们将信息发送给在全球范围内支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。',
    '（2）第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。',
    '（3）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，包括姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。但我们可能会将使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助在不识别您个人的前提下提升广告及服务信息的有效触达率。',
    '对我们与之共享个人信息的公司、组织和个人，我们会与其签署相应的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。',
    '（二）转让',
    '除以下情况外，我们不会将您的个人信息转让给任何公司、组织和个人：',
    '1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；',
    '2. 在本客户端合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的公司、组织继续按本隐私政策接收、管理、使用和转让您的信息。若无法达成共识，我们会要求新的公司重新向您征求授权。',
    '（三）公开披露',
    '我们仅会在以下情况下，公开披露您的个人信息：',
    '1. 获得您明确同意后，按您授权情况披露您的个人信息；',
    '2. 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会按要求披露您的个人信息。',
    '四、我们如何保护您的个人信息',
    '（一）我们会严格按照《网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）、《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等法律法规的相关要求，建立信息安全保障制度，在合理的安全范围内采取各种安全保护技术措施（包括身份鉴别、数据加密、访问控制等）和其他必要措施保护您的个人信息安全。我们努力保障用户信息安全，以防止出现信息丢失、不当使用、未经授权访问或披露的情况。',
    '（二）我们只会在本政策所述目的所需的期限内保留您的个人信息，按国家政策法律法规要求或允许延长保留期限的除外。',
    '（三）请您理解：互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性，但由于技术的限制及可能存在的各种恶意手段，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百安全。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。',
    '（四）我们如何保存您的个人信息',
    '1. 信息存储的地点',
    '我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内，不存在境外收集用户信息情况。',
    '2. 信息存储的期限',
    '我们承诺您的个人信息的存储时间始终处于合理必要期限内，我们根据业务需要时长去存储用户信息，超出保存期限的用户信息，中国移动会做匿名化处理。当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。',
    '注：法律法规有规定不能删除时或不能匿名化处理的情况除外。',
    '五、您的权利',
    '1. 查询和更正您的个人信息',
    '除法律法规规定外，您有权在登录本客户端后查询您的个人信息。如需更正个人信息，请本人持有效证件到中国移动营业厅办理。',
    '2. 删除您的个人信息',
    '在以下情形中，您可以向我们提出删除个人信息的请求：',
    '（1）如果我们处理个人信息的行为违反法律法规；',
    '（2）如果我们收集、使用您的个人信息，却未征得您的同意；',
    '（3）如果我们处理个人信息的行为违反了与您的约定；',
    '（4）如果您不再使用我们的产品或服务，或您注销了账号；',
    '（5）如果我们不再为您提供产品或服务。',
    '您可以通过拨打客服电话10086、通过客户端在线客服等各种方式与我们联系提出您想要删除个人信息的请求。',
    '当您从我们的客户端中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时及时删除这些信息。',
    '3. 撤回已同意的授权',
    '您有权撤回已经同意的授权，撤回同意后，我们将不再处理您相应的个人信息。客户端支持您撤销已有的的授权，您可在“手机设置—>权限”中关闭相关隐私授权，具体为：',
    'Android手机：设置—>应用—>权限。',
    'IOS手机：设置—>隐私—>权限—>应用。',
    '4. 注销账户您在本客户端登录时的用户名为您的中国移动手机号码，本客户端只通过关联方式展示该手机号码和您在手机号码入网登记时提供的个人信息，如您需要办理手机号码入网登记时提供的个人信息变更、删除及手机号码注销操作，需前往中国移动实体营业厅办理。',
    '5. 例外情况',
    '请您充分理解并同意，我们在以下情况下可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：',
    '（1）与国家安全、国防安全有关的情况。',
    '（2）与公共安全、公共卫生、重大公共利益有关的情况。',
    '（3）与犯罪侦查、起诉、审判和执行判决等有关的情况。',
    '（4）有充分证据表明个人信息主体存在主观恶意或滥用权利的情况。',
    '（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的情况。',
    '（6）涉及商业秘密的情况。',
    '7. 法律法规规定的其他情形。',
    '六、我们如何处理未成年人的个人信息',
    '1. 我们非常重视对未成年人个人信息的保护。若您是未成年人，在使用我们的服务前，应事先取得您的父母或法定监护人的书面同意。我们将根据国家相关法律法规的规定保护未成年人的个人信息。',
    '2. 对于已经过未成年人的父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。',
    '3. 如果我们发现在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，会尽快删除相关数据。',
    '七、您的个人信息如何在全球范围转移',
    '原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。如果需要将您的个人信息转移到境外，我们将另行获得您的授权同意，并依据本政策保护您的个人信息安全。',
    '八、本政策如何更新',
    '我们的隐私政策可能变更。',
    '未经您明确同意，我们不会削减您应享受的个人隐私权利。我们会以通告的形式发布对本政策所做的任何变更。在该种情况下，若您继续使用我们的服务，即表示您同意接受修订后的隐私政策约束。',
    '九、如何联系我们',
    '如果您对本隐私政策有任何疑问、意见、建议或需要投诉，可以随时通过拨打客服电话10086、登录客户端通过在线客服等各种方式与我们联系，我们会积极进行查证、处理并尽快答复。',
    '公司名称：中国移动通信集团广东有限公司',
    '公司地址：广东省广州市天河区珠江西路11号',
    '联系电话：10086',
  ],
};
let zhouShanDX = {
  title: '隐私政策',
  content: [
    '附件 2',
    '中国电信用户入网协议（2024 线上版）',
    '甲方：',
    '乙方：中国电信股份有限公司XX分公司',
    '重要提示：',
    '1.甲方应当为具有完全民事行为能力的自然人。如甲方为无民事行为能力人或限制民事行为能力人，甲方签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。',
    '2.在签署本协议前，请甲方或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。',
    '3.乙方尊重并保护用户的个人信息，制定了《中国电信个人信息保护政策》，已向甲方告知有关乙方提供本协议项下服务适用的个人信息处理规则。',
    '4.如甲方为不满十四周岁的未成年人的，甲方父母或其他监护人须同时签署《中国电信儿童个人信息处理知情同意书（适用于不满十四周岁的未成年用户）》（“知情同意书”）。乙方尊重并保护儿童个人信息，制定了《中国电信儿童个人信息处理规则》，作为知情同意书的附件，向甲方告知有关乙方提供本协议项下服务适用的儿童个人信息处理规则。',
    '5.甲方或其代理人/监护人签署本协议即视为完全理解并同意接受本协议的全部条款。',
    '6.甲方所需的业务内容、办理方式以及资费方案请详见乙方的相关业务说明。',
    '根据《中华人民共和国民法典》《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国网络安全法》《中华人民共和国反电信网络诈骗法》《中华人民共和国电信条例》及其他有关法律、法规的规定，甲乙双方在自愿、平等、公平、诚实信用的基础上，就电信服务的相关事宜，达成如下协议：',
    '一、 用户真实身份信息登记要求',
    '1.根据《中华人民共和国反电信网络诈骗法》《电话用户真实身份信息登记规定》（工业和信息化部令第 25 号）及有关登记实施规范要求，甲方办理入网手续时须向乙方出示二代居民身份证、外国人永久居留身份证（仅限外国公民使用）、提供真实身份信息，并通过乙方在线视频实人认证等认证方式完成身份信息核验（具体认证方式以乙方网络渠道页面提示为准）。如甲方申办乙方移动电话卡（含无线上网卡），但未完成在线实人认证程序的，乙方将委托第三方递送电话卡给甲方，并授权第三方现场查验甲方身份信息、收集相关资料，甲方须予以配合。',
    '2.甲方应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整，并有义务配合乙方或乙方授权的第三方进行查验。甲方可以通过乙方相应渠道查询已登记的身份信息。如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的，甲方应当及时到乙方营业网点更正或变更。因甲方登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由甲方自行承担。',
    '3.甲方申请入网时，在中国电信全国范围内登记在同一个个人用户名',
    '下的移动电话卡达到 5 张的，乙方根据国家相关规定不再为其开办新的移动电话卡。',
    '4.乙方有权按照《中华人民共和国反电信网络诈骗法》，对监测识别的涉诈异常电话卡用户重新进行真实身份信息核验。对未按规定核验或核验未通过的，乙方有权限制、暂停有关电话卡功能。',
    '二、 甲方的权利和义务',
    '1.甲方有权监督乙方的电信服务质量，及时提出改善意见和建议；对电信服务持有异议的，可以向乙方进行咨询或投诉。',
    '2.甲方有权自主选择乙方依法提供的各类电信业务，有权自主选择乙方公示的资费方案，有权自主选择乙方提供的各类业务办理、咨询、查询和投诉渠道。对于部分乙方业务，甲方有权自业务办理成功后7日内按照相应的业务规则申请办理退订且无需因退订支付违约金。具体适用业务范围及退订规则（包括但不限于甲方应当交纳已使用的电信费用）以甲方办理业务时签署的业务登记单或乙方公示信息为准。',
    '3.甲方有权自主选择取得入网许可的终端、设备，并负责安装、调测和维护（包括建筑内自建管线的维护）。甲方自主选择的终端、设备应当具备符合乙方网络的相应功能，否则可能无法支持所选择的电信服务，甲方将自行承担损失。',
    '4.甲方对乙方提供给甲方使用但所有权归乙方的线路、设备或终端有保管责任，不得转借、出租、出售或赠与他人，也不得用于非乙方提供的业务中。由',
    '',
    '于甲方原因造成该等线路、设备或终端损坏、丢失的，甲方需承担修复费用或按价赔偿。',
    '5.甲方应当按照约定的交费时间和方式及时、足额交纳电信费用。电信费',
    '用计费周期一般为自然月，即每月 1 日 0 时至当月最后一日 24 时，双方另有约定的除外。逾期交纳电信费用（欠费）的，甲方须每日按所欠费用 3‰的标准支付违约金，并承担相应违约责任。由于通信设备产生费用清单及相关处理会有时延',
    '（如国际漫游业务等），可能会发生上一计费周期的部分电信费用计入下一计费周期收取的情况。',
    '6.甲方可以自愿订购或退订第三方服务提供商通过乙方网络提供的增值电信服务，并同意乙方根据第三方服务提供商的委托代为收取相关费用。如甲方对代收费用有异议，可以向第三方服务提供商或乙方提出，由第三方服务提供商自行解决或由乙方协调第三方服务提供商解决。',
    '7.甲方对交纳的电信费用及代收费用有异议的，应当在乙方计费原始数据保存期限内向乙方提出。',
    '8.甲方有权自主选择或终止以代扣、银行托收等方式支付电信费用，并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。',
    '9.甲方通信自由和通信秘密受法律保护。公安机关、国家安全机关、人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，乙方将依法配合。',
    '10.甲方不得非法买卖、出租、出借名下的电话卡、物联网卡、电信线路、短信端口等。甲方应当确保电信服务的实际使用人信息与乙方登记信息一致，如需转让给他人使用或实际使用人信息发生变化，甲方须按本协议关于用户真实身份信息登记的约定，办理过户手续或变更实际使用人信息，否则乙方有权采取关停相关服务或功能、解除协议以及有权机构要求的其他措施。由此产生的后果和法律责任，由甲方全部承担。',
    '11.甲方不得利用乙方通信资源、电信服务等制作、复制、发布、传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：',
    '（1）反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建',
    '',
    '（2）迷信的；',
    '（3）危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
    '（4）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；',
    '（5）散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；',
    '（6）散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；',
    '（7）侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；',
    '（8）发送违法信息、拨打诈骗电话，未经接收方同意或请求、或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；',
    '（9）其他利用乙方通信资源、电信服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。',
    '12.甲方不得实施或协助他人实施下列危害乙方网络安全和信息安全的行为，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：',
    '（1）侵入或非法控制乙方网络或电信设备，盗接乙方电信线路、非法复制乙方电信码号；',
    '（2）非法获取、删除、修改乙方网络或电信设备中存储、处理、传输的信息和应用程序；',
    '（3）利用乙方网络从事窃取或破坏他人信息、损害他人合法权益的活动，制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；',
    '（4）提供从事入侵乙方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；',
    '（5）设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；',
    '',
    '（6）故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；',
    '（7）使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；',
    '（8）危害乙方网络安全和信息安全的其他行为。',
    '13.甲方同意乙方基于提供电信服务、保障通信服务安全、与甲方沟通联系目的处理并与乙方关联公司共享甲方信息，包括身份信息、服务数据及日志信息等。身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、出生日期、身份证件号码等身份证件登记信息、拍摄的影像、住址、电话号码、电子邮箱等；服务数据及日志信息包括但不限于甲方在使用乙方服务过程中上传和产生的位置信息、终端信息、通话记录、使用记录、订购信息、账单、网络信息等。乙方处理甲方个人信息的规则，见《中国电信个人信息保护政策》。如甲方不同意提供或不同意乙方处理如上的某一或某些信息，甲方将可能无法成为乙方的用户或无法享受乙方提供的某些服务，或无法达到相关服务拟达到的效果。本协议解除或终止后，乙方不再收集甲方新的信息，并将按照相关法律法规规定对本协议存续期间收集的甲方信息进行处理。其中乙方关联公司是指乙方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司或机构管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
    '14.未经甲方授权同意，乙方不向第三方提供甲方信息。如甲方授权与乙方有合作关系的银行等金融机构、依法设立的征信机构、数据服务机构、信息查询服务机构、信息验证服务机构、互联网企业等第三方向乙方收集、核验甲方信息，甲方同意乙方有权向该等第三方提供相关的甲方信息和核验信息。',
    '15.为保护公共利益、优化电信服务质量、保障甲方电信服务知情权、及时警示通讯信息诈骗、创新电信服务体验，甲方同意乙方使用甲方信息通过营业网点、网站、短信、互联网媒体、电子邮件、语音外呼等方式，向甲方告知社会公益、电信服务、业务使用、诈骗风险警示等信息。',
    '16.甲方有权自主选择携号转网服务，即在保持移动电话号码不变的情况下变更签约的电信运营企业。甲方办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。',
    '17.办理携号转网业务过程中，甲方同意乙方将必要的甲方身份信息提供给工业和信息化部集中业务管理系统（CSMS 系统），用于比对携入方和携出方登记的身份信息是否一致。',
    '三、 乙方的权利和义务',
    '1.乙方在其通信网络与设施覆盖范围内，向甲方提供电信服务；其中，乙方在签订有自动漫游协议的国家和地区的电信运营商的网络覆盖范围内为甲方提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。乙方提供的电信服务应符合国家规定的电信服务质量标准。',
    '2.乙方免费向甲方提供中华人民共和国境内（为本协议目的，仅限大陆地区，不含港澳台）火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。',
    '3.乙方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。',
    '4.乙方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、通知或告知业务种类和服务项目、范围、时限、资费方案以及其他电信服务内容。',
    '5.乙方可以通过电话热线、营业网点、网上营业厅、掌上营业厅或短信等多种渠道为甲方提供业务办理、咨询、查询、障碍申告、投诉受理等服务。因甲方原因造成的通知未送达或未及时送达，乙方免责。',
    '6.乙方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。',
    '7.甲方向乙方申告除网络覆盖和终端设备故障外的电信服务障碍，乙方应当自接到申告之日起，城镇 48 小时、农村 72 小时内修复或调通。',
    '8.乙方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常电信服务的，应当提前 72 小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。',
    '',
    '9.乙方向甲方提供免费的电子账单服务，并有义务对账单进行解释。乙方对甲方计费原始数据保留期限为 5 个月（系统产生用户话单当月起后 5个月，不含当月），法律另有规定除外。如数据保留期限内甲方对电信费用提出异议，乙方应当保存相应计费原始数据至异议解决。',
    '10.乙方可以根据对甲方实际情况的综合评估，与甲方约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。',
    '11.乙方有权根据具体业务类型按预付方式或后付方式向甲方收取电信费用：',
    '（1）预付方式下，甲方需预存费用。当账户余额加上信用额度（如有）不足以支付甲方拟使用的电信业务费用时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务（“欠费停机”）。',
    '（2）后付方式下，甲方累计未交的费用到达信用额度、或到达交费期时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务。其中，甲方当月累计出账费用的交费期为次月整月，另有约定的除外。',
    '12.甲方自欠费停机之日起 60 日内结清费用并申请复机的，乙方应当在收到甲方费用时起 24 小时内为甲方恢复暂停的电信服务（“复机”）。',
    '13.如甲方自欠费停机之日起满 60 日仍未结清欠费和违约金，乙方有权自欠费停机第 61 日起终止提供电信服务，收回相应号码（“销户”）、解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金，并可以依法向征信机构提供甲方失信信息。',
    '14.对经设区的市级以上公安机关认定的非法买卖、出租、出借电话卡、物联网卡、电信线路、短信端口以及假冒他人身份或者虚构代理关系开立上述卡或业务的单位、个人，以及因从事电信网络诈骗活动或者关联犯罪受过刑事处罚的人员，乙方有权按照国家有关规定采取限制其有关卡、账户、账号等功能和停止非柜面业务、暂停新业务、限制入网等措施。',
    '15.乙方严格按照《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国反电信网络诈骗法》《电信和互联网用户个人信息保护规定》（工业和信息化部令第 24 号）等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、纸质返档，通过网络接收、读取并记录、身份验证、客户服务、安全防范、诈骗监测、邀请甲方参与有关乙方产品和服务的调查）中处理甲方信息。为提供更为精准优质的服务，乙方可以依法对包含甲方在内的整体用户数据进行分析并加以利用。乙方承诺对甲方信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供，并采取符合业界标准、合理可行的安全防护措施保护甲方的个人信息，防止甲方的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。',
    '16.为遵守相关法律法规或者行政机关、监管机构等有权部门依法提出的要求以及基于诉讼/仲裁需要，乙方有权披露甲方的信息。',
    '四、 特殊情况的责任承担',
    '1.甲方入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由甲方自行承担责任。',
    '2.甲方密码是甲方办理业务的重要凭证。甲方入网后应当立即设置甲方密码，并妥善保管。为方便办理业务，乙方也可以根据甲方申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。',
    '3.凡使用甲方密码或随机密码定制、变更或取消业务的行为，均被视为甲方或甲方授权的行为。因甲方对密码保管不善造成的损失由甲方自行承担，但乙方有义务协助甲方或公安机关调查相关情况。',
    '4.甲方应当合理管理电信费用支出，出现异常的高额电信费用时（指超过甲方此前 3 个月平均电信费用 5 倍以上的费用），乙方一经发现，应当尽可能迅速告知甲方，如告知后未得到甲方确认的，乙方有权暂停本协议约定的部分或全部服务。',
    '5.在甲方欠费停机的情况下，乙方有权拒绝为甲方办理除交费、电信费用查询外的其他电信业务。',
    '6.一方违约给对方造成直接损失的，应当依法承担赔偿责任。',
    '7.因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任，但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。',
    '8.甲方使用本协议项下电信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等），由第三方承担责任。对于甲方提出的异议，乙方可以协调第三方解决。',
    '9.乙方可能重新启用销户的移动电话号码。号码被销户前原使用该号码的用户（“原机主”）如将该号码用作第三方联系电话或者绑定第三方业务，且原机主未在销户前予以变更或解除绑定，该号码可能会收到第三方发给原机主的通知，或者因第三方的限制无法在解除绑定前完成新用户认证绑定。甲方需自行办理解除绑定等手续，乙方在合理限度内提供配合。',
    '10.经甲方申请并经乙方同意的，甲方（以下称“原客户”）可以将本协议项下的完成实名登记的号码过户给符合乙方要求的第三人（以下称“新客户”）。办理过户手续需先解除原客户与乙方之间的合同关系。新客户在受让号码前需按乙方要求重新办理入网手续，签订入网协议，办理实名登记，并选择相应的资费方案。原客户办理过户手续时应先行结清所有已出账费用，未出账费用由新客户继续承担。原客户或新客户名下任何号码有欠费，需结清其名下所有号码的欠费方能办理过户。客户未经乙方同意擅自转让号码的，对乙方不产生效力，乙方有权拒绝办理过户手续并有权终止合同，收回号码。号码过户，原客户的电信积分不转移至新客户。过户号码若属于中国电信靓号范围，在办理过户手续时，新客户参照新入网用户按中国电信靓号规则执行对应级别的预存话费、保底消费合约、团购约定，并签订靓号协议。',
    '11.号码资源属于国家所有，甲方在本合同存续期间可使用乙方提供的号码，本合同解除或终止的，乙方有权收回号码。乙方将按照国家相关规定重复利用号码资源，对于使用过的号码将冻结90日后再次投放使用。因此，乙方不保证甲方所选择的号码未曾被使用过。',
    '12.号码资源回收后，乙方提供的与号码有关的包括但不限于189邮箱等电信业务同时注销。',
    '13.部分终端需要甲方提供不间断电源（如移动电话、光猫）。因甲方电源中断原因造成电信业务不能正常使用，乙方免责，但应积极为甲方提供咨询服务，协助甲方及时解决问题。',
    '14.乙方按约定提供通信服务，但并不对通信内容本身的真实性、合法性负责。甲方应当特别防范第三方恶意盗号、改号及垃圾短信等新型网络违法或犯罪行为，保护自身合法权益。如若发现上述情形出现，甲方应当及时通知乙方，防止产生或扩大损失，乙方在技术条件许可的情况下应当及时协助甲方处理相关事宜，但不因此承担违约责任。',
    '15.宽带默认开通互联网接入服务，具体上下行接入速率以《客户登记单》为准。电信公司标称的宽带接入速率，是指宽带入户点接入电信网能够达到的最高速率。客户室内布线、接入终端以及所访问的互联网内容源等非电信网络原因，可能会导致客户实际访问速率与宽带接入速率不一致，电信公司不因此承担责任。因技术原因，电信公司所分配给客户宽带接入互联网的IP地址将不定期更换，IP地址更换期间可能出现短暂的接入异常，电信公司不因此承担责任。',
    '16.乙方提供上门安装以及后期维护服务，但仅限于网络入户以及连接调通甲方所申请电信业务数量的信息点。上述信息点的室内布线由甲方负责完成，因甲方室内布线原因造成电信业务不能正常安装或者使用，由甲方承担安装维护责任。因技术条件以及网络覆盖范围等原因，乙方可能无法按照套餐约定的标准提供套餐内每一项电信业务（如光宽带），乙方不因此构成违约，但乙方应当提前告知甲方，甲方可自主选择是否办理该套餐。',
    '17.非因乙方过错所造成的电信业务中断或服务质量下降(包括但不限于电力中断、黑客攻击、第三方故意或过失破坏相关通信设施和线路等)，乙方免责。',
    '18.对于本协议所述的通知，乙方可以通过短信、电话、账单、电子邮件等方式之一通知甲方。因甲方原因造成的通知未送达或未及时送达，乙方免责。乙方亦可以通过网站、广播、报纸、电视或互联网媒体进行公告的方式通知甲方。',
    '19.双方协商一致可变更或终止套餐。甲方单方面变更或终止套餐约定的，甲方应当按照标准资费补足已经享受的套餐优惠部分价差（包括但不限于一次性费用、电信业务费用、终端租赁费用）。如该套餐另有终端等实物赠送的，甲方应当按照市场价以现金方式予以返还，与该套餐绑定的电话号码，乙方有权收回。甲方享受乙方给予的手机或其他补贴、充值送话费等优惠并有合约期的，若甲方在合约期内欠费停机或主动停机，仍按协议约定收取套餐资费，直至合约期结束。',
    '20.因业务政策变化等原因，乙方需修改、终止甲方所选择的套餐，乙方应提前三十日通知甲方，甲方可以选择修改后的套餐或乙方提供的其他套餐，也可以选择终止本协议，若甲方未选择以上任一种方案，则双方另行协商解决。',
    '21.甲方选择资费套餐时，应了解该套餐内所包含的电信业务范围和数量（包括但不限于通话时长、流量、短信数量等），甲方超出套餐约定范围或数量使用的电信业务，乙方将按照标准资费另行向甲方收取费用，具体以双方签署的《客户登记单》为准。套餐内包含的电信业务（包括但不限于通话时长、流量、短信数量）若有使用期限约定，甲方应当在约定期限内使用完毕，超期未使用的部分，视作甲方放弃。',
    '22.甲方同一主合同约定下的多个业务，或者多个独立业务之间存在约束关系的，其中一个业务出现欠费的，乙方将暂停其他业务，由甲方承担由此产生的责任，如甲方自业务暂停之日起满60日仍未结清欠费和违约金，乙方有权自业务暂停第61日起终止提供电信服务，收回相应号码（“销户”）、解除本协议，双方另有约定的除外。',
    '23.甲方选择、变更或终止银行托收、委托银行支付、银行代扣等方式支付电信费用时，如果甲方所办理的多个电信业务使用相同的计费账户（协议号），则该账户下所有电信业务只能使用同一种支付方式。',
    '24.甲方主动申请办理业务停机的，乙方将自业务停机次月起向甲方收取停机保号费（适用于移动电话、固定电话）/端口占用费（适用于宽带），直至甲方主动申请复机后当月停止收取。甲方因欠费等原因造成停机的（包含本协议约定本协议第三项第13款、第四项第22款约定的情形），乙方将自业务停机次月起向甲方收取停机保号费（适用于移动电话、固定电话）/端口占用费（适用于宽带），连续收取3个月，停机保号费、端口占用费收费标准参照乙方资费公示、备案内容。若甲方在停机时仍在合约期内，收费标准按本协议第四项第19款约定执行。',
    '25.甲方不得利用乙方提供的服务从事违法犯罪活动或违背公序良俗的活动，不得发送《中华人民共和国电信条例》和其他法律法规禁止的违法信息，不得未经接收人同意发送商业性信息、拨打商业性电话，不得为商业目的大批量发送信息、拨打电话，不得有其他发送骚扰信息、拨打骚扰电话等不当行为（以下合称“违法、骚扰信息/电话”）。甲方如有上述行为，乙方可采取相应措施预防或制止（包括但不限于标记、拦截、身份信息核验、暂停服务、终止提供服务中的一种或数种）。为保护不特定公众利益，治理违法、骚扰信息/电话，乙方可通过大数据技术分析、第三方信息采集、客户举报等手段，对疑似违法、诈骗、骚扰号码采取预防或制止措施（包括但不限于标记、拦截、身份信息核验、暂停服务、终止提供服务等）。若甲方认为乙方预防或制止措施错误的，可向乙方投诉，但甲方同意免于追究乙方责任。',
    '26.若号码连续90天内未使用或新入网号码两天内未将号卡插入手机并使用（包括拨打接听电话、发送短信、上网），乙方将执行保护性单停，限制主叫呼出和短信发送功能。需甲方进行二次实人认证后方可正常使用。',
    '五、 协议的生效、变更、终止和解除',
    '1.除本协议另有约定外，本协议自甲方以点击确认或其他方式表示同意本协议时成立，自甲方按本协议第一条第 1 款完成身份信息线上核验或现场查验时生效。如甲方办理号码携入的，本协议自甲方以点击确认或其他方式表示同意本协议时成立，于甲方按本协议第一条第 1 款完成身份信息线上核验或现场查验、乙方收到携号转网成功生效结果告知之时起生效。',
    '2.甲方如终止使用电信服务，应当结清电信费用及违约金（如有），并至乙方营业网点申请销户或号码携出，销户或号码携出后本协议终止。',
    '3.未经双方同意并办理有关手续，甲、乙方不得将依据本协议办理的业务全部或部分转让给第三方。',
    '4.甲方申请销户或号码携出时账户仍有余额的，乙方根据实际情况将账户余额按原路退还甲方，以中国电信充值卡充值的余额以充值卡方式退还，双方另有约定的除外；甲方通过参与赠送、优惠、兑换等营销活动获取的余额，不予退还，如按营销活动的业务规则另有约定的，按其业务规则处理。',
    '5.因乙方电信业务经营许可证的业务范围、业务种类发生改变，或因乙方对移动通信网络进行整体换代升级、技术进步、国家政策变动等原因，乙方无法向甲方继续提供原有业务或需对原有业务的服务方式、服务功能、操作方法、业务号码等进行调整时，乙方有权变更或终止本协议，但应当提前发布公告，提出解决方案，做好客户服务。',
    '6.有下列情形之一的，乙方有权单方终止或解除本协议：',
    '（1）甲方未按照用户真实身份信息登记要求，提供的登记资料不真实、不准确、不完整、无效，或经乙方通知后未在合理期限内配合提供的；',
    '（2）甲方收到电话卡后 20 天内未激活使用的；',
    '（3）甲方存在本协议第二条第 11 款、第 12 款所列任一行为，或由此引起用户投诉或举报的；',
    '（4）甲方未办理相关手续，自行改变电信服务使用性质、用途，或出租、二次转售、倒卖电话卡，或自行转让协议的；',
    '（5）甲方利用乙方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案；',
    '（6）甲方自欠费停机之日起 60 日内仍未结清所有欠费、违约金、赔偿金等费用的；',
    '（7）有权机关要求乙方停止对甲方提供电信服务的；',
    '（8）甲方原因造成本协议无法履行的；',
    '（9）法律、法规、规章及规范性文件规定的其他情形。',
    '7.本协议终止或解除后，乙方继续保留向甲方追缴所欠费用的权利，并有权收回甲方原使用的业务号码（销户的号码）。乙方对销户的业务号码冻结 90 日后将重新分配给其他用户使用。',
    '8.甲方改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用（如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、代扣等第三方协议，自行卸载甲方加载的第三方软件，避免造成甲方信息泄露；否则由此造成甲方或其他人损失由甲方承担。',
    '9.为使甲方便捷使用乙方或其关联公司提供的产品或服务、保障信息安全，如甲方通过其在乙方办理的移动电话号码使用（包括但不限于登录、真实身份验证等）乙方或乙方关联公司运营的各类互联网应用（统称“中国电信互联网应用”）的，中国电信互联网应用将默认该移动电话号码作为甲方的中国电信互联网应用账号（统称“天翼账号”）。当甲方对该移动电话号码进行改号、销户或过户时，中国电信互联网应用将自动注销甲方的上述天翼账号，并同步删除该天翼账号中的全部信息。甲方应当在改号、销户或过户前另行备份上述天翼账号中的信息。如甲方未备份造成信息丢失的，由甲方自行承担，乙方或乙方关联公司不承担责任且无义务为甲方进行信息恢复。中国电信互联网应用包括但不限于 189 邮箱、天翼云盘。',
    '六、 其他约定',
    '1.本协议适用中华人民共和国法律（仅为本协议之目的，不含香港特别行政区、澳门特别行政区和台湾地区法律）。所有因本协议引起的或与本协议有关的争议，可以通过协商、请求消费者协会或依法成立的其他调解组织调解（限于甲方为消费者）、向有关行政部门投诉，或者向乙方所在地的人民法院起诉等方式解决。',
    '2.如果本协议的任何条款在任何时候变成不合法、无效或不可强制执行但不从根本上影响本协议的效力时，本协议的其他条款不受影响。',
    '3.甲方办理各类业务所签署的业务登记单为本协议的一部分，业务登记单与本协议条款冲突的部分以业务登记单为准。乙方以公告等书面形式公开做出的服务承诺为本协议的补充，该等服务承诺与本协议冲突的部分以服务承诺为准。',
    '4.有关乙方提供本协议项下服务适用的个人信息处理规则，见《中国电信个人信息保护政策》。《中国电信个人信息保护政策》与本协议条款冲突的部分以《中国电信个人信息保护政策》为准。',
    '5.如甲方为不满十四周岁的未成年人的，甲方父母或其他监护人签订本协议时须同时签署《中国电信儿童个人信息处理知情同意书（适用于不满十四周岁的未成年用户）》（“知情同意书”）。甲方同意乙方按照知情同意书及其附件《中国电信儿童个人信息处理规则》处理甲方的个人信息。知情同意书及其附件《中国电信儿童个人信息处理规则》为本协议的一部分，知情同意书及其附件《中国电信儿童个人信息处理规则》与本协议条款冲突的部分以知情同意书为准。',
    '6.本协议一式两份，双方各保留一份，具有同等法律效力。甲乙双方以电子方式签署本协议的，甲方可以通过乙方提供的途径或指引查询、下载本协议。',
    '',
    '甲方：（签名或单位盖章）地址：',
    '联系方式：',
    '签署日期： 年 月 日',
    '',
    '甲方法定代理人或代理人：（签名或单位盖章）地址：',
    '联系方式：',
    '签署日期： 年 月 日',
    '',
    '乙方：（名称并盖章）',
    '地址：（乙方单位注册地址）签署日期： 年 月 日',
  ],
};
let zhouShanruwangDX = {
  title: '隐私政策',
  content: [
    '用户入网补充告知书',
    '尊敬的用户：',
    '本告知书内容适用于中国电信（又称“我们”）向中国电信用户（又称“您”）提供的所有产品/服务。',
    '一、经您申请并经我们同意的，您可以将本次新开户的完成实名登记的号码过户给符合我们要求的第三人（以下称“新客户”）。办理过户手续需先解除您与我们之间的合同权利义务。新客户在受让号码前需按我们要求重新办理入网手续，签订入网协议，办理实名登记，并选择相应资费方案。办理过户手续时应先行结清所有已出账费用，未出账费用由新客户继续承担。您或新客户名下任何号码有欠费，需结清名下所有号码的欠费方能办理过户。未经我们同意擅自转让号码的，对我们不产生效力，我们有权拒绝办理过户手续并有权终止合同，收回号码。号码过户，您的电信积分不转移至新客户。过户号码若属于靓号范围，在办理过户手续时，新客户参照新入网用户按我们靓号规则执行对应级别的预存话费、保底消费合约、团购约定，并签订靓号协议。',
    '二、号码资源属于国家所有，我们将按照国家相关规定重复利用号码资源，对于使用过的号码将冻结90日后再次投放使用。因此，我们不保证您所选择的号码未曾被使用过。',
    '三、号码资源回收后，我们提供的与号码有关的包括但不限于189邮箱等电信业务同时注销。',
    '四、部分终端需要您提供不间断电源（如移动电话、光猫）。因您电源中断原因造成电信业务不能正常使用，我们免责，但应积极为您提供咨询服务，协助您及时解决问题。',
    '五、我们按约定提供通信服务，但并不对通信内容本身的真实性、合法性负责。您应当特别防范第三方恶意盗号、改号及垃圾短信等新型网络违法或犯罪行为，保护自身合法权益。如若发现上述情形出现，您应当及时通知我们，防止产生或扩大损失，我们在技术条件许可的情况下应当及时协助您处理相关事宜，但不因此承担违约责任。',
    '六、宽带默认开通互联网接入服务，具体上下行接入速率以《客户登记单》为准。我们标称的宽带接入速率，是指宽带入户点接入电信网能够达到的最高速率。客户室内布线、接入终端以及所访问的互联网内容源等非电信网络原因，可能会导致您实际访问速率与宽带接入速率不一致，我们不因此承担责任。因技术原因，我们所分配给客户宽带接入互联网的IP地址将不定期更换，IP地址更换期间可能出现短暂的接入异常，我们不因此承担责任。',
    '七、我们提供上门安装以及后期维护服务，但仅限于网络入户以及连接调通您所申请电信业务数量的信息点。上述信息点的室内布线由您负责完成，因您室内布线原因造成电信业务不能正常安装或者使用，由您承担安装维护责任。因技术条件以及网络覆盖范围等原因，我们可能无法按照套餐约定的标准提供套餐内每一项电信业务（如光宽带），我们不因此构成违约，但我们应当提前告知您，您可自主选择是否办理该套餐。',
    '八、非因我们过错所造成的电信业务中断或服务质量下降(包括但不限于电力中断、黑客攻击、第三方故意或过失破坏相关通信设施和线路等)，我们免责。',
    '九、双方协商一致可变更或终止套餐。您单方面变更或终止套餐约定的，您应当按照标准资费补足已经享受的套餐优惠部分价差（包括但不限于一次性费用、电信业务费用、终端租赁费用）。如该套餐另有终端等实物赠送的，您应当按照市场价以现金方式予以返还，与该套餐绑定的电话号码，我们有权收回。您享受我们给予的手机或其他补贴、充值送话费等优惠并有合约期的，若您在合约期内欠费停机或主动停机，仍按协议约定收取套餐资费，直至合约期结束。',
    '十、因业务政策变化等原因，我们需修改、终止您所选择的套餐，我们应提前三十日通知您，您可以选择修改后的套餐或我们提供的其他套餐，也可以选择终止本协议，若您未选择以上任一种方案，则我们将和您另行协商解决。',
    '十一、您选择资费套餐时，应了解该套餐内所包含的电信业务范围和数量（包括但不限于通话时长、流量、短信数量等），您超出套餐约定范围或数量使用的电信业务，我们将按照标准资费另行向您收取费用，具体以您签署的《客户登记单》为准。套餐内包含的电信业务（包括但不限于通话时长、流量、短信数量）若有使用期限约定，您应当在约定期限内使用完毕，超期未使用的部分，视作您放弃。',
    '十二、您同一主合同约定下的多个业务，或者多个独立业务之间存在约束关系的，其中一个业务出现欠费的，我们将暂停其他业务，您将承担由此产生的责任，如您自业务暂停之日起满60日仍未结清欠费和违约金，我们有权自业务暂停第61日起终止提供电信服务，收回相应号码（“销户”）、解除“入网协议”，双方另有约定的除外。',
    '十三、您选择、变更或终止银行托收、委托银行支付、银行代扣等方式支付电信费用时，如果您所办理的多个电信业务使用相同的计费账户（协议号），则该账户下所有电信业务只能使用同一种支付方式。',
    '十四、您主动申请办理业务停机的，我们将自业务停机次月起向您收取停机保号费（适用于移动电话、固定电话）/端口占用费（适用于宽带），直至您主动申请复机后当月停止收取。您因欠费等原因造成停机的（包含入网协议第三项第13款、上述第十二项约定的情形），我们将自业务停机次月起向您收取停机保号费（适用于移动电话、固定电话）/端口占用费（适用于宽带），连续收取3个月，停机保号费、端口占用费收费标准参照我们资费公示、备案内容。若您在停机时仍在合约期内，收费标准按上述第九项约定执行。',
    '十五、您不得利用我们提供的服务从事违法犯罪活动或违背公序良俗的活动，不得发送《中华人民共和国电信条例》和其他法律法规禁止的违法信息，不得未经接收人同意发送商业性信息、拨打商业性电话，不得为商业目的大批量发送信息、拨打电话，不得有其他发送骚扰信息、拨打骚扰电话等不当行为（以下合称“违法、骚扰信息/电话”）。您如有上述行为，我们可采取相应措施预防或制止（包括但不限于标记、拦截、身份信息核验、暂停服务、终止提供服务中的一种或数种）。为保护不特定公众利益，治理违法、骚扰信息/电话，我们可通过大数据技术分析、第三方信息采集、客户举报等手段，对疑似违法、诈骗、骚扰号码采取预防或制止措施（包括但不限于标记、拦截、身份信息核验、暂停服务、终止提供服务等）。若您认为我们预防或制止措施错误的，可向我们投诉，但您同意免于追究我们责任。',
    '十六、若号码连续90天内未使用或新入网号码两天内未将号卡插入手机并使用（包括拨打接听电话、发送短信、上网），我们将执行保护性单停，限制主叫呼出和短信发送功能。需您进行二次实人认证后方可正常使用。',
    '开户申请人已清楚明确阅读上述告知内容，并完全理解、同意上述内容。',
  ],
};
