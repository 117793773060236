import React, { useEffect } from 'react';

interface Props {
  title?: string;
  imgPath: string;
  onClose: () => void;
}

export default function ViewPictureDialog({ title = '查看图片', imgPath, onClose }: Props) {
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
  }, []);

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose();
  }
  return (
    <div className="picture-dialog">
      <div className="picture-mask" onClick={handleClose} />
      <div className="picture-center" onClick={() => {}}>
        <div className="picture-title-block">
          <div className="picture-title">{title}</div>
          <div className="picture-close" onClick={handleClose}>
            <img alt="" className="close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="picture-content">
          <img src={imgPath} alt="" />
        </div>
      </div>
    </div>
  );
}

let closeIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACSUlEQVR4nO2avXKbQBSF9Th6CBUpXbpwYcfyT+L8PIr2nLPvlDKly8SWnThOYj+AyxRKEcggBliBgAXmfjOUcPd8u8Cyy2xmGIZhGIZhGIZh1Md7P3fOLfqu65xbeO/nfdfdwns/J/kM4KVPCc65BYAXks/RJKxWq1ckP5HcJMc3ABdd13XOHZP8kqn7meRB13W3KAj/XwLJ867qFoSPIyEzBPMN2XR1O1TVJPkHwGHbNSsBcJH0eFGD7gCctVWrouc3AJ4kfWyrVi1InpO8L5MgablvjarwJH+T/NBGlsZUSZC03kfC4MOneO/PSN6VDNE1gNO616wKL+mXpPddZGkMgFIJJG/rSAj0/PDCp0haSlqXSZD0OnSNQPifJN/1kaUxkpYACiVIunHOlUoYffgUAKckb0ueCTckT/LnBO75R0lXMbI0pkoCya9ZCYGefwQwrvApu8wYd5jhHcXOsRchCZMOn0LyJBn2RUGLjgdJb2K3u1UkHe8o4YHkZez2dkIioexhtyH5fbLhZ7Pg037aAnYIP10JNcJPT0LoPZ8cpfOE2O3fi10mOQCOJikhMOy3XnUkL5NhP43bIRD+R9EkZzISQuFJvi07d/QSQl91VeFTRish9D1f55N2dBJCKzlNFjNGIyG0gMk9lrEGL6HL8CmDlRDatGhz6XpwEmIsYw1qxkjygP+2pPMNeWKH21UVI+G6993hvARJnYbP1M1L6D98SvqLTFfDvozM7RDvF5kU7/08Rg8AOIwe3jAMwzAMwzAMY6z8BS3ngNlcif7iAAAAAElFTkSuQmCC';
