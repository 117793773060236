import axios from 'axios';
import main from './main';
import { showToast } from './utils/utils';
const api = axios.create({
  baseURL: '/api',
  timeout: 120000,
});

// 获取商品列表
export async function apiGetGoodsList() {
  return await api.get('/card/client/goodsList', { params: { shopId: main.shopId } });
}
// 获取商品详情
export async function apiGetGoodsDetail(code: string) {
  return await api.get('/card/client/goodsDetail', { params: { code } });
}

// 查询订单列表
export async function apiGetOrderList(params: any) {
  return await api.get('/card/client/queryOrder', { params });
}

// 首页配置
export async function apiGetH5Config() {
  return await api.get('/card/client/index', { params: { shopId: main.shopId } });
}
// 提交订单
export async function apiSubmitOrder(body: any) {
  return await api.post('/card/client/submitOrder', body);
}
// 提交订单 支付
export async function apiPayAndSubmitOrder(body: any) {
  return await api.post('/card/client/payGoodsOrder', body);
}
// 获取地址库
export async function apiGetMatchById(supId: string) {
  return await api.get('/card/client/matchById', { params: { supId } });
}

// 获取号码池
export async function apiGetCardPool(goodsCode: string, province?: string, city?: string, county?: string) {
  return await api.get('/card/client/queryPhonePool', { params: { goodsCode, province, city, county } });
}

// 12306页面监控
export async function apiMonitorPage(id: string) {
  return await api.get(`/card/client/monitorPage/${id}`);
}
// 获取OPENID
export async function apiGetOpenId(code: string, appId: string) {
  return await api.get(`/card/common/getUserOpenId?code=${code}&appId=${appId}`);
}
// 获取支付配置列表
export async function apiGetPayConfigList(goodsCode: string) {
  return await api.get(`/card/client/getPayList`, { params: { goodsCode } });
}

// 通过编码获取订单编号
export async function apiClientGetOrderSn(code: string) {
  return await api.get(`/card/client/getOrderSn/${code}`);
}

/// 图片上传
export async function apiUploadPic(file: File, randomCode: string) {
  let res = await api.post(
    '/resource/oss/randomCode',
    { file, randomCode },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  let url: string | null = null;
  if (res.data.code === 200) {
    url = res.data.data.url;
  } else {
    showToast(res.data.msg ?? '上传失败');
  }

  return url;
}
// 提交订单-图片上传
export async function apiOssUpload(file: File) {
  let res = await api.post(
    '/card/client/oss/upload',
    { file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  let url: string | null = null;
  if (res.data.code === 200) {
    url = res.data.data.url;
  } else {
    showToast(res.data.msg ?? '上传失败');
  }

  return url;
}

// 提交三证
export async function apiSavePhoto(body: any) {
  return await api.post(`/card/client/savePhoto`, body);
}

// 获取短信验证码
export async function apiMarketVerifyCode(phoneNum: string, goodsCode: string) {
  return await api.get('/card/client/market/verifyCode', { params: { phoneNum, goodsCode } });
}

// 提交存量订单
export async function apiMarketOfferOrder(data: any) {
  return await api.get('/card/client/market/offerOrder', { params: data });
}

// 加密手机号
export async function apiEncryption(data: any) {
  let res = await api.get('/card/client/encryption', { params: data });
  return res.data;
}

// 申请退款
export async function apiApplyRefund(params: any) {
  let res = await api.get('/card/client/applyRefund', { params });
  return res.data;
}

// 取消申请退款
export async function apiCancelRefund(params: any) {
  let res = await api.get('/card/client/cancelRefund', { params });
  return res.data;
}
