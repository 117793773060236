import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  apiEncryption,
  apiGetCardPool,
  apiGetGoodsDetail,
  apiGetMatchById,
  apiGetOpenId,
  apiGetPayConfigList,
  apiMarketOfferOrder,
  apiMarketVerifyCode,
  apiOssUpload,
  apiPayAndSubmitOrder,
  apiSubmitOrder,
} from '../api';
import AppBar from '../components/appBar';
import Empty from '../components/empty';
import Loading from '../components/loading';
import MessageDialog from '../components/messageDialog';
import MCityPicker from '../components/m_city_picker/m_city_picker';
import Page from '../components/page';
import ProtocolDialog from '../components/protocolDialog';
import MCheckbox from '../components/radioBox';
import MSelectbox from '../components/select';
import SelectPhoneDialog from '../components/selectPhoneDialog';
import ViewPictureDialog from '../components/viewPictureDialog';
import main from '../main';
import { Goods, PayConfig } from '../types';
import showPayPage, { PayType } from '../utils/pay';
import { copyToClipboard, isWeixinEnv, parseLocationParams, showToast } from '../utils/utils';
import { protocolData } from './protocolPage';

let loading = false;

// 广东移动埋码
let insertCode = {
  businessType_var: '放号',
  goodsType_var: '号卡',
  apiId_var: '',
  apiName_var: '',
  merchantsId_var: 'GDOPEN_2116613', // 商品所属商户id。
  merchantsIdCity_var: '省统', //商户ID归属地市，枚举值：省统、广州、中山、云浮……
  merchants_var: '河南天诺信息技术有限公司', // 商品所属商户名称。
  contactNumber_var: '', //输入电话号码,传AES加密后的手机号
  selectNumber_var: '', // 办理号码(需AES加密),传用户要购买的那个号码
  errorMessage_var: '', // 业务办理失败时的失败原因明细
  channelId_var: '', // 下单渠道编码
  operatorId_var: '', // 下单工号
  payType_var: '', // 业务办理支付时的支付方式名称。（支付宝、微信、话费、……）
  merchantsOrder_var: '', // 自建的订单号。
  orderNumber_var: '', // 能开接口返回的订单号。
  ordertype_var: '号卡订单', // 业务办理支付完成时所属订单类型名称。与goodsType_var对应，是什么商品类型就是什么类型订单。
};
export default function DetailPage() {
  const ref = useRef<{ timer?: NodeJS.Timeout; count?: number }>({});
  let location = useLocation();
  const navigate = useNavigate();
  let landingCode = sessionStorage.getItem('landingCode') ?? '';

  const [goodsDetail, setGoodsDetail] = useState<Goods>();
  const [loadError, setLoadError] = useState('');
  // 已选卡号
  const [selectCard, setSelectCard] = useState<{
    activatePhone: string;
    checkCode: string;
    phoneSessionId?: string;
    phoneTag?: string;
    numberPoolId?: string;
    numberId?: string;
  }>();
  // 开卡号码
  const [activatePhone, setactivatePhone] = useState('');
  // 身份证名
  const [cardName, setCardName] = useState('');
  // 身份证号
  const [cardNum, setCardNum] = useState('');
  // 收件人手机号
  const [phone, setPhone] = useState('');
  // 验证码
  const [verifyCode, setVerifyCode] = useState('');
  const [sendCaptcha, setSendCaptcha] = useState(0);
  // 省市区
  const [province, setProvince] = useState('');
  // 市区
  const [city, setCity] = useState('');
  // 区
  const [area, setArea] = useState('');
  // 地址
  const [address, setAddress] = useState('');
  // 运营商
  const [operatorsType, setoperatorsType] = useState('');
  // 身份证头像照片
  const [cardAvatar, setCardAvatar] = useState<string>();
  // 身份证国徽照片
  const [cardNationalEmblem, setNationalEmblem] = useState<string>();
  // 免冠正面照片
  const [handPhoto, setHandPhoto] = useState<string>();
  // 已选同意协议
  const [checkProtocol, setCheckProtocl] = useState(false);
  // 显示协议弹窗
  const [showProtocol, setShowProtocol] = useState<string>();
  // 显示选择卡号弹窗
  const [showSelectCardDialog, setShowSelectCardDialog] = useState(false);
  // 运营商数据
  const [operatorsList, setoperatorsList] = useState<{ value: string; label: string }[]>([]);
  // 是否隐藏appbar 默认不隐藏 app内嵌入网页时可设置 h=1 隐藏
  const [hideAppBar, setHideAppBar] = useState(false);
  /**
   * activatePhone :  17260779503
   * checkCode : dGcoEdX1DCNUYjXy4
   */
  const [cardPool, setCardPool] = useState([]);
  const [urlQueryParams, setUrlQueryParams] = useState<any>({});
  // 支付配置列表
  const [payConfigList, setPayConfigList] = useState<PayConfig[]>([]);
  const [payType, setPayType] = useState('');
  // 微信支付配置准备完成
  const [wxReady, setWxReady] = useState(false);
  // 判断视频上的播放图片是否显示
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(true);
  const [selectPhoneFlag, setselectPhoneFlag] = useState<boolean>(true);
  // 短信验证码orderId
  const [smsKey, setSmsKey] = useState();
  // 路路通productId
  const [productId, setproductId] = useState<string>('');
  // 接口报错msg
  const [messageDialogShow, setmessageDialogShow] = useState<string>('');
  // 地址库数据
  const [matchList, setMatchList] = useState([]);
  // 上传照片显示loading
  const [uploadLoading, setuploadLoading] = useState<boolean>(false);
  // 查看图片
  const [viewPictureDialogShow, setviewPictureDialogShow] = useState<string | undefined>();
  // 加密手机号
  const [aesPhone, setaesPhone] = useState<string>();
  // 获取商品接口报错
  const [detailerrorMsg, setDetailerrorMsg] = useState<string>('');
  useEffect(() => {
    main.enterUrl = window.location.href;
    // 微信code 和商品code参数冲突，改商品code为id自动跳转
    let _p = parseLocationParams(location.search) ?? {};
    setUrlQueryParams(_p);

    if (_p.code?.length === 10) {
      let queryStr = location.search.replaceAll('code=', 'id=');
      window.location.replace(window.location.origin + window.location.pathname + '/' + queryStr);
    } else if (isWeixinEnv()) {
      // 获取支付列表
      loadPayConfigList(_p.id);
    } else {
      // 非微信环境内（浏览器、支付宝直接获取商品详情与支付方式）
      loadGoodsDetail(_p.id);
      loadPayConfigList(_p.id);
    }
  }, []); // eslint-disable-line

  // 获取OPENID
  async function getOpenId() {
    try {
      let res = await apiGetOpenId(main.code!, main.appId);
      let data = res.data;
      if (data.code === 200) {
        main.openId = data.data;
        console.log('main.openId', main.openId);
        localStorage.setItem(main.appId, main.openId ?? '');

        loadGoodsDetail(urlQueryParams.id);
      }
    } catch (e) {}
  }

  function wxPayReady() {
    if (!isWeixinEnv()) return;
    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => setWxReady(true), false);
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => setWxReady(true));
      }
    } else {
      setWxReady(true);
    }
  }

  // 获取支付列表
  async function loadPayConfigList(code?: string) {
    console.log('urlQueryParams', urlQueryParams);
    try {
      let res = await apiGetPayConfigList(code ?? urlQueryParams.id);
      let data = res.data;
      if (data.code === 200) {
        let _list = data.data ?? [];
        let arr = [];
        if (isWeixinEnv()) {
          arr = _list.filter((i: { payType: string }) => i.payType.indexOf('wechatJsPay') !== -1);
          main.appId = arr[0]?.appId;
          let param = parseLocationParams(location.search);
          // 对比appid和链接内是否一样
          if (main.appId !== param.appId) {
            // 跳转自身带上appid
            param.appId = main.appId;
            let url = Object.keys(param)
              .map((key) => `${key}=${param[key]}`)
              .join('&');
            let path = window.location.origin + window.location.pathname + `?${url}`;
            window.location.href = path;

            console.log('url---', url, path);
          } else {
            let openId = localStorage.getItem(main.appId);
            if (!!openId) {
              main.openId = openId;
              console.log('---有openId', openId);
              loadGoodsDetail(urlQueryParams.id);
            } else {
              //  判断是否有CODE
              if (!!param.code) {
                main.code = param.code;
                getOpenId();
              } else {
                let redirect_uri = encodeURIComponent(window.location.href);
                // 没有取到缓存openId, 跳微信链接
                document.location.replace(
                  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${param.appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
                );
              }
              // window.location.href = window.location.origin + `/product` + location.search + `&appId=${main.appId}`;
            }
          }
          // 用appID 取缓存openID 跳转自身带上appid
        } else {
          arr = _list.filter((i: { payType: string }) => i.payType.indexOf('alipay') !== -1);
        }

        setPayConfigList(arr);
        if (arr.length > 0) {
          setPayType(arr[0].payType);
        }
      }
    } catch (e) {}
  }

  async function loadGoodsDetail(code: string) {
    wxPayReady();
    let _p = parseLocationParams(location.search) ?? {};
    console.log('code-----', code, _p);
    try {
      let res = await apiGetGoodsDetail(code ?? _p.id);
      let data = res.data;
      if (data.code === 200) {
        setGoodsDetail(data.data);
        if (data.data?.goodsType === 'order_subscribe') {
          window.location.href = `https://h5.100861.net/ys/?${data.data.goodsCode}`;
        }
        // 带参数的和路路通的都隐藏
        let _hideTitle = _p.h === '1' || !!_p.productId;
        if (_p.productId) {
          setproductId(_p.productId);
        }
        setHideAppBar(_hideTitle);
        if (landingCode) {
          main.shopId = landingCode;
        } else if (data.data?.shopId) {
          main.shopId = (parseInt(data.data?.pageType ?? '0') !== 0 ? data.data.pageType : '') + data.data.shopId;
        }

        if (data.data.supId) {
          getMatchById(data.data.supId);
        }

        if (data.data.ifCampOn === 'Y' && data.data.ifAddress !== 'Y') {
          loadCardPool(data.data.goodsCode);
        }
        // 商品类型不等于号卡时获取运营商数据
        if (data.data?.goodsType !== 'order') {
          let _data = [
            { label: '中国移动', value: 'cmcc' },
            { label: '中国联通', value: 'unicom' },
            { label: '中国电信', value: 'telecom' },
            { label: '中国广电', value: 'cbn' },
          ];
          let list = data.data.operatorsType ? data.data.operatorsType.split(',') : [];
          let _list: any = [];
          list.forEach((item: string) => {
            _data.forEach((i) => {
              if (item === i.value) {
                _list.push(i);
              }
            });
          });
          setoperatorsList(_list);
          setoperatorsType(_list[0]?.value);
        }
        // 广东移动插码初始化开始----
        if (main.supId === data?.data?.supId) {
          window.gdp('init', '8d2279a5e2f18b7c', '9d59ac736f01c688', {
            host: 'collect.gmcc.net', // 数据发送地址。
            compress: true, // 开启数据压缩模式。
            // debug: true, // 开启测试模式，控制台可看到数据，生产环境请勿添加此参数。
            hashtag: true, //开启页面URL混淆采集
            scheme: 'https', //http页面加此参数
          });
          window.gdp('setGeneralProps', {
            global_merchantsId_var: 'GDOPEN_2116613', // 商户id
            global_merchants_var: '河南天诺信息技术有限公司', // 商户名称
            global_apiId_var: 'smscodechkodcommitorder', // 接口id；传产品受理的接口ID
            global_apiName_var: '短信验证及订单提交', // 调用接口名称；传产品受理的接口名称
            global_merchantsIdCity_var: '省统', //商户ID归属地市，枚举值：省统、广州、中山、云浮……
          });
          window.gdp(
            'track',
            'merchants_bussinessProcessing',
            Object.assign({}, insertCode, {
              goodsId_var: data?.data?.skuCode, // 业务办理时所属的商品ID。（需与能开商品编码一致）
              goodsName_var: data?.data?.skuName, //业务办理时商品名称。
              processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、输入电话号码、获取短信验证码、输入短信验证码、立即办理、确认办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
            })
          );
        }
        // 广东移动插码开始初结束----
      } else if (data.code === 503) {
        setLoadError(data?.msg ?? '暂未找到商品');
      } else {
        // setLoadError(data.msg ?? '暂未找到商品');
        setDetailerrorMsg(data.msg ?? '暂未找到商品');
      }
    } catch (e: any) {
      setLoadError(e?.message ?? '暂未找到商品');
    }
  }
  async function getMatchById(supId: string) {
    try {
      let res = await apiGetMatchById(supId);
      let data = res.data;
      if (data.code === 200) {
        setMatchList(data.data);
      } else {
        setMatchList([]);
      }
    } catch (e: any) {
      showToast(e.message ?? '获取可选号码失败,请重试');
    }
  }

  async function loadCardPool(code: string, _p?: string, _v?: string, _c?: string) {
    setSelectCard(undefined);
    setCardPool([]);
    try {
      let res = await apiGetCardPool(code, _p, _v, _c);
      let data = res.data;
      if (data.code === 200) {
        setCardPool(data.data);
        setselectPhoneFlag(true);
      } else {
        setselectPhoneFlag(false);
        setmessageDialogShow(data.msg ?? '获取可选号码失败,请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取可选号码失败,请重试');
    }
  }

  function handleCityPickerChange(v: string[]) {
    if (v[0] === '暂无可选城市') return;
    setProvince(v[0]);
    setCity(v[1]);
    setArea(v[2]);
    handChangeIpt('选择收货地址');
    if (goodsDetail?.ifAddress === 'Y') {
      loadCardPool(goodsDetail?.goodsCode, v[0], v[1], v[2]);
    }
  }
  // 不能有数字、字母、特殊符号（不包含少数民族名字中的点）、不能一个字
  function isValidName(name: string) {
    // 允许的少数民族名字中的点（例如：·）
    const allowedDots = /[·]/;
    // 校验姓名是否包含不允许的字符
    const regex = /^[^0-9a-zA-Z!！，、。@#$%^&*()_+\-=【】[{};':"\\|,.<>/?]+$/;
    if (name.length <= 1) {
      return false;
    } else {
      // 检查是否包含不允许的点（如果不是允许的点）
      if (!allowedDots.test(name)) {
        return regex.test(name);
      } else {
        // 替换掉所有非允许的点后校验
        const nameWithoutInvalidDots = name.replace(/[^·]/g, '');
        return regex.test(nameWithoutInvalidDots);
      }
    }
  }
  async function handleSubmit() {
    console.log('landingCode', landingCode);
    let shop_num = landingCode ? main.shopId?.split('')[0] : '';
    let shopNum = !shop_num ? '' : /^\d+$/.test(shop_num) ? shop_num : 0;
    let interceptArr = ['酒店', '宾馆', '网吧', '驿站'];
    let idx = interceptArr.findIndex((i) => !address.indexOf(i));
    /// 身份证正则
    const cardNumReg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (goodsDetail?.goodsType === 'order_subscribe' && !activatePhone) {
      return showToast('请输入开卡号码');
    }
    if (goodsDetail?.ifCampOn === 'Y' && !selectCard) {
      return showToast('请选择号码');
    }
    if (operatorsList.length > 0 && goodsDetail?.goodsType && goodsDetail?.goodsType !== 'order' && !operatorsType) {
      return showToast('请选择运营商');
    }
    if (!isValidName(cardName)) {
      return showToast('请输入身份证名');
    }
    if (!cardNum && (goodsDetail?.goodsType === 'order' || goodsDetail?.goodsType === 'order_subscribe')) {
      return showToast('请输入身份证号');
    } else if (
      (goodsDetail?.goodsType === 'order' || goodsDetail?.goodsType === 'order_subscribe') &&
      !cardNumReg.test(cardNum)
    ) {
      return showToast('请输入正确的身份证号');
    }
    let reg = /^1[3456789]\d{9}$/;
    if (goodsDetail?.goodsType !== 'order_subscribe' && !reg.test(phone)) {
      return showToast('请输入正确的收件号码');
    }
    if (goodsDetail?.goodsType !== 'order_subscribe' && !province) {
      return showToast('请选择省市区');
    }
    if (goodsDetail?.goodsType !== 'order_subscribe' && !address) {
      return showToast('请输入详细地址');
    }
    if (goodsDetail?.goodsType !== 'order_subscribe' && goodsDetail?.goodsType !== 'equipment' && idx !== -1) {
      return showToast(`详细地址不能包含${interceptArr[idx]}`);
    }
    if (goodsDetail?.ifPhoto === 'Y' && !cardAvatar) {
      return showToast('请上传身份证头像照片');
    }
    if (goodsDetail?.ifPhoto === 'Y' && !cardNationalEmblem) {
      return showToast('请上传身份证国徽照片');
    }
    if (goodsDetail?.ifPhoto === 'Y' && !handPhoto) {
      return showToast('请上传免冠正面照片');
    }
    if (!checkProtocol) {
      return showToast('请阅读并同意协议');
    }
    if (loading) {
      return;
    }
    loading = true;
    let submitOverTips = '';
    if (goodsDetail?.supId === main.supId) {
      window.gdp(
        'track',
        'merchants_bussinessProcessing',
        Object.assign({}, insertCode, {
          goodsId_var: goodsDetail?.skuCode, // 业务办理时所属的商品ID。（需与能开商品编码一致）
          goodsName_var: goodsDetail?.skuName, //业务办理时商品名称。
          apiId_var: 'AirpickinstallnewOrder', // 调用能开接口id。（调用能开接口时赋值）
          apiName_var: '线上放号订单下单接口', // 调用能开接口名称。（调用能开接口时赋值）
          contactNumber_var: aesPhone, //联系电话(AES加密后的手机号),传用户的联系电话
          processType_var: '提交订单', // 业务办理时的每个办理步骤。（页面浏览、输入姓名(点击输入框时触发）、输入联系电话(点击输入框时触发）、输入身份证(点击输入框时触发）、选择收货省/市（选择成功后触发）、选择号码归属（选择成功后触发）、选择号码（选择号码成功后触发）、选择收货地址（选择成功后触发）、填写详细地址(点击输入框时触发）、提交订单、提交订单成功、提交订单失败......）
        })
      );
    }

    let _p = parseLocationParams(location.search) ?? {};
    let orderData = {
      goodsId: goodsDetail!.id,
      cardName,
      cardNo: cardNum,
      receiverName: cardName,
      receiverPhone: phone,
      province,
      city,
      county: area,
      address,
      activatePhone: goodsDetail?.goodsType === 'order_subscribe' ? activatePhone : selectCard?.activatePhone,
      avatarImg: cardAvatar,
      nationalEmblemImg: cardNationalEmblem,
      handPhotoImg: handPhoto,
      operatorsType: operatorsType,
      shopNum,
      apiParams: Object.assign({}, urlQueryParams ?? {}, {
        preCityCode: selectCard?.checkCode,
        checkCode: selectCard?.checkCode,
        code: _p.id,
        phoneSessionId: selectCard?.phoneSessionId,
        phoneTag: selectCard?.phoneTag,
        numberId: selectCard?.numberId,
        numberPoolId: selectCard?.numberPoolId,
      }),
    };
    // 非支付订单
    if (goodsDetail?.paySwitch !== 'Y') {
      try {
        let res = await apiSubmitOrder(orderData);
        let data = res.data;
        if (data.code === 200) {
          submitOverTips = data.msg ?? '提交成功';
          console.log('data', data);
          if (goodsDetail?.supId === main.supId) {
            window.gdp(
              'track',
              'merchants_bussinessProcessing',
              Object.assign({}, insertCode, {
                goodsId_var: goodsDetail?.skuCode, // 业务办理时所属的商品ID。（需与能开商品编码一致）
                goodsName_var: goodsDetail?.skuName, //业务办理时商品名称。
                contactNumber_var: aesPhone, //联系电话(AES加密后的手机号),传用户的联系电话
                processType_var: '提交订单成功', // 业务办理时的每个办理步骤。（页面浏览、输入姓名(点击输入框时触发）、输入联系电话(点击输入框时触发）、输入身份证(点击输入框时触发）、选择收货省/市（选择成功后触发）、选择号码归属（选择成功后触发）、选择号码（选择号码成功后触发）、选择收货地址（选择成功后触发）、填写详细地址(点击输入框时触发）、提交订单、提交订单成功、提交订单失败......）
                channelId_var: data.data?.channelId_var, // 下单渠道编码
                operatorId_var: data.data?.operatorId, // 下单工号
                merchantsOrder_var: data.data?.merchantsOrder_var, // 自建的订单号。
                orderNumber_var: data.data?.orderNumber_var, // 能开接口返回的订单号。
              })
            );
          }
          navigate('/submit', { state: submitOverTips });
        } else {
          return setmessageDialogShow(data.msg ?? '提交失败');
        }
      } catch (e: any) {
        return showToast(submitOverTips);
      } finally {
        loading = false;
      }
    } else {
      // =================================支付订单===================================
      // if (!isWeixinEnv()) {
      //   return showToast('请在微信中打开页面');
      // }
      if (isWeixinEnv()) {
        if (!wxReady || !main.openId) {
          return showToast('微信支付初始化失败，请刷新重试');
        }
      }
      try {
        let res = await apiPayAndSubmitOrder({
          openId: main.openId,
          appId: main.appId,
          payType: payType,
          clientOrderBo: orderData,
          shopNum,
        });
        let data = res.data;
        if (data.code === 200) {
          let _data = data.data;
          let payRes = await showPayPage(_data, payType);
          if (payRes.code === 200) {
            submitOverTips = payRes.msg ?? '提交成功';
            navigate('/submit', { state: submitOverTips });
          } else if (payRes.code === 300) {
            console.log('payRes', payRes);
            window.location.href = payRes.url;
          } else {
            showToast(payRes.msg);
          }
        } else {
          if (goodsDetail?.supId === main.supId) {
            window.gdp(
              'track',
              'merchants_bussinessProcessing',
              Object.assign({}, insertCode, {
                goodsId_var: goodsDetail?.skuCode, // 业务办理时所属的商品ID。（需与能开商品编码一致）
                goodsName_var: goodsDetail?.skuName, //业务办理时商品名称。
                contactNumber_var: aesPhone, //联系电话(AES加密后的手机号),传用户的联系电话
                processType_var: '提交订单', // 业务办理时的每个办理步骤。（页面浏览、输入姓名(点击输入框时触发）、输入联系电话(点击输入框时触发）、输入身份证(点击输入框时触发）、选择收货省/市（选择成功后触发）、选择号码归属（选择成功后触发）、选择号码（选择号码成功后触发）、选择收货地址（选择成功后触发）、填写详细地址(点击输入框时触发）、提交订单、提交订单成功、提交订单失败......）
                errorMessage_var: data.msg, // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
              })
            );
          }
          return setmessageDialogShow(data.msg ?? '提交失败');
        }
      } catch (e: any) {
        submitOverTips = e.message ?? '提交失败';
        return showToast(submitOverTips);
      } finally {
        loading = false;
      }
    }
    setSelectCard(undefined);
    setCardName('');
    setCardNum('');
    setPhone('');
    setAddress('');
    setCardAvatar('');
    setNationalEmblem('');
    setHandPhoto('');
  }
  // 存量提交
  async function handleSubmitCL() {
    if (!phone) {
      return showToast('请输入收件人手机号');
    }
    if (!verifyCode) {
      return showToast('请输入验证码');
    }
    try {
      let res = await apiMarketOfferOrder({
        phoneNum: phone,
        verifyCode: verifyCode,
        goodsCode: goodsDetail?.goodsCode,
        orderSn: smsKey,
      });
      let data = res.data;
      if (data.code === 200) {
        showToast('提交成功');
        setPhone('');
        setVerifyCode('');
        setSendCaptcha(0);
      } else {
        setmessageDialogShow(data.msg ?? '提交失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '提交失败');
    }
  }
  // 发送验证码
  async function getSendCaptcha() {
    let telReg = /^1[3-9]{1}[0-9]{9}$/;
    if (!telReg.test(phone)) {
      return showToast('手机号格式不正确');
    }
    try {
      let res = await apiMarketVerifyCode(phone, goodsDetail!.goodsCode);
      let data = res.data;
      if (data.code === 200) {
        if (data?.data?.orderId) {
          setSmsKey(data.data.orderId);
        }
        if (ref.current.timer) {
          clearInterval(ref.current.timer);
        }
        ref.current.count = 60;
        ref.current.timer = setInterval(() => {
          ref.current.count! -= 1;
          setSendCaptcha(ref.current.count!);
          if (ref.current.count! <= 0) {
            clearInterval(ref.current.timer);
          }
        }, 1000);
        showToast('发送成功');
      } else {
        let msg =
          goodsDetail?.goodsType === 'market' && goodsDetail?.supId === '1729792961245667330'
            ? data.data.tipsMsg
            : data.msg;
        setmessageDialogShow(msg ?? '发送失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '发送失败');
    }
  }

  function handleDetailRef(v?: HTMLDivElement | null) {
    if (v) {
      let imgList = v.getElementsByTagName('img');
      if (imgList.length) {
        for (let i = 0; i < imgList.length; i++) {
          let img = imgList.item(i);
          if (img) {
            let url = img.getAttribute('data-href');
            if (url) {
              if (!url?.startsWith('http')) {
                //不是http开头的 跳转到固定页面并传参数
                let tmp = protocolData.find((i) => i.value === url);
                if (tmp) {
                  img.onclick = () => navigate('/protocol', { state: url });
                }
              } else {
                // 是HTTP开头的 跳转走
                if (!img.onclick) {
                  img.onclick = () => window.open(url!);
                }
              }
            }
          }
        }
      }
    }
  }
  function changeVideo(event: any) {
    const video: any = document.getElementById('videoPlayer');
    let isVideoPlaying = !video.paused && !video.ended && video.readyState > 2;
    setIsVideoPlaying(isVideoPlaying);
    if (isVideoPlaying) {
      video.pause();
    } else {
      video.play();
    }
  }
  function handleCopyHost() {
    let url = window.location.href;
    copyToClipboard(url);
  }
  // 上传照片
  const chooseImg = async (t: FileList | null, type: string) => {
    if (t && t[0]) {
      if (t[0].size / 1024 / 1024 > 10) return showToast('照片不可以上传超过10MB');
      setuploadLoading(true);
      try {
        const res = await apiOssUpload(t[0]);
        if (res) {
          if (type === 'cardAvatar') {
            setCardAvatar(res);
          } else if (type === 'cardNationalEmblem') {
            setNationalEmblem(res);
          } else if (type === 'handPhoto') {
            setHandPhoto(res);
          }
        }
      } catch (error) {
      } finally {
        setuploadLoading(false);
      }
    }
  };
  // 广东移动插码输入框开始----
  const handChangeIpt = (type: string) => {
    if (main.supId === goodsDetail?.supId) {
      window.gdp(
        'track',
        'merchants_bussinessProcessing',
        Object.assign({}, insertCode, {
          goodsId_var: goodsDetail?.skuCode, // 业务办理时所属的商品ID。（需与能开商品编码一致）
          goodsName_var: goodsDetail?.skuName, //业务办理时商品名称。
          contactNumber_var: aesPhone ? aesPhone : '', //输入电话号码,传AES加密后的手机号
          processType_var: type, // 业务办理时的每个办理步骤。（页面浏览、输入电话号码、获取短信验证码、输入短信验证码、立即办理、确认办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        })
      );
    }
  };
  // 广东移动插码输入框结束----
  // 获取加密手机号
  async function getStockAesPhone(tel: string) {
    if (main.supId === goodsDetail?.supId) {
      let res = await apiEncryption({ msgs: tel });
      if (res.code === 200) {
        setaesPhone(res?.data[tel]);
      } else {
        showToast('请检查手机号是否正确');
      }
    }
  }
  // 跳转客服
  function handleServiceLink() {
    let url = landingCode ? main.serviceLink : goodsDetail?.serviceUrl;
    window.open(url!, '_blank');
  }
  if (loadError || !goodsDetail || detailerrorMsg) {
    return (
      <Page
        style={Object.assign({}, main.pageBgStyle, { popGesture: 'none' })}
        className="detail-page d-flex-c"
        contentClassName="flex-1 h-1 overflow-y"
        appBar={<AppBar className="bg-transparent" title="商品详情" />}
      >
        {!goodsDetail && !loadError && !detailerrorMsg ? (
          <Loading tips={'正在加载中...'} />
        ) : (
          <Empty
            className="emptybox"
            pic={detailerrorMsg ? '/pic/bg_error.png' : '/pic/pic_not_found.png'}
            tips={detailerrorMsg ?? 'Page not found...'}
          />
        )}
      </Page>
    );
  }
  const excludeRegions = {
    provinces: !!goodsDetail.stopProvince ? goodsDetail.stopProvince : [],
    cities: !!goodsDetail.stopCity ? goodsDetail.stopCity : [],
    district: !!goodsDetail.stopCounty ? goodsDetail.stopCounty : [],
  };
  return (
    <Page
      style={Object.assign({}, main.pageBgStyle, { popGesture: 'none' })}
      className="detail-page d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c"
      appBar={
        hideAppBar
          ? undefined
          : !!landingCode && (
              <AppBar
                showBack={!!main.shopId}
                onBack={() => navigate(`/${main.shopId ? '?code=' + main.shopId : ''}`)}
                className="bg-transparent"
                title="商品详情"
              />
            )
      }
    >
      <div className="flex-1 h-1 overflow-y mb-70">
        <img
          className="head-img overflow-hidden border-radius-10 m-10 box-shadow"
          src={goodsDetail!.goodsHeadImg}
          alt=""
        />
        {/* 商品类型为存量 只需要提交手机号与验证码 */}
        {goodsDetail.goodsType === 'market' ? (
          <div className="form-block m-10 border-radius-10 bg-white p-10 box-shadow">
            <div className="font-size-18 font-widget-600 text-center mb-10">{goodsDetail!.goodsName}</div>
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">手机号</div>
              <div className="value border-bottom flex-1">
                <input
                  disabled={goodsDetail.goodsStatus === 'stop_sale'}
                  className="input"
                  placeholder="请输入收件手机号"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">验证码</div>
              <div
                className={`value border-bottom flex-1 d-flex-r ${
                  goodsDetail.goodsStatus === 'stop_sale' ? 'bg-ccc' : ''
                }`}
              >
                <input
                  disabled={goodsDetail.goodsStatus === 'stop_sale'}
                  className="flex-1 input"
                  placeholder="请输入验证码"
                  value={verifyCode}
                  onChange={(e) => setVerifyCode(e.target.value)}
                />
                {goodsDetail.goodsStatus === 'stop_sale' ? (
                  <div className="verify-btn" style={{ background: '#ccc' }}>
                    发送
                  </div>
                ) : (
                  <div
                    className={`verify-btn ${sendCaptcha > 0 && 'disable-verify-btn'}`}
                    onClick={() => {
                      sendCaptcha > 0 ? showToast('短信正在发送，请稍等') : getSendCaptcha();
                    }}
                  >
                    {sendCaptcha > 0 ? `${sendCaptcha}s` : '发送'}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="form-block m-10 border-radius-10 bg-white p-10 box-shadow">
            <div className="font-size-18 font-widget-600 text-center mb-10">{goodsDetail!.goodsName}</div>
            {goodsDetail?.goodsType === 'order_subscribe' && (
              <div className="prop-line mb-10 d-flex-r ai-center">
                <div className="label">开卡号码</div>
                <div className="value border-bottom flex-1">
                  <input
                    disabled={goodsDetail.goodsStatus === 'stop_sale'}
                    className="input"
                    placeholder="请输入开卡号码"
                    value={activatePhone}
                    onChange={(e) => {
                      setactivatePhone(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">{goodsDetail?.goodsType !== 'order_subscribe' ? '收件姓名' : '身份证姓名'}</div>
              <div className="value border-bottom flex-1">
                <input
                  disabled={goodsDetail.goodsStatus === 'stop_sale'}
                  className="input"
                  placeholder="与身份证上的姓名一致(已加密)"
                  value={cardName}
                  onChange={(e) => {
                    setCardName(e.target.value);
                    handChangeIpt('输入姓名');
                  }}
                />
              </div>
            </div>
            {(goodsDetail?.goodsType === 'order' || goodsDetail?.goodsType === 'order_subscribe') && (
              <div className="prop-line mb-10 d-flex-r ai-center">
                <div className="label">身份证号</div>
                <div className="value border-bottom flex-1">
                  <input
                    disabled={goodsDetail.goodsStatus === 'stop_sale'}
                    className="input"
                    placeholder="(已加密，仅用于本次业务)"
                    value={cardNum}
                    onChange={(e) => {
                      setCardNum(e.target.value);
                      handChangeIpt('输入身份证');
                    }}
                  />
                </div>
              </div>
            )}
            {goodsDetail?.goodsType !== 'order_subscribe' && (
              <>
                <div className="prop-line mb-10 d-flex-r ai-center">
                  <div className="label">收件号码</div>
                  <div className="value border-bottom flex-1">
                    <input
                      disabled={goodsDetail.goodsStatus === 'stop_sale'}
                      className="input"
                      placeholder="收货手机号码(已加密)"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        handChangeIpt('输入联系电话');
                        if (e.target.value.length === 11) getStockAesPhone(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="prop-line mb-10 d-flex-r ai-center">
                  <div className="label">城市选择</div>
                  <div className="value border-bottom flex-1">
                    <MCityPicker
                      matchList={matchList}
                      disable={goodsDetail.goodsStatus === 'stop_sale'}
                      onChange={handleCityPickerChange}
                      someProvince={goodsDetail!.localAddress ?? []}
                      excludeRegions={excludeRegions}
                      subProvince={!!goodsDetail.subProvince ? goodsDetail.subProvince : []}
                    />
                  </div>
                </div>
                <div className="prop-line mb-5 d-flex-r ai-center">
                  <div className="label">详细地址</div>
                  <div className="value border-bottom flex-1">
                    <input
                      disabled={goodsDetail.goodsStatus === 'stop_sale'}
                      className="input"
                      placeholder="街道/镇+村庄/小区/写字楼+门牌号"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        handChangeIpt('填写详细地址');
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {!!productId && goodsDetail?.supId === '1816357513598500865' && (
              <div className="prop-line mb-5 d-flex-r ai-center">
                <div className="label"></div>
                <div className="flex-1 text-color-red">
                  单一地址中禁止含有:酒吧、宾馆、超市、门卫、开发区、经济开发区、园、大厦、写字楼、酒店、网、茶、游戏厅、台球厅、美容院、饭馆、住所、娱乐性场所、初中，小学、幼儿园等地址为学校、附近、旁边、东西南北、物流、快递、代收点等信息会进行订单拦截，请谨慎下单!
                </div>
              </div>
            )}
            {operatorsList.length > 0 && goodsDetail?.goodsType && goodsDetail?.goodsType !== 'order' && (
              <div className="prop-line mb-5 d-flex-r ai-center">
                <div className="label">运营商</div>
                <div className="value border-bottom flex-1">
                  <MSelectbox
                    disable={goodsDetail.goodsStatus === 'stop_sale'}
                    title="运营商选择"
                    onChange={(v) => v && setoperatorsType(v[0].value)}
                    someProvince={operatorsList}
                    defaultVal={operatorsList[0]?.label}
                  ></MSelectbox>
                </div>
              </div>
            )}
            {goodsDetail!.ifCampOn === 'Y' && (
              <div className="prop-line mb-10 d-flex-r ai-center">
                <div className="label">在线选号</div>
                <div
                  className={`value border-bottom flex-1 ${selectCard ? '' : 'text-color-9'}  
                  ${goodsDetail.goodsStatus === 'stop_sale' ? 'bg-555 pl-10' : ''}`}
                  onClick={() => {
                    if (goodsDetail!.ifAddress === 'Y' && !province) {
                      showToast('请先选择城市');
                      return;
                    }
                    setShowSelectCardDialog(true);
                  }}
                >
                  {selectCard?.activatePhone || (goodsDetail!.ifAddress === 'Y' ? '需要先选择城市' : '请选择号码')}
                </div>
              </div>
            )}
            {goodsDetail!.ifPhoto === 'Y' && (
              <div className="font-size-14 font-widget-600 border-bottom pt-10 pb-10">
                根据有关部门要求，必须上传真实身份证照片。<span className="text-color-red">未上传照片，订单无效</span>
              </div>
            )}
            {goodsDetail!.ifPhoto === 'Y' && (
              <div className="mt-10">
                <div className="prop-line d-flex-r ai-center">
                  <div className="flex-1 pic pb-5 d-flex-r">
                    {cardAvatar ? (
                      <div className="upload-pic border-radius-4 overflow-hidden mr-5">
                        <img
                          className="bw-100 bh-100"
                          src={cardAvatar}
                          alt=""
                          onClick={() => setviewPictureDialogShow(cardAvatar)}
                        />
                        <div className="icon-close-block" onClick={() => setCardAvatar(undefined)}>
                          <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`upload-pic border border-radius-4 text-center d-flex-r ai-center jc-center ${
                          goodsDetail.goodsStatus === 'stop_sale' && 'bg-555'
                        }`}
                      >
                        <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                        <input
                          disabled={goodsDetail.goodsStatus === 'stop_sale'}
                          className="upload-input bw-100 bh-100"
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          onChange={(e) => {
                            chooseImg(e.target.files, 'cardAvatar');
                          }}
                        />
                      </div>
                    )}
                    <div className="example-block">
                      <img
                        onClick={() => setviewPictureDialogShow('/pic/iccid1.jpg')}
                        className="example-img"
                        src="/pic/iccid1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <span className="text-color-6 font-size-12">
                  手机横拍，保证边角完整信息清晰，不能反光、翻拍、模糊、边角残缺等。
                </span>
              </div>
            )}
            {goodsDetail!.ifPhoto === 'Y' && (
              <div className="mt-10">
                <div className="prop-line d-flex-r ai-center">
                  <div className="flex-1 pic pb-5 d-flex-r">
                    {cardNationalEmblem ? (
                      <div className="upload-pic border-radius-4 overflow-hidden mr-5">
                        <img
                          className="bw-100 bh-100"
                          src={cardNationalEmblem}
                          alt=""
                          onClick={() => setviewPictureDialogShow(cardNationalEmblem)}
                        />
                        <div className="icon-close-block" onClick={() => setNationalEmblem(undefined)}>
                          <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`upload-pic border border-radius-4 text-center d-flex-r ai-center jc-center ${
                          goodsDetail.goodsStatus === 'stop_sale' && 'bg-555'
                        }`}
                      >
                        <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                        <input
                          disabled={goodsDetail.goodsStatus === 'stop_sale'}
                          className="upload-input bw-100 bh-100"
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          onChange={(e) => {
                            chooseImg(e.target.files, 'cardNationalEmblem');
                          }}
                        />
                      </div>
                    )}
                    <div className="example-block">
                      <img
                        className="example-img"
                        onClick={() => setviewPictureDialogShow('/pic/iccid2.jpg')}
                        src="/pic/iccid2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <span className="text-color-6 font-size-12">
                  手机横拍，保证边角完整信息清晰，不能反光、翻拍、模糊、边角残缺等。
                </span>
              </div>
            )}
            {goodsDetail!.ifPhoto === 'Y' && (
              <div className="mt-10 mb-10">
                <div className="prop-line d-flex-r ai-center">
                  <div className="flex-1 pic pb-5 d-flex-r">
                    {handPhoto ? (
                      <div className="upload-pic border-radius-4 overflow-hidden mr-5">
                        <img
                          className="bw-100 bh-100"
                          src={handPhoto}
                          alt=""
                          onClick={() => setviewPictureDialogShow(handPhoto)}
                        />
                        <div className="icon-close-block" onClick={() => setHandPhoto(undefined)}>
                          <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`upload-pic border border-radius-4 text-center d-flex-r ai-center jc-center ${
                          goodsDetail.goodsStatus === 'stop_sale' && 'bg-555'
                        }`}
                      >
                        <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                        <input
                          disabled={goodsDetail.goodsStatus === 'stop_sale'}
                          className="upload-input bw-100 bh-100"
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          onChange={(e) => {
                            chooseImg(e.target.files, 'handPhoto');
                          }}
                        />
                      </div>
                    )}
                    <div className="example-block">
                      <img
                        className="example-img"
                        onClick={() => setviewPictureDialogShow('/pic/iccid3.jpg')}
                        src="/pic/iccid3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <span className="text-color-6 font-size-12">
                  请拍摄本人真实环境清晰正面免冠人像，不能反光，有水印，PS、上传证件照等。
                </span>
              </div>
            )}
            <div className="d-flex-r pb-10">
              <MCheckbox value={checkProtocol} onChange={(v) => setCheckProtocl(v)} />
              <div className="ml-10 font-size-12">
                <span onClick={() => setCheckProtocl(!checkProtocol)}>我已阅读并同意</span>
                {goodsDetail.supId === '1667093255927742465' ? (
                  <>
                    <span className="text-color-p" onClick={() => setShowProtocol('gdyd1')}>
                      《电信网络诈骗犯罪及发送不良信息法律责任风险提示告知书》
                    </span>
                    <span className="text-color-p" onClick={() => setShowProtocol('gdyd2')}>
                      《电子渠道开户服务协议》
                    </span>
                    <span className="text-color-p" onClick={() => setShowProtocol('gdyd3')}>
                      《中国移动广东公司电子渠道商品销售服务协议》
                    </span>
                    <span className="text-color-p" onClick={() => setShowProtocol('gdyd4')}>
                      《校园客户新入网优惠协议》
                    </span>
                    <span className="text-color-p" onClick={() => setShowProtocol('gdyd5')}>
                      《中国移动通信客户服务协议》
                    </span>
                    <span className="text-color-p" onClick={() => setShowProtocol('gdyd6')}>
                      《解约条款》
                    </span>
                    <span className="text-color-p" onClick={() => setShowProtocol('gdyd7')}>
                      《隐私政策》
                    </span>
                  </>
                ) : (
                  <>
                    <span className="text-color-p" onClick={() => setShowProtocol('userInfo')}>
                      《关于客户个人信息收集、使用规则的公告》
                    </span>
                    {goodsDetail.operatorsType === 'telecom' && goodsDetail?.isZsDx !== 'Y' && (
                      <span className="text-color-p" onClick={() => setShowProtocol('noticeDX')}>
                        《中国电信用户入网协议》
                      </span>
                    )}
                    {goodsDetail.operatorsType === 'telecom' && goodsDetail?.isZsDx === 'Y' && (
                      <>
                        <span className="text-color-p" onClick={() => setShowProtocol('zhouShanDX')}>
                          《中国电信用户入网协议（2024 线上版）》
                        </span>
                        <span className="text-color-p" onClick={() => setShowProtocol('zhouShanruwangDX')}>
                          《用户入网补充告知书-2024版》
                        </span>
                      </>
                    )}
                    <span className="text-color-p" onClick={() => setShowProtocol('phone')}>
                      《办理电话号码开户法律风险及防范提示告知书》
                    </span>
                    {goodsDetail.operatorsType === 'cmcc' && (
                      <span className="text-color-p" onClick={() => setShowProtocol('noticeYD')}>
                        《关于客户个人信息收集、使用及保护的公告》
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {goodsDetail.paySwitch === 'Y' && (
          <div className="form-block m-10 border-radius-10 bg-white p-10 box-shadow">
            <div className="font-widget-600">选择支付方式</div>
            {payConfigList.map((i) => (
              <div className="d-flex-r ai-center mt-14">
                <img src={`/pic/${PayType.typeIcon(payType)}`} className="icon-20 mr-10" alt="" />
                <div className="flex-1">{i.name}</div>

                <MCheckbox value={i.payType === payType} onChange={() => setPayType(i.payType)} />
              </div>
            ))}
            {!isWeixinEnv() && (
              <div className="mt-10">
                <span className="font-size-12 text-color-6">如需微信支付，请复制链接到微信打开即可！</span>
                <div
                  className="bg-p w-100 border-radius-4 text-color-white pt-5 pb-5 text-center mt-5"
                  onClick={handleCopyHost}
                >
                  复&nbsp;制
                </div>
              </div>
            )}
          </div>
        )}
        {goodsDetail?.goodsType !== 'equipment' && (
          <>
            <div className="pl-30 pr-30 font-size-12 text-color-9">
              *请填写真实身份信息，未满18岁、一证五号、信息错误将无法发货。(所填信息皆做加密处理)
            </div>
            <div className="pl-30 pr-30 mt-10 font-size-12 text-color-red">
              *避免填写 酒店 宾馆 网吧 驿站 学校
              <br />
              *禁止用电话卡参与任何违法事件
            </div>
          </>
        )}
        <div className="box-shadow bg-white border-radius-10 m-10">
          {!!goodsDetail?.videoUrl && (
            <div className="video-box" onClick={changeVideo}>
              <video
                width="100%"
                id="videoPlayer"
                // autoPlay
                webkit-playsinline="true"
                playsInline={true}
                // controls
                poster={!!isVideoPlaying ? goodsDetail!.goodsHeadImg : ''}
              >
                <source src={goodsDetail?.videoUrl} type="video/mp4" />
              </video>
              {!!isVideoPlaying && <img className="video-img" src="/pic/icon-video.png" alt="" />}
            </div>
          )}
          <div className="font-size-18 font-widget-600 text-center p-10">详情介绍</div>
          <div
            className="goods-detail pb-20"
            ref={(v) => handleDetailRef(v)}
            dangerouslySetInnerHTML={{ __html: goodsDetail!.goodsDetail }}
          />
        </div>
      </div>
      {goodsDetail.goodsStatus === 'stop_sale' ? (
        <div className="p-10 footer-btn">
          <div className="submit-btn" style={{ background: '#ccc' }}>
            该商品暂不可下单!
          </div>
        </div>
      ) : (
        <div className="p-10 footer-btn bw-100">
          {goodsDetail.goodsType === 'market' ? (
            <div className="d-flex-r ai-center bw-100">
              <div className="font-size-12 d-flex-r">
                {!!(landingCode ? main.serviceLink : goodsDetail?.serviceUrl) && (
                  <div className="d-flex-c ai-center jc-center mr-30" onClick={handleServiceLink}>
                    <img src="/pic/icon_custom.jpg" className="w-30 h-30 mb-3" alt="" />
                    <span>客服</span>
                  </div>
                )}
                <div className="d-flex-c ai-center jc-center mr-30" onClick={() => navigate('/orderList')}>
                  <img src="/pic/icon_order.jpg" className="w-30 h-30 mb-3" alt="" />
                  <span>订单</span>
                </div>
              </div>
              <div className="flex-1 submit-btn" onClick={handleSubmitCL}>
                提交
              </div>
            </div>
          ) : (
            <div className="d-flex-r ai-center bw-100">
              <div className="font-size-12 d-flex-r">
                {!!(landingCode ? main.serviceLink : goodsDetail?.serviceUrl) && (
                  <div className="d-flex-c ai-center jc-center mr-30" onClick={handleServiceLink}>
                    <img src="/pic/icon_custom.jpg" className="w-30 h-30 mb-3" alt="" />
                    <span>客服</span>
                  </div>
                )}
                <div className="d-flex-c ai-center jc-center mr-30" onClick={() => navigate('/orderList')}>
                  <img src="/pic/icon_order.jpg" className="w-30 h-30 mb-3" alt="" />
                  <span>订单</span>
                </div>
              </div>
              <div className="flex-1 submit-btn" onClick={handleSubmit}>
                {goodsDetail.paySwitch === 'Y' ? `立即支付￥${goodsDetail.goodsPrice}` : '提交'}
              </div>
            </div>
          )}
        </div>
      )}

      {showProtocol && (
        <ProtocolDialog supName={goodsDetail!.supName} type={showProtocol} onClose={() => setShowProtocol(undefined)} />
      )}
      {showSelectCardDialog && (
        <SelectPhoneDialog
          list={cardPool ?? []}
          value={selectCard}
          selectPhoneFlag={selectPhoneFlag}
          onChange={(v) => setSelectCard(v)}
          onClose={() => setShowSelectCardDialog(false)}
          onChangeNextPage={() => loadCardPool(goodsDetail?.goodsCode, province, city, area)}
        />
      )}
      {/* 接口500 返回的msg弹窗 */}
      {!!messageDialogShow && <MessageDialog message={messageDialogShow} onClose={() => setmessageDialogShow('')} />}
      {uploadLoading && (
        <Loading
          tips={'正在加载中...'}
          loadingClass="bh-100 overflow-hidden text-color-white d-flex-r ai-center jc-center mt-0 bg-shallow"
        />
      )}
      {/* 查看图片 */}
      {viewPictureDialogShow && (
        <ViewPictureDialog imgPath={viewPictureDialogShow} onClose={() => setviewPictureDialogShow(undefined)} />
      )}
    </Page>
  );
}
